<template>
    <div class="cases-controls">
        <div class="controls-count">
            <button v-on:click="casesSetCount(1)" v-bind:class="{ 'button-active': casesCount === 1 }" v-bind:disabled="socketSendLoading !== null || casesRunning === true">1</button>
            <button v-on:click="casesSetCount(2)" v-bind:class="{ 'button-active': casesCount === 2 }" v-bind:disabled="socketSendLoading !== null || casesRunning === true">2</button>
            <button v-on:click="casesSetCount(3)" v-bind:class="{ 'button-active': casesCount === 3 }" v-bind:disabled="socketSendLoading !== null || casesRunning === true">3</button>
            <button v-on:click="casesSetCount(4)" v-bind:class="{ 'button-active': casesCount === 4 }" v-bind:disabled="socketSendLoading !== null || casesRunning === true">4</button>
        </div>
        <div class="controls-bet">
            <button v-on:click="casesOpenButton()" class="button-open" v-bind:disabled="socketSendLoading !== null || casesRunning === true">
                OPEN CASE
                <img src="@/assets/img/icons/coin.png" alt="coins-image" />
                <div class="button-amount">
                    <span>{{casesFormatValue(Math.floor(casesBoxData.box.amount * casesCount)).split('.')[0]}}</span>.{{casesFormatValue(Math.floor(casesBoxData.box.amount * casesCount)).split('.')[1]}}
                </div>
            </button>
            <button v-on:click="casesDemoButton()" class="button-demo" v-bind:disabled="socketSendLoading !== null || casesRunning === true">DEMO SPIN</button>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'CasesControls',
        methods: {
            ...mapActions([
                'casesSetCount',
                'casesSetRunnning',
                'casesSetGames',
                'casesSendCreateSocket'
            ]),
            casesFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            casesOpenButton() {
                if(this.authUser.user === null) {
                    this.notificationShow({ type: 'error', message: 'Please sign in to perform this action.' });
                    return;
                }

                const data = { boxId: this.casesBoxData.box._id, unboxCount: this.casesCount };
                this.casesSendCreateSocket(data);
            },
            casesDemoButton() {
                let games = [];

                for(let i = 0; i < this.casesCount; i++) {
                    games.push({ demo: true, outcome: Math.floor(Math.random() * (100000 - 1 + 1)) + 1, updatedAt: Date.now() });
                }

                this.casesSetRunnning(true);
                this.casesSetGames(games);
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading',
                'authUser',
                'casesCount',
                'casesRunning',
                'casesBoxData'
            ])
        }
    }
</script>

<style scoped>
    .cases-controls {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
    }

    .cases-controls .controls-count,
    .cases-controls .controls-bet {
        display: flex;
        align-items: center;
    }

    .cases-controls .controls-count button {
        width: 46px;
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background: #212732;
        box-shadow: 0px 2px 0px 0px #1d222a;
    }

    .cases-controls .controls-count button.button-active {
        color: #fd3b31;
        background: #252129;
        box-shadow: 0px 0px 0px 1px #59272b;
    }

    .cases-controls button.button-open, 
    .cases-controls button.button-demo {
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 16px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        transition: all 0.3s ease;
    }

    .cases-controls button.button-open {
        margin-right: 12px;
        background: #00c74d;
        border-bottom: 2px solid #00732c;
    }

    .cases-controls button.button-open img {
        width: 21px;
        margin-left: 12px;
    }

    .cases-controls button.button-open .button-amount {
        margin-left: 10px;
        font-size: 12px;
        font-weight: 700;
        color: #ffffff;
    }

    .cases-controls button.button-open .button-amount span {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .cases-controls button.button-demo {
        color: #767c8b;
        background: #212732;
        border-bottom: 2px solid #1d222a;
    }

    .cases-controls button.button-demo:hover {
        color: #ffffff;
    }

    @media only screen and (max-width: 750px) {

        .cases-controls {
            flex-direction: column
        }

        .cases-controls .controls-bet {
            margin-top: 12px;
        }

    }
</style>