<template>
  <div class="blackjack-game">
    <div class="game-table">
      <div class="table-background">
        <div class="background-inner">
          <img src="@/assets/img/icons/blackjack.svg" alt="blackjack" />
        </div>
      </div>
      <div class="table-deck">
        <div class="deck-card"></div>
        <div class="deck-card"></div>
        <div class="deck-card"></div>
        <div class="deck-card"></div>
        <div class="deck-card"></div>
        <div class="deck-card">
          <div class="card-inner">
            <img src="@/assets/img/cards/back.webp" alt="card-back" />
          </div>
        </div>
      </div>
      <transition name="table" mode="out-in">
        <div v-if="blackjackData.game" class="table-stack">
          <div class="stack-dealer">
            <TransitionGroup  name="dealer" tag="div" class="dealer-cards">
              <BlackjackCard 
                v-for="(card, index) of blackjackData.game.dealerStack.cards" 
                :key="'dealer-' + index" 
                :card="card" 
              />
            </TransitionGroup >
            <div class="dealer-value">{{blackjackGetCardValue(blackjackData.game.dealerStack.cards)}}</div>
          </div>
          <div class="stack-player">
            <div v-if="!blackjackData.game.actions.includes('split')" :class="['player-single', 
              blackjackGetResultClass(blackjackData.game.playerStack.cards, blackjackData.game.dealerStack.cards)
            ]">
              <TransitionGroup name="player" tag="div" class="single-cards">
                <BlackjackCard 
                  v-for="card of blackjackData.game.playerStack.cards" 
                  :key="card._id" 
                  :card="card" 
                />
              </TransitionGroup >
              <div class="single-value">{{blackjackGetCardValue(blackjackData.game.playerStack.cards)}}</div>
            </div>
            <div v-else class="player-split">
              <div :class="['split-left', 
                blackjackGetResultClass(blackjackData.game.playerStack.leftCards, blackjackData.game.dealerStack.cards)
              ]">
                <TransitionGroup  name="player" tag="div" class="left-cards">
                  <BlackjackCard 
                    v-for="card of blackjackData.game.playerStack.leftCards" 
                    :key="card._id"
                    :card="card" 
                  />
                </TransitionGroup >
                <div :class="['left-value', { 
                  'value-active': blackjackData.game.state === 'created' && !blackjackData.game.actions.includes('stand') && blackjackGetCardValue(blackjackData.game.playerStack.leftCards) <= 20 
                }]">{{blackjackGetCardValue(blackjackData.game.playerStack.leftCards)}}</div>
              </div>
              <div :class="['split-right', 
                blackjackGetResultClass(blackjackData.game.playerStack.rightCards, blackjackData.game.dealerStack.cards)
              ]">
                <TransitionGroup  name="player" tag="div" class="right-cards">
                  <BlackjackCard 
                    v-for="card of blackjackData.game.playerStack.rightCards" 
                    :key="card._id"
                    :card="card" 
                  />
                </TransitionGroup >
                <div :class="['right-value', { 
                  'value-active': blackjackData.game.state === 'created' && (blackjackData.game.actions.includes('stand') || blackjackGetCardValue(blackjackData.game.playerStack.leftCards) >= 21) 
                }]">{{blackjackGetCardValue(blackjackData.game.playerStack.rightCards)}}</div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import mixins from '@/mixins';
  import BlackjackCard from '@/components/blackjack/BlackjackCard';

  export default {
    components: {
      BlackjackCard
    },
    mixins: [
      mixins
    ],
    methods: {
      blackjackGetResultClass(playerCards, dealerCards) {
        if(!this.blackjackDealing && this.blackjackData.game.state === 'completed') {
          if(
            this.blackjackGetCardValue(playerCards) <= 21 &&
            (
              this.blackjackGetCardValue(dealerCards) > 21 || 
              this.blackjackGetCardValue(playerCards) > this.blackjackGetCardValue(dealerCards)
            )
          ) {
            return 'result-won';
          } else if(
            this.blackjackGetCardValue(playerCards) <= 21 &&
            this.blackjackGetCardValue(dealerCards) <= 21 &&
            this.blackjackGetCardValue(playerCards) === this.blackjackGetCardValue(dealerCards)
          ) {
            return 'result-draw';
          } else {
            return 'result-lose';
          }
        }

        return null;
      }
    },
    computed: {
      ...mapGetters([
        'blackjackDealing', 
        'blackjackData'
      ])
    }
  }
</script>

<style scoped>
  .blackjack-game {
    width: calc(100% - 380px);
    padding-left: 16px;
  }

  .blackjack-game .game-table {
    width: 100%;
    position: relative;
  }

  .blackjack-game .table-background {
    width: 100%;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px 50px 50% 50%;
    background: #11141f;
    border: 10px solid #1e222b;
  }

  .blackjack-game .background-inner {
    width: 80%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px 50px 50% 50%;
    border: 1px solid #1e222b;
  }

  .blackjack-game .background-inner img {
    width: 60%;
    opacity: 0.2;
  }

  .blackjack-game .table-deck {
    width: 101px;
    height: 140px;
    position: absolute;
    top: 40px;
    right: 60px;
    transform: rotate(45deg);
    border-radius: 8px;
    background: rgba(30, 34, 43, 0.5);
  }

  .blackjack-game .deck-card {
    width: 86px;
    height: 120px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    background: #ebe9e6;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.42));
  }

  .blackjack-game .deck-card:nth-child(2) {
    width: 86px;
    height: 120px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% - 1px), calc(-50% - 1px));
    border-radius: 8px;
    background: #ebe9e6;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.42));
  }

  .blackjack-game .deck-card:nth-child(3) {
    width: 86px;
    height: 120px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% - 2px), calc(-50% - 2px));
    border-radius: 8px;
    background: #ebe9e6;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.42));
  }

  .blackjack-game .deck-card:nth-child(4) {
    width: 86px;
    height: 120px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% - 3px), calc(-50% - 3px));
    border-radius: 8px;
    background: #ebe9e6;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.42));
  }

  .blackjack-game .deck-card:nth-child(5) {
    width: 86px;
    height: 120px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% - 4px), calc(-50% - 4px));
    border-radius: 8px;
    background: #ebe9e6;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.42));
  }

  .blackjack-game .deck-card:nth-child(6) {
    width: 86px;
    height: 120px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% - 5px), calc(-50% - 5px));
    border-radius: 8px;
    background: #ebe9e6;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.42));
  }

  .blackjack-game .card-inner {
    width: 76px;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .blackjack-game .card-inner img {
    width: 100%;
    border-radius: 6px;
  }

  .blackjack-game .inner-point:nth-child(1) {
    width: 6px;
    height: 6px;
    position: absolute;
    top: 6px;
    left: 6px;
    border-radius: 50%;
    border: 2px solid #595c7a;
  }

  .blackjack-game .inner-point:nth-child(2) {
    width: 6px;
    height: 6px;
    position: absolute;
    top: 6px;
    right: 6px;
    border-radius: 50%;
    border: 2px solid #595c7a;
  }

  .blackjack-game .inner-point:nth-child(3) {
    width: 6px;
    height: 6px;
    position: absolute;
    bottom: 6px;
    left: 6px;
    border-radius: 50%;
    border: 2px solid #595c7a;
  }

  .blackjack-game .inner-point:nth-child(4) {
    width: 6px;
    height: 6px;
    position: absolute;
    bottom: 6px;
    right: 6px;
    border-radius: 50%;
    border: 2px solid #595c7a;
  }

  .blackjack-game .table-stack {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .blackjack-game .table-stack.table-leave-active {
    transition: all 0.3s;
  }

  .blackjack-game .table-stack.table-leave-to {
    transform: translateY(30px);
    opacity: 0;
  }

  .blackjack-game .stack-dealer {
    height: 200px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    top: 60px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .blackjack-game .dealer-value {
    height: 37px;
    width: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    background: #1b1e2c;
  }

  .blackjack-game .dealer-cards {
    width: 80px;
    position: relative;
  }

  .blackjack-game .dealer-enter-active {
    transition: all 0.3s;
  }

  .blackjack-game .dealer-enter-from {
    transform: translateX(30px);
    opacity: 0;
  }

  .blackjack-game .stack-player {
    position: absolute;
    bottom: 65px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .blackjack-game .player-single {
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .blackjack-game .player-split {
    display: flex;
    align-items: center;
  }

  .blackjack-game .split-left,
  .blackjack-game .split-right {
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .blackjack-game .split-left {
    margin-right: 140px;
  }

  .blackjack-game .single-value,
  .blackjack-game .left-value,
  .blackjack-game .right-value {
    height: 37px;
    width: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    background: #1b1e2c;
    transition: all 0.3s;
  }

  .blackjack-game .left-value.value-active,
  .blackjack-game .right-value.value-active {
    box-shadow: 0 0 0 2px rgba(253, 59, 49, 0.6);
  }

  .blackjack-game .result-won .single-value,
  .blackjack-game .result-won .left-value,
  .blackjack-game .result-won .right-value {
    background-repeat: #00c74d;
  }

  .blackjack-game .result-draw .single-value,
  .blackjack-game .result-draw .left-value,
  .blackjack-game .result-draw .right-value {
    background: #ffc93d;
  }

  .blackjack-game .result-lose .single-value,
  .blackjack-game .result-lose .left-value,
  .blackjack-game .result-lose .right-value {
    background: #fd3b31;
  }

  .blackjack-game .single-cards,
  .blackjack-game .left-cards,
  .blackjack-game .right-cards {
    width: 80px;
    position: relative;
  }

  .blackjack-game .player-enter-active {
    transition: all 0.3s;
  }

  .blackjack-game .player-enter-from {
    transform: translateX(30px);
    opacity: 0;
  }

  @media only screen and (max-width: 1100px) {

    .blackjack-game {
      width: 100%;
      padding-left: 0;
    }

    .blackjack-game .table-background {
      height: 500px;
      border-radius: 18px;
      border: 5px solid #1e222b;
    }

    .blackjack-game .background-inner {
      border-radius: 50px;
    }

    .blackjack-game .table-deck {
      width: 86px;
      height: 120px;
    }

    .blackjack-game .deck-card {
      width: 72px;
      height: 100px;
    }

    .blackjack-game .deck-card:nth-child(2) {
      width: 72px;
      height: 100px;
    }

    .blackjack-game .deck-card:nth-child(3) {
      width: 72px;
      height: 100px;
    }

    .blackjack-game .deck-card:nth-child(4) {
      width: 72px;
      height: 100px;
    }

    .blackjack-game .deck-card:nth-child(5) {
      width: 72px;
      height: 100px;
    }

    .blackjack-game .deck-card:nth-child(6) {
      width: 72px;
      height: 100px;
    }

    .blackjack-game .card-inner {
      width: 65px;
      height: 90px;
    }

    .blackjack-game .table-dealer {
      height: 180px;
      top: 35px;
    }

    .blackjack-game .table-player {
      bottom: 40px;
    }

    .blackjack-game .player-single {
      height: 180px;
    }

    .blackjack-game .split-left,
    .blackjack-game .split-right {
      height: 180px;
    }

  }

  @media only screen and (max-width: 800px) {

    .blackjack-game .background-inner img {
      width: 75%;
    }

    .blackjack-game .table-deck {
      display: none;
    }

  }
</style>
