<template>
  <div class="amount-number">
    <span>{{generalFormatAmount(amountCurrent).split('.')[0]}}</span>.{{
    generalFormatAmount(amountCurrent).split('.')[1]}}
  </div>
</template>

<script>
  import mixins from '@/mixins';

  export default {
    mixins: [
      mixins
    ],
    props: {
      amount: Number
    },
    data() {
      return {
        amountInterval: null,
        amountCurrent: 0
      }
    },
    watch: {
      'amount': {
        handler() {
          clearInterval(this.amountInterval);

          const step = Math.floor(this.amount - this.amountCurrent) / 30;
          this.amountInterval = setInterval(() => { 
            this.amountCurrent = Math.floor(this.amountCurrent + step);

            if((step >= 0 && this.amountCurrent >= this.amount) || (step <= 0 && this.amountCurrent <= this.amount)) {
              clearInterval(this.amountInterval); 
              this.amountCurrent = this.amount;
            }
            }, 500 / 30);
        }
      }
    },
    mounted() {
      this.amountCurrent = this.amount;
    },
    beforeUnmount() {
      clearInterval(this.amountInterval);
    }
  }
</script>

<style lang="css" scoped>
  .amount-number {
    font-variant-numeric: tabular-nums;
  }
</style>