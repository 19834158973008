const state = {
    affiliatesData: {
        data: null,
        loading: false
    },
    affiliatesUsers: {
        users: null,
        count: null,
        loading: false,
        page: 1
    }
}

const getters = {
    affiliatesData: state => state.affiliatesData,
    affiliatesUsers: state => state.affiliatesUsers
}

const mutations = {
    affiliates_set_data(state, data) {
        state.affiliatesData.data = data;
    },
    affiliates_set_data_loading(state, status) {
        state.affiliatesData.loading = status;
    },
    affiliates_set_users(state, data) {
        state.affiliatesUsers.users = data.users;
        state.affiliatesUsers.count = data.count;
    },
    affiliates_set_users_loading(state, status) {
        state.affiliatesUsers.loading = status;
    },
    affiliates_set_users_page(state, page) {
        state.affiliatesUsers.page = page;
    }
}

const actions = {
  affiliatesSetUsersPage({ commit }, page) {
    commit('affiliates_set_users_page', page);
  },
  async affiliatesGetDataSocket({ getters, commit, dispatch }, data) {
    commit('affiliates_set_data_loading', true);

    try {
      const res = await getters.socketClient
        .emitWithAck('general:affiliate:getData', data);

      if(res.success) {
        commit('affiliates_set_data', res.data);
      } else {
        dispatch('notificationShow', res.error);
      }
    } finally {
      commit('affiliates_set_data_loading', false);
    }
  },
  async affiliatesGetUsersSocket({ getters, commit, dispatch }, data) {
    commit('affiliates_set_users_loading', true);

    try {
      const res = await getters.socketClient
        .emitWithAck('general:affiliate:getUsers', data);

      if(res.success) {
        commit('affiliates_set_users', res);
      } else {
        dispatch('notificationShow', res.error);
      }
    } finally {
      commit('affiliates_set_users_loading', false);
    }
  },
  async affiliatesSendCodeSocket({ getters, commit, dispatch }, data) {
    commit('socket_set_send_loading', 'AffiliatesCode');

    try {
      const res = await getters.socketClient
        .emitWithAck('general:affiliate:sendCode', data);

      if(res.success) {
        commit('affiliates_set_data', res.data);
        dispatch('notificationShow', { type: 'success', message: 'You have successfully updated your affiliate code.' });
      } else {
        dispatch('notificationShow', res.error);
      }
    } finally {
      commit('socket_set_send_loading', null);
    }
  },
  async affiliatesSendClaimCodeSocket({ getters, commit, dispatch }, data) {
    commit('socket_set_send_loading', 'AffiliatesClaimCode');

    try {
      const res = await getters.socketClient
        .emitWithAck('general:affiliate:sendClaimCode', data);

      if(res.success) {
        commit('auth_update_user', res.user);

        dispatch('modalsSetShow', null);
        dispatch('notificationShow', { type: 'success', message: 'You have successfully redeemed a affiliate code.' });
      } else {
        dispatch('notificationShow', res.error);
      }
    } finally {
      commit('socket_set_send_loading', null);
    }
  },
  async affiliatesSendClaimEarningsSocket({ getters, commit, dispatch }, data) {
    commit('socket_set_send_loading', 'AffiliatesClaim');

    try {
      const res = await getters.socketClient
        .emitWithAck('general:affiliate:sendClaimEarnings', data);

      if(res.success) {
        commit('auth_update_user', res.user);
        commit('affiliates_set_data', res.user.affiliates);

        dispatch('notificationShow', { type: 'success', message: 'You have successfully claimed your affiliate earnings.' });
      } else {
        dispatch('notificationShow', res.error);
      }
    } finally {
      commit('socket_set_send_loading', null);
    }
  }
}

const affiliates = {
  state,
  mutations,
  actions,
  getters
}

export default affiliates;
