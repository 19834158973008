<template>
  <div :class="['chat-room-dropdown', { 
    'dropdown-open': dropdown 
  }]">
    <button @click="chatSetDropdown(!dropdown)" class="button-toggle">
      <div class="button-language">
        <img :src="require(`@/assets/img/languages/${chatRoom}.webp`)" alt="language-icon" />
      </div>
      <div class="button-icon">
        <svg width="13" height="9" viewBox="0 0 13 9" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.2142 0.361328L6.5 5.07555L1.78577 0.361328L0 2.1471L6.5 8.6471L13 2.1471L11.2142 0.361328Z" />
        </svg>
      </div>
      {{chatGetRoomName}}
    </button>
    <div class="dropdown-menu">
      <div class="menu-inner">
        <button @click="chatSetRoomButton('en')">
          <img src="@/assets/img/languages/en.webp" alt="en-icon" />
          ENGLISH CHAT ROOM
        </button>
        <button @click="chatSetRoomButton('ru')">
          <img src="@/assets/img/languages/ru.webp" alt="en-icon" />
          RUSSIAN CHAT ROOM
        </button>
        <button @click="chatSetRoomButton('tr')">
          <img src="@/assets/img/languages/tr.webp" alt="en-icon" />
          TURKISH CHAT ROOM
        </button>
        <button @click="chatSetRoomButton('es')">
          <img src="@/assets/img/languages/es.webp" alt="en-icon" />
          SPANISH CHAT ROOM
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    data() {
      return {
        dropdown: false
      }
    },
    methods: {
      ...mapActions([
        'chatSetRoom'
      ]),
      chatSetDropdown(value) {
        this.dropdown = value;
      },
      chatSetRoomButton(room) {
        this.chatSetRoom(room);
        this.chatSetDropdown(false);
      },
      chatHandleOutside(event) {
        if(!this.$el.contains(event.target) && this.dropdown) {
          this.chatSetDropdown(false);
        }
      }
    },
    computed: {
      ...mapGetters([
        'chatRoom'
      ]),
      chatGetRoomName() {
        if(this.chatRoom === 'ru') {
          return 'RUSSIAN CHAT ROOM';
        } else if(this.chatRoom === 'tr') {
          return 'TURKISH CHAT ROOM';
        } else if(this.chatRoom === 'es') {
          return 'SPANISH CHAT ROOM';
        } else {
          return 'ENGLISH CHAT ROOM';
        }
      }
    },
    mounted() {
      document.addEventListener('click', this.chatHandleOutside);
    },
    beforeUnmount() {
      document.removeEventListener('click', this.chatHandleOutside);
    }
  }
</script>

<style scoped>
  .chat-room-dropdown {
    width: 100%;
    position: relative;
    z-index: 15;
  }

  .chat-room-dropdown button.button-toggle {
    width: 100%;
    height: 43px;
    position: relative;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 600;
    color: #626c7e;
    background: #212732;
    border-bottom: 2px solid #191e27;
  }

  .chat-room-dropdown button.button-toggle .button-language {
    height: 19px;
    position: absolute;
    top: 50%;
    left: 13px;
    transform: translate(0, -50%);
  }

  .chat-room-dropdown button.button-toggle .button-language img {
    width: 19px;
    height: 19px;
    border-radius: 50%;
  }

  .chat-room-dropdown button.button-toggle .button-icon {
    position: absolute;
    top: 50%;
    right: 13px;
    transform: translate(0, -50%);
  }

  .chat-room-dropdown button.button-toggle .button-icon svg {
    fill: #626c7e;
    transition: all 0.3s ease;
  }

  .chat-room-dropdown.dropdown-open button.button-toggle .button-icon svg {
    transform: rotate(180deg);
  }

  .chat-room-dropdown .dropdown-menu {
    width: 100%;
    height: 0;
    position: absolute;
    top: 50px;
    left: 0;
    transition: height 0.2s ease;
    overflow: hidden;
  }

  .chat-room-dropdown.dropdown-open .dropdown-menu {
    height: 182px;
  }

  .chat-room-dropdown .menu-inner {
    width: 100%;
    position: relative;
    padding: 3px;
    border-radius: 5px;
    background: #212732;
    border: 1px solid #191d26;
  }

  .chat-room-dropdown .menu-inner button {
    width: 100%;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    color: #ffffff;
  }

  .chat-room-dropdown .menu-inner button:hover {
    background: #191d26;
  }

  .chat-room-dropdown .menu-inner button img {
    width: 19px;
    height: 19px;
    margin-right: 12px;
    border-radius: 50%;
  }
</style>
