<template>
  <div class="upgrader-items">
    <div class="items-header">
      <FilterSearch
        @setFilterValue="upgraderSetFilterSearch"  
        :filterValue="upgraderFilterSearch" 
      />
      <div class="header-filter">
        <FilterItemPrice
          @setFilterValue="upgraderSetFilterPrice" 
          :filterValue="upgraderFilterPrice" 
        />
        <FilterItemSort
          @setFilterValue="upgraderSetFilterSort" 
          :filterValue="upgraderFilterSort" 
        />
      </div>
    </div>
    <div class="items-content">
      <div v-if="upgraderItemsData.loading" class="content-loading" key="loading">
        
        <SkeletonLoader v-for="index in 18" :key="index" v-once />

      </div>
      <div v-else-if="upgraderItemsData.items && upgraderItemsData.items.length > 0" class="content-items" key="data">

        <SteamItemElement 
          v-for="item of upgraderGetItems" 
          :key="item.name" 
          :item="item" 
        />

      </div>
      <div v-else class="content-empty" key="empty">NO ITEMS FOUND</div>
    </div>

    <Pagination 
      @setPage="upgraderSetPage" 
      :page="upgraderItemsData.page" 
      :count="upgraderItemsData.count" 
      countPage="90" 
    />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import SkeletonLoader from '@/components/SkeletonLoader';
  import Pagination from '@/components/Pagination';
  import FilterSearch from '@/components/filters/FilterSearch';
  import FilterItemPrice from '@/components/filters/FilterItemPrice';
  import FilterItemSort from '@/components/filters/FilterItemSort';
  import SteamItemElement from '@/components/steam/SteamItemElement';

  export default {
    components: {
      SkeletonLoader,
      Pagination,
      FilterSearch,
      FilterItemPrice,
      FilterItemSort,
      SteamItemElement
    },
    data() {
      return {
        upgraderFilterSearch: '',
        upgraderFilterPrice: 'all',
        upgraderFilterSort: 'descending'
      }
    },
    methods: {
      ...mapActions([
        'upgraderSetItemsDataPage',
        'upgraderGetItemsDataSocket'
      ]),
      upgraderSetFilterSearch(value) {
        this.upgraderFilterSearch = value;
        this.upgraderSetPage(1);
      },
      upgraderSetFilterPrice(value) {
        this.upgraderFilterPrice = value;
        this.upgraderSetPage(1);
      },
      upgraderSetFilterSort(value) {
        this.upgraderFilterSort = value;
        this.upgraderSetPage(1);
      },
      upgraderSetPage(page) {
        this.upgraderSetItemsDataPage(page);

        this.upgraderGetItemsDataSocket({ 
          page: this.upgraderItemsData.page, 
          search: this.upgraderFilterSearch, 
          select: this.upgraderFilterPrice,
          sort: this.upgraderFilterSort 
        });
      }
    },
    computed: {
      ...mapGetters([
        'upgraderItemsData'
      ]),
      upgraderGetItems() {
        let items = [];

        for(let item of this.upgraderItemsData.items) {
          items.push({
            _id: item._id,
            name: item.name,
            image: item.image,
            amount: item.amount,
            count: 1,
            accepted: true
          });
        }

        return items;
      }
    },
    created() {
      this.upgraderGetItemsDataSocket({ 
        page: this.upgraderItemsData.page, 
        search: this.upgraderFilterSearch,
        select: this.upgraderFilterPrice, 
        sort: this.upgraderFilterSort 
      });
    }
  }
</script>

<style scoped>
  .upgrader-items {
    width: 100%;
    margin-top: 35px;
  }

  .upgrader-items .items-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }


  .upgrader-items .header-filter {
    display: flex;
    align-items: center;
  }

  .upgrader-items .items-content {
    width: 100%;
    margin-top: 26px;
  }

  .upgrader-items .content-loading,
  .upgrader-items .content-items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px 16px;
  }

  .upgrader-items .skeleton-loader {
    width: calc(16.66% - 13.33px);
    height: 222px;
    border-radius: 18px;
    background: linear-gradient(180deg, #1a1e29, #1c202c);
  }

  .upgrader-items .content-empty {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    color: #626c7e;
  }

  @media only screen and (max-width: 1350px) {

    .upgrader-items .skeleton-loader {
      width: calc(20% - 12.8px);
    }

  }

  @media only screen and (max-width: 1100px) {

    .upgrader-items .items-header {
      flex-direction: column;
      align-items: flex-start;
    }

    .upgrader-items .header-search {
      width: 100%;
    }

    .upgrader-items .header-search input {
      width: 100%;
    }

    .upgrader-items  .header-filter {
      margin-top: 26px;
    }

  }

  @media only screen and (max-width: 800px) {

    .upgrader-items .skeleton-loader {
      width: calc(25% - 12px);
    }

  }

  @media only screen and (max-width: 650px) {

    .upgrader-items .skeleton-loader {
      width: calc(33.33% - 10.66px);
    }

  }

  @media only screen and (max-width: 500px) {

    .upgrader-items .skeleton-loader {
      width: calc(50% - 8px);
    }

  }

  @media only screen and (max-width: 475px) {

    .upgrader-items .header-filter {
      flex-direction: column;
      align-items: flex-start;
    }

  }
</style>
