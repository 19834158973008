<template>
  <div class="faq">
    <div class="faq-header">
      <div class="header-title">FREQUENTLY ASKED QUESTIONS</div>
      <div class="header-support">
        Cant find anything related to you problem?
        <RouterLink to="/support">Contact Support</RouterLink>
      </div>
    </div>
    <div class="faq-content">
      <div class="content-column">

        <FaqQuestion :question="{ title: 'How do I contact Support?', answer: 'Click the support button on the navigation bar at the top of the page. From there you can submit a ticket with the necessary information and a support agent will get back to you as soon as possible.' }" />
        <FaqQuestion :question="{ title: 'How do I know the games aren’t rigged?', answer: 'You can verify the outcome of any round using our provably fair system! Click the “Provably Fair” header at the top of the page or the “Fairness” button beside each game to learn more.' }" />
        <FaqQuestion :question="{ title: 'I would like a sponsorship, what do I do?', answer: 'You can make a sponsorship request through our support system. Please only contact us if you already have an established sizeable following.' }" />

      </div>
      <div class="content-column">

        <FaqQuestion :question="{ title: 'I found a bug/exploit, what do I do?', answer: 'Contact us via support and you may be rewarded for helping us improve the site.' }" />
        <FaqQuestion :question="{ title: 'I encountered a visual glitch, what do I do?', answer: 'Users are automatically credited with their winnings. Animations on the site do not determine the winner of each round, that is done by our provably fair system. Sometimes a visual glitch may occur, however, the winners will still be credited.' }" />
        <FaqQuestion :question="{ title: 'Why can’t I deposit?', answer: 'You may not have your Steam trade url set correctly, verify it and try again. You must also have Steam mobile authenticator on your smartphone for 15 days before you are allowed to trade instantly. Unfortunately, this is a Steam policy and nothing can be done on our side. If this still doesn’t help you, please contact support.' }" />

      </div>
      <div class="content-column">

        <FaqQuestion :question="{ title: 'Why must I wager before I withdraw?', answer: 'Unfortunately, we must do this to protect our shop from traders and market manipulators.' }" />
        <FaqQuestion :question="{ title: 'Why can\'t I join giveaways?', answer: 'You must have recently deposited in order to join giveaways. Unfortunately this is the most effective way to combat botting.' }" />
        <FaqQuestion :question="{ title: 'I feel that I may be addicted, what can I do?', answer: 'You can request a self ban through our support system, this will block you from using the site. There are also many resources available online, please visit this link to learn more.', link: 'https://www.begambleaware.org/' }" />

      </div>
    </div>
  </div>
</template>

<script>
  import FaqQuestion from '@/components/faq/FaqQuestion';

  export default {
    metaInfo: {
      title: 'FAQ - RustRoll'
    },
    components: {
      FaqQuestion
    }
  }
</script>

<style scoped>
  .faq {
    width: 100%;
    min-height: calc(100vh - 112px);
    padding: 40px;
  }

  .faq .faq-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    border-bottom: 1px solid #20252D;
  }

  .faq .header-title {
    font-size: 24px;
    font-weight: 700;
    color: #596076;
  }

  .faq .header-support {
    display: flex;
    font-size: 14px;
    font-weight: 500;
    color: #596076;
  }

  .faq .header-support a {
    margin-left: 5px;
    font-size: 14px;
    font-weight: 600;
    color: #fd3b31;
  }

  .faq .faq-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
  }

  .faq .content-column {
    width: calc(33.33% - 10.66px);
    margin-right: 16px;
  }

  .faq .content-column:nth-child(3n) {
    margin-right: 0;
  }

  @media only screen and (max-width: 1650px) {

    .faq {
      padding: 40px 15px 40px 15px;
    }

  }

  @media only screen and (max-width: 1100px) {

    .faq .content-column {
      width: calc(50% - 8px);
      margin-right: 16px;
      margin-top: 12px;
    }

    .faq .content-column:nth-child(1),
    .faq .content-column:nth-child(2) {
      margin-top: 0;
    }

    .faq .content-column:nth-child(3n) {
      margin-right: 16px;
    }

    .faq .content-column:nth-child(2n){
      margin-right: 0;
    }

  }

  @media only screen and (max-width: 1000px) {

    .faq .faq-header {
      flex-direction: column;
      align-items: flex-start;
    }

    .faq .header-support {
      margin-top: 12px;
    }

  }

  @media only screen and (max-width: 700px) {

    .faq .content-column {
      width: 100%;
      margin-right: 0!important;
      margin-top: 12px!important;
    }

    .faq .content-column:nth-child(1) {
      margin-top: 0!important;
    }

  }

  @media only screen and (max-width: 475px) {

    .faq .header-support {
      flex-direction: column;
    }

    .faq .header-support a {
      margin-left: 0;
    }

  }
</style>
