<template>
    <div  class="cases-reel">
        <div v-for="(item, index) in reel" v-bind:key="index" class="reel-element" v-bind:class="[{ 'element-active': index === pos }, 'rarity-' + casesPercent(item)]">
            <div class="element-image">
                <div class="element-rarity">
                    <img v-bind:src="require(`@/assets/img/cases/rusty-${casesPercent(item)}.webp`)" />
                </div>
                <img class="item-image" v-bind:src="item.item.image" />
            </div>
            <Transition name="slide-fade">
                <div v-if="index === 60 && running === false" class="element-info">
                    <span>{{item.item.name}}</span>
                    <div class="info-amount">
                        <img src="@/assets/img/icons/coin.png" alt="coins-image" />
                        <div class="amount-value">
                            <span>{{ generalFormatAmount(item.item.amountFixed).split('.')[0] }}</span>.{{ generalFormatAmount(item.item.amountFixed).split('.')[1] }}
                        </div>
                    </div>
                </div>
            </Transition>
        </div>
    </div>
</template>

<script>
    import mixins from '@/mixins';

    export default {
        name: 'CasesReel',
        mixins: [
            mixins
        ],
        props: [
            'box',
            'reel', 
            'pos', 
            'running'
        ],
        methods: {
            casesPercent(item) {

                if(item.item.amountFixed < this.box.amount * 0.25) {
                    return 'grey'
                } else if(item.item.amountFixed < this.box.amount * 1) {
                    return 'blue';
                } else if(item.item.amountFixed < this.box.amount * 2) {
                    return 'purple';
                } else if(item.item.amountFixed < this.box.amount * 3) {
                    return 'red';
                } else {
                    return 'gold';
                }

            }
        }
    }
</script>

<style scoped>
    .cases-reel {
        height: 100%;
        display: flex;
        align-items: center;
    }

    .cases-spinner.spinner-2 .cases-reel,
    .cases-spinner.spinner-3 .cases-reel,
    .cases-spinner.spinner-4 .cases-reel {
        width: 100%;
        flex-direction: column;
    }

    .cases-reel .reel-element {
        width: 105px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 25px;
        opacity: 0.25;
    }

    .cases-spinner.spinner-2 .reel-element,
    .cases-spinner.spinner-3 .reel-element,
    .cases-spinner.spinner-4 .reel-element {
        width: 100%;
        flex-direction: row;
        margin-right: 0;
        margin-bottom: 20px;
    }

    .cases-reel .reel-element:last-child {
        margin-right: 0;
        margin-bottom: 0;
    }

    .cases-reel .reel-element.element-active {
        opacity: 1;
    }

    .cases-reel .reel-element .element-image {
        width: 105px;
        height: fit-content;
        transition: all 0.2s ease;
        display: flex;
        align-items: center;
        position: relative;
    }
    .cases-reel .reel-element .element-image .element-rarity {
        width: 105px;
        height: 105px;
        z-index: -1;
    }

    .cases-reel .reel-element .element-image .element-rarity::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        filter: blur(20px);
        background: radial-gradient(50% 50% at 50% 50%, rgba(150, 150, 150, .3) 0%, rgba(74, 74, 74, 0) 90%);
    }

    .cases-reel .reel-element.rarity-gold .element-image .element-rarity::before {
        background: radial-gradient(50% 50% at 50% 50%, rgba(231, 212, 39, 0.3) 0%, rgba(74, 34, 34, 0) 90%);
    }

    .cases-reel .reel-element.rarity-red .element-image .element-rarity::before {
        background: radial-gradient(50% 50% at 50% 50%, rgba(231, 39, 39, 0.3) 0%, rgba(128, 34, 42, 0) 90%);
    }

    .cases-reel .reel-element.rarity-purple .element-image .element-rarity::before {
        background: radial-gradient(50% 50% at 50% 50%, rgba(172, 39, 231, .3) 0%, rgba(94, 34, 128, 0) 90%);
    }

    .cases-reel .reel-element.rarity-blue .element-image .element-rarity::before {
        background: radial-gradient(50% 50% at 50% 50%, rgba(39, 112, 231, .3) 0%, rgba(34, 74, 128, 0) 90%);
    }
    .cases-reel .reel-element .element-image .element-rarity img {
        width: 100%;
        height: 100%;
        opacity: 0.5;
    }
    .cases-reel .reel-element .element-image img.item-image {
        width: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .cases-reel .reel-element.element-active .element-image {
        transform: scale(1.15);
    }

    .cases-reel .element-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
    }

    .cases-spinner.spinner-2 .element-info,
    .cases-spinner.spinner-3 .element-info,
    .cases-spinner.spinner-4 .element-info {
        width: auto;
        max-width: calc(100% - 115px);
        align-items: flex-start;
        margin-left: 15px;
    }

    .cases-reel .element-info.slide-fade-enter-active {
        transition: all 0.3s ease;
    }

    .cases-reel .element-info.slide-fade-enter-from {
        transform: translateY(-15px);
        opacity: 0;
    }

    .cases-spinner.spinner-2 .element-info.slide-fade-enter-from,
    .cases-spinner.spinner-3 .element-info.slide-fade-enter-from,
    .cases-spinner.spinner-4 .element-info.slide-fade-enter-from {
        transform: translateX(-15px);
    }

    .cases-reel .element-info span {
        width: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        font-size: 15px;
        font-weight: 600;
        color: #596076;
    }

    .cases-spinner.spinner-2 .element-info span,
    .cases-spinner.spinner-3 .element-info span,
    .cases-spinner.spinner-4 .element-info span {
        width: 100%;
    }

    .cases-reel .info-amount {
        display: flex;
        align-items: center;
        margin-top: 3px;
    }

    .cases-reel .info-amount img {
        width: 18px;
        height: 18px;
        margin-right: 8px;
    }

    .cases-reel .amount-value {
        font-size: 11px;
        font-weight: 600;
        color: #bbbfd0;
    }

    .cases-reel .amount-value span {
        font-size: 14px;
        font-weight: 800;
        color: #ffffff;
    }
</style>