<template>
  <div v-if="generalRainData" class="chat-rain">
    <img src="@/assets/img/icons/coin.png" alt="coins-image" />
    <div class="rain-title">
      <span>RAIN</span>
      <div class="title-tooltip">
        <IconExclamation />
        <div class="tooltip-text">You need to have rustroll.com in your steam name and have joined the rustroll discord server to join the rain.</div>
      </div>
    </div>
    <div class="rain-amount">
      <span>{{generalFormatAmount(generalRainData.amount).split('.')[0]}}</span>.{{generalFormatAmount(generalRainData.amount).split('.')[1]}}
    </div>
    <div class="rain-action">
      <button
        @click="modalsSetShow('RainTip')" 
        class="button-tip" 
      >
        <IconTip />
      </button>
      <button 
        @click="rainJoinButton" 
        class="button-join" 
        :disabled="socketSendLoading || chatIsJoined"
      >{{ chatIsJoined ? 'JOINED' : 'JOIN' }}</button>
    </div>
    <div class="rain-timer">
      <div class="timer-progress" :style="{ 'width': `${(100 / 1800) * rainTimer}%` }"></div>
    </div>
  </div>
</template>

<script>
  import mixins from '@/mixins';
  import { mapGetters, mapActions } from 'vuex';
  import IconExclamation from '@/components/icons/IconExclamation';
  import IconTip from '@/components/icons/IconTip';

  export default {
    components: {
      IconExclamation,
      IconTip
    },
    mixins: [
      mixins
    ],
    props: {
      rainActive: Boolean
    },
    data() {
      return {
        rainTimer: 0,
        rainTimerInterval: null
      }
    },
    methods: {
      ...mapActions([
        'notificationShow', 
        'modalsSetData', 
        'modalsSetShow'
      ]),
      rainStartTimer() {
        const endingTime = new Date(this.generalRainData.createdAt).getTime() + (1000 * 60 * 30);
        let timeLeft = Math.floor((endingTime - (Date.now() + this.generalTimeDiff)) / 1000);

        this.rainTimer = timeLeft <= 0 ? 0 : timeLeft;

        const joinable = this.rainTimer < 2000 ? true : false;
        if(this.rainActive !== joinable) { this.$emit('setRainActive', joinable); }
      },
      rainJoinButton() {
        if(!this.authUser.user) {
          this.notificationShow({ 
            type: 'error', 
            message: 'Please sign in to perform this action.' 
          });
          return;
        }

        this.modalsSetData({ type: 'rainJoin', data: { rainId: this.generalRainData._id } });
        this.modalsSetShow('Captcha');
      }
    },
    computed: {
      ...mapGetters([
        'authUser', 
        'generalTimeDiff', 
        'generalRainData', 
        'socketSendLoading'
      ]),
      chatIsJoined() {
        return this.authUser.user && this.generalRainData.participants.some((element) => element.user === this.authUser.user._id);
      }
    },
    watch: {
      'generalRainData': {
        handler() {
          console.log(this.generalRainData);
          if(this.generalRainData && !this.rainTimerInterval) { 
            this.rainTimerInterval = setInterval(() => { this.rainStartTimer(); }, 500); 
          }
        }
      }
    },
    beforeDestroy() {
      clearInterval(this.rainTimerInterval);
    },
    mounted() {
      console.log(this.generalRainData);
      if(this.generalRainData) { 
        this.rainTimerInterval = setInterval(() => { this.rainStartTimer(); }, 500); 
      }
    }
  }
</script>

<style scoped>
  .chat-rain {
    width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto auto;
    align-items: center;
    gap: 0 10px;
    padding: 12px 12px 16px 12px;
    border-radius: 5px;
    background: #212732;
  }

  .chat-rain:deep(img) {
    width: 40px;
    height: 40px;
    grid-row: 1 / 3;
  } 

  .chat-rain .rain-title {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 700;
  }

  .chat-rain .rain-title span {
    background: linear-gradient(180deg, #fd3b31 0%, #fd6b31 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .chat-rain .title-tooltip {
    position: relative;
    margin-left: 8px;
    cursor: pointer;
  }

  .chat-rain .title-tooltip svg {
    width: 12px;
    fill: #5f6779;
  }

  .chat-rain .tooltip-text {
    width: 230px;
    position: absolute;
    left: 50%;
    top: 25px;
    padding: 10px;
    transform: translate(-50%, 0);
    border-radius: 5px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    color: #767c8b;
    background-color: #181b23;
    visibility: hidden;
    z-index: 10;
  }

  .chat-rain .tooltip-text::before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    left: 50%;
    top: -8px;
    transform: translate(-50%, 0);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #181b23;
  }

  .chat-rain .title-tooltip:hover .tooltip-text {
    visibility: visible;
  }

  .chat-rain .rain-amount {
    font-size: 15px;
    font-weight: 700;
    color: #bbbbbb;
  }

  .chat-rain .rain-amount span {
    font-size: 17px;
    font-weight: 700;
    color: #ffffff;
  }

  .chat-rain .rain-action {
    width: 100%;
    display: flex;
    grid-column: 3 / 3;
    grid-row: 1 / 3;
    gap: 5px;
  }

  .chat-rain button.button-tip,
  .chat-rain button.button-join {
    height: 36px;
    border-radius: 5px;
    transition: all 0.3s ease;
  }

  .chat-rain button.button-tip {
    width: 36px;
    background: #313a4a;
    border-bottom: 2px solid #2a3240;
  }

  .chat-rain button.button-join {
    width: 70px;
    font-size: 13px;
    font-weight: 700;
    color: #ffffff;
    background-color: #00C74D;
    border-bottom: 2px solid #00732C;
  }

  .chat-rain button.button-tip svg {
    width: 18px;
    fill: #758196;
  }

  .chat-rain .rain-timer {
    height: 2px;
    position: absolute;
    bottom: 2px;
    left: 4px;
    right: 4px;
    border-radius: 1px;
    background: #191e27;
  }

  .chat-rain .timer-progress {
    height: 100%;
    border-radius: 1px;
    background: #fd3b31;
  }
</style>