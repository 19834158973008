<template>
    <div class="leaderboard-podium-element">
        <div v-if="winner === null" class="element-loading"></div>
        <div v-else class="element-inner">
            <div class="inner-badge">
                <IconStar />
            </div>
            <AvatarImage v-bind:image="leaderboardGetAvatar" />
            <div v-html="leaderboardGetUsername" class="element-username"></div>
            <div class="inner-wagered">
                <img src="@/assets/img/icons/coin.png" alt="coins-image" />
                <div class="wagered-value">
                    <span>{{ generalFormatAmount(winner.points).split('.')[0] }}</span>.{{ generalFormatAmount(winner.points).split('.')[1] }}
                </div>
            </div>
            <div class="inner-prize">
                <img src="@/assets/img/icons/coin.png" alt="coins-image" />
                <div class="prize-value">
                    <span>{{ generalFormatAmount(winner.prize).split('.')[0] }}</span>.{{ generalFormatAmount(winner.prize).split('.')[1] }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mixins from '@/mixins';
import AvatarImage from '@/components/AvatarImage';
import IconStar from '@/components/icons/IconStar';

export default {
    name: 'LeaderboardPodiumElement',
    components: {
        AvatarImage,
        IconStar
    },
    mixins: [
        mixins
    ],
    props: [
        'winner'
    ],
    computed: {
        leaderboardGetAvatar() {
            let avatar = null;

            if (this.winner.user !== null) { avatar = this.winner.user.avatar; }

            return avatar;
        },
        leaderboardGetUsername() {
            let username = 'Empty';

            if (this.winner.user !== null) { username = this.winner.user.anonymous === true ? 'Hidden' : this.winner.user.username; }

                return username;
            }
        }
    }
</script>

<style scoped>
.leaderboard-podium-element {
    width: 200px;
    height: 234px;
    margin-right: 50px;
    border-radius: 8px;
    background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
}

.leaderboard-podium-element:nth-child(3) {
    margin-right: 0;
}

.leaderboard-podium-element:nth-child(1),
.leaderboard-podium-element:nth-child(3) {
    margin-top: 40px;
}

.leaderboard-podium-element .element-loading,
.leaderboard-podium-element .element-inner {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 25px 10px 10px 10px;
}

.leaderboard-podium-element .element-loading {
    border-radius: 8px;
    overflow: hidden;
}

.leaderboard-podium-element .element-loading:after {
    width: 100%;
    height: 100%;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    animation: loading_animation 1s ease infinite;
    background: linear-gradient(to right, #ffffff00 0%, rgba(255, 255, 255, .1) 50%, #ffffff00 100%);
}

.leaderboard-podium-element .inner-badge {
    width: 30px;
    height: 36px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 15px;
    border-radius: 0 0 5px 5px;
}

.leaderboard-podium-element:nth-child(1) .inner-badge {
    background: #8b8b8b;
}

.leaderboard-podium-element:nth-child(2) .inner-badge {
    background: #e5c900;
}

.leaderboard-podium-element:nth-child(3) .inner-badge {
    background: #c48c29;
}

.leaderboard-podium-element .inner-badge svg {
    fill: #ffffff;
}

.leaderboard-podium-element .avatar-image {
    width: 80px;
    height: 80px;
    border-radius: 5px;
}

.leaderboard-podium-element .inner-username {
    max-width: 120px;
    margin-top: 12px;
    font-size: 15px;
    font-weight: 600;
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.leaderboard-podium-element .inner-wagered,
.leaderboard-podium-element .inner-prize {
    display: flex;
    align-items: center;
    margin-top: 8px;
}

.leaderboard-podium-element .inner-prize {
    width: 100%;
    height: 40px;
    justify-content: center;
    margin-top: 12px;
    border-radius: 5px;
    background: #00c74d;
}

.leaderboard-podium-element .inner-wagered img,
.leaderboard-podium-element .inner-prize img {
    width: 21px;
    margin-right: 10px;
}

.leaderboard-podium-element .wagered-value,
.leaderboard-podium-element .prize-value {
    font-size: 12px;
    font-weight: 700;
    color: #bbbbbb;
}

.leaderboard-podium-element .prize-value {
    color: #ffffff;
}

.leaderboard-podium-element .wagered-value span,
.leaderboard-podium-element .prize-value span {
    font-size: 15px;
    font-weight: 700;
    color: #ffffff;
}

@keyframes loading_animation {
    0% {
        transform: translateX(-100%);
    }

    50% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(100%);
    }
}

@media only screen and (max-width: 800px) {

    .leaderboard-podium-element {
        margin-right: 20px;
    }

}

@media only screen and (max-width: 650px) {

    .leaderboard-podium-element {
        width: 100%;
        margin-top: 0 !important;
        margin-right: 0;
    }

    .leaderboard-podium-element:nth-child(1) {
        grid-column: 1 / 1;
        grid-row: 2 / 2;
    }

    .leaderboard-podium-element:nth-child(2) {
        grid-column: 1 / 3;
        grid-row: 1 / 1;
    }

    .leaderboard-podium-element:nth-child(3) {
        grid-column: 2 / 2;
        grid-row: 2 / 2; }

    }
</style>