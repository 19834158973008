<template>
  <div class="navbar-user">
    <div class="user-actions">
      <RouterLink @click="$parent.navbarSetMobile(false)" to="/withdraw" class="link-withdraw">
        WITHDRAW
      </RouterLink>
      <RouterLink @click="$parent.navbarSetMobile(false)" to="/deposit" class="link-deposit">
        DEPOSIT
        <div class="link-bonus">+35%</div>
      </RouterLink>
    </div>
    <div class="user-balance">
      <img src="@/assets/img/icons/coin.png" alt="icon" />
      <AmountNumber :amount="authUser.user.balance" />
    </div>
    <RouterLink @click="$parent.navbarSetMobile(false)" to="/profile" class="link-profile">
      VIEW PROFILE
      <AvatarImage :image="authUser.user.avatar" />
    </RouterLink>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import AmountNumber from '@/components/AmountNumber';
  import AvatarImage from '@/components/AvatarImage';

  export default {
    components: {
      AmountNumber,
      AvatarImage
    },
    methods: {
      ...mapActions([
        'authLogoutUser',
        'modalsSetShow',
        'modalsSetData'
      ])
    },
    computed: {
      ...mapGetters([
        'authUser'
      ])
    }
  }
</script>

<style scoped>
  .navbar-user {
    display: flex;
    align-items: center;
  }

  .navbar-secondary .navbar-user {
    flex-direction: column;
  }

  .navbar-user .user-actions {
    display: flex;
    position: relative;
    margin-right: 16px;
    padding-right: 16px;
  }

  .navbar-secondary .navbar-user .user-actions {
    width: 100%;
    flex-direction: column;
    margin-right: 0;
    padding-right: 0;
  }

  .navbar-user .user-actions::after {
    content: '';
    width: 1px;
    height: 23px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    background: #262c3a;
  }

  .navbar-secondary .navbar-user .user-actions::after {
    display: none;
  }

  .navbar-secondary .navbar-user .user-actions button {
    width: 100%;
  }

  .navbar-user a.link-withdraw,
  .navbar-user a.link-deposit {
    height: 39px;
    display: flex;
    align-items: center;
    margin-right: 12px;
    padding: 0 16px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 700;
  }

  .navbar-user a.link-withdraw {
    color: #767c8b;
    background: #212732;
    box-shadow: 0px 2px 0px 0px #191e27;
  }

  .navbar-user a.link-deposit {
    color: #ffffff;
    background: #00c74d;
    box-shadow: 0px 2px 0px 0px #00732c;
  }

  .navbar-user a.link-withdraw:hover {
    color: #ffffff;
  }

  .navbar-user a.link-deposit:hover {
    background: #00de56;
  }

  .navbar-user a.link-deposit .link-bonus {
    height: 21px;
    display: flex;
    align-items: center;
    margin-left: 8px;
    padding: 0 4px;
    border-radius: 3px;
    font-size: 11px;
    font-weight: 700;
    color: #ffffff;
    background: #fd3b31;
  }

  .navbar-user .user-balance {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 28px;
    padding: 0 12px;
    border-radius: 5px;
    background: #191e27;
    border: 1px solid #262c3a;
  }

  .navbar-secondary .navbar-user .user-balance {
    display: none;
  }

  .navbar-user .user-balance img {
    width: 21px;
    margin-right: 10px;
  }

  .navbar-user .amount-number {
    font-size: 12px;
    font-weight: 700;
      color: #bbbbbb;
  }

  .navbar-user .amount-number:deep(span) {
    font-size: 15px;
    font-weight: 700;
    color: #ffffff;
  }

  .navbar-user a.link-profile {
    height: 51px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 700;
    color: #888e9d;
    background: #212732;
    border-bottom: 2px solid #191e27;
  }

  .navbar-secondary .navbar-user a.link-profile {
    width: 100%;
    display: none;
    justify-content: space-between;
    margin-top: 8px;
  }

  .navbar-user a.link-profile:hover {
    color: #ffffff;
  }

  .navbar-user a.link-profile .avatar-image {
    height: 33px;
    width: 33px;
    margin-left: 12px;
    border-radius: 5px;
  }

  @media only screen and (max-width: 1300px) {

    .navbar-primary .navbar-user .user-actions {
      display: none;
    }

    .navbar-secondary .user-actions {
      flex-direction: row!important;
      align-items: center;
    }

    .navbar-user .user-actions a.link-withdraw,
    .navbar-user .user-actions a.link-deposit {
      width: calc(50% - 4px);
      justify-content: center;
      margin-right: 0;
    }

    .navbar-user .user-actions a.link-withdraw {
      margin-right: 8px;
    }

    .navbar-user .user-actions a.link-deposit .link-bonus {
      display: none;
    }

  }

  @media only screen and (max-width: 900px) {

    .navbar-user .user-balance {
      margin-right: 0;
    }

    .navbar-user a.link-profile {
      display: none;
    }

    .navbar-secondary .navbar-user a.link-profile {
      display: flex;
    }

  }
</style>
