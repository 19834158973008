<template>
  <div :class="['battles-game-element', {
    'element-crazy': game.options.crazy === true
  }]">
    <div class="element-rounds">
      <div class="rounds-box">{{ battlesGetBoxes.length }}</div>
      <span v-if="(game.mode === 'team' || game.mode === 'pair') || game.options.crazy === true">
        {{ (game.mode === 'team' || game.mode === 'pair') && game.options.crazy === true ? 'Crazy Team' : (game.mode === 'team' || game.mode === 'pair') ? 'Team' : 'Crazy'
        }}
      </span>
      <span>Rounds</span>
    </div>
    <div class="element-cases">
      <div class="cases-list">
        <div class="cases-process" v-bind:style="battlesProcessStyle">
          <div class="cases-process-info">
            <div class="process-top"></div>
            <div class="process-bottom"></div>
          </div>
        </div>
        <BattlesGameOverViewBoxes 
          :boxes="battlesGetBoxes" 
          :game="game"
          :boxWapperWidth="casesWidth" 
        />
      </div>
    </div>
    <div class="element-amount">
      <img src="@/assets/img/icons/coin.png" alt="coins-image" />
      <div class="amount-value">
        <span>{{generalFormatAmount(game.amount - Math.floor(game.amount * game.options.funding / 100)).split('.')[0]}}</span>.{{
        generalFormatAmount(game.amount - Math.floor(game.amount * game.options.funding / 100)).split('.')[1]}}
      </div>
    </div>
    <div class="element-right">
      <div class="right-users">
        <div v-for="(bet, index) in battlesGetBets" :key="index" class="users-element">
          <div class="element-info">
            <AvatarImage v-if="bet !== null" :image="bet.bot === true ? null : bet.user.avatar" />
            <svg v-else xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
              <path class="fa-secondary" opacity=".4"
                d="M256 64C150 64 64 150 64 256s86 192 192 192c70.1 0 131.3-37.5 164.9-93.6l.1 .1c-6.9 14.9-1.5 32.8 13 41.2c15.3 8.9 34.9 3.6 43.7-11.7c.2-.3 .4-.6 .5-.9l0 0C434.1 460.1 351.1 512 256 512C114.6 512 0 397.4 0 256S114.6 0 256 0c-17.7 0-32 14.3-32 32s14.3 32 32 32z" />
              <path class="fa-primary"
                d="M224 32c0-17.7 14.3-32 32-32C397.4 0 512 114.6 512 256c0 46.6-12.5 90.4-34.3 128c-8.8 15.3-28.4 20.5-43.7 11.7s-20.5-28.4-11.7-43.7c16.3-28.2 25.7-61 25.7-96c0-106-86-192-192-192c-17.7 0-32-14.3-32-32z" />
            </svg>
          </div>
          <div v-if="(index + 1) !== game.playerCount && (game.mode !== 'team' && game.mode !== 'pair')"
            class="element-separator">
            vs
          </div>
          <div v-else-if="(index + 1) !== game.playerCount && (game.mode === 'team' && index === (game.playerCount / 2 - 1))"
            class="element-separator">
            vs
          </div>
          <div v-else-if="(index + 1) !== game.playerCount && (game.mode === 'pair' && ((index + 1) % 2) === 0)"
            class="element-separator">
            vs
          </div>
        </div>
      </div>
      <div class="right-actions">
        <button 
          v-if="!['completed', 'rolling'].includes(game.state) && !battlesIsCreator"
          @click="battlesJoinButton()" 
          class="button-join" 
          :disabled="socketSendLoading"
        >
          JOIN GAME
        </button>
        <RouterLink :to="`/battles/${game._id}`" :class="['link-watch', {
          'link-full': ['completed', 'rolling'].includes(game.state) || battlesIsCreator
        }]">
          <svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512">
            <path
              d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
          </svg>
          {{ ['completed', 'rolling'].includes(game.state) || battlesIsCreator ? 'WATCH GAME' : '' }}
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from '@/mixins';
import { mapGetters, mapActions } from 'vuex';
import AvatarImage from '@/components/AvatarImage';
import BattlesGameOverViewBoxes from '@/components/battles/BattlesGameOverViewBoxes';

export default {
  components: {
    AvatarImage,
    BattlesGameOverViewBoxes
  },
  mixins: [
    mixins
  ],
  props: {
    game: Object
  },
  data() {
    return {
      casesWidth: 0,
    }
  },
  methods: {
    ...mapActions([
      'notificationShow',
      'battlesSendJoinSocket'
    ]),
    battlesJoinButton() {
      if(!this.authUser.user) {
        this.notificationShow({ 
          type: 'error', 
          message: 'Please sign in to perform this action.' 
        });
        return;
      }

      this.battlesSendJoinSocket({ 
        gameId: this.game._id, 
        slot: this.game.bets.length 
      });
    },
    checkDivPresence() {
      let div1 = document.querySelectorAll('.element-cases');

      if (div1.length > 0) {
        this.casesWidth = div1[0].offsetWidth;
      }
    },
  },
  computed: {
    ...mapGetters([
      'socketSendLoading',
      'authUser'
    ]),
    battlesGetBets() {
      let bets = [];

      for(let bet = 0; bet < this.game.playerCount; bet++) {
        const index = this.game.bets.findIndex((element) => element.slot === bet);
        bets.push(index !== -1 ? this.game.bets[index] : null);
      }

      return bets;
    },
    battlesGetBoxes() {
      let boxes = [];

      for(const box of this.game.boxes) {
        for (let i = 0; i < box.count; i++) { boxes.push(box.box); }
      }

      return boxes;
    },
    battlesIsCreator() {
      let creator = false;

      if(this.authUser.user && this.authUser.user._id === this.battlesGetBets[0].user._id) { 
        creator = true; 
      }

      return creator;
    },
    battlesGetRound() {
      let round = 1;

      if(this.game && this.game.bets[0].outcomes !== undefined && this.game.bets[0].outcomes.length >= 1) { 
        round = this.game.bets[0].outcomes.length; 
      }

      return round;
    },
    battlesProcessStyle() {
      let processDiff = 98 * (this.battlesGetRound - 1);
      
      if (this.casesWidth > 0 && ((this.casesWidth - 98 * 3) < 98 * (this.battlesGetRound - 1))) {
        processDiff = Math.ceil((this.casesWidth - 98 * 3) / 98) * 98;
      }

      let processStyle = { transform: 'translate(' + (-109 + processDiff) + 'px, -50%)' };

      return processStyle;
    },
  },
  mounted() {
    this.checkDivPresence();
  }
}
</script>

<style scoped>
.battles-game-element {
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 70px calc(100% - 543px) 100px 320px;
  grid-gap: 0 20px;
  margin-top: 15px;
  padding: 10px 20px;
  border-radius: 8px;
  background: #1c202c;
}

.battles-game-element:first-child {
  margin-top: 0;
}

.battles-game-element.element-crazy {
  border: 1px solid rgba(252, 59, 48, 0.25);
  background: linear-gradient(90deg, rgba(252, 59, 48, 0.1) 0%, rgba(252, 59, 48, 0) 80%), #1c202c;
}

.battles-game-element .element-rounds {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.battles-game-element .element-rounds span {
  text-wrap: nowrap;
  font-size: 13px;
  font-weight: 600;
  color: #626c7e;
}

.battles-game-element.element-crazy .element-rounds span {
  color: #fc3b30;
}

.battles-game-element .element-rounds span:first-of-type {
  margin-top: 12px;
}

.battles-game-element .rounds-box {
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  color: #626c7e;
  background: #191e27;
  border: 1px solid #262c3a;
}

.battles-game-element.element-crazy .rounds-box {
  color: #fc3b30;
  background: linear-gradient(90deg, rgba(252, 59, 48, 0.05) 0%, rgba(252, 59, 48, 0.05) 50%), #191e27;
  border: 1px solid rgba(252, 59, 48, 0.5);
}

.battles-game-element .element-cases {
  width: 100%;
  height: 110px;
  border-radius: 8px;
  background: #12161d;
  overflow: hidden;
}

.battles-game-element .cases-list {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;
  position: relative;
}

.battles-game-element .cases-list .cases-process {
  position: absolute;
  height: 100%;
  width: 300px;
  top: 50%;
  transition: all .3s;
}

.battles-game-element .cases-list .cases-process .cases-process-info {
  width: 100%;
  height: 100%;
  position: relative;
}

.battles-game-element .cases-list .cases-process .cases-process-info .process-top {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 5px solid #ffffff62;
}

.battles-game-element .cases-list .cases-process .cases-process-info .process-bottom {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 5px solid #ffffff62;
}

.battles-game-element .cases-list img {
  height: 76px;
  margin-right: 15px;
}

.battles-game-element .element-amount {
  width: 100%;
  display: flex;
  align-items: center;
}

.battles-game-element .element-amount img {
  width: 21px;
  margin-right: 10px;
}

.battles-game-element .amount-value {
  font-size: 12px;
  font-weight: 700;
  color: #bbbbbb;
}

.battles-game-element .amount-value span {
  font-size: 15px;
  font-weight: 700;
  color: #ffffff;
}

.battles-game-element .element-right {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.battles-game-element .right-users {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.battles-game-element .users-element {
  display: flex;
  align-items: center;
  margin-right: 4px;
}

.battles-game-element .users-element:last-child {
  margin-right: 0;
}

.battles-game-element .element-info {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  background: #12161d;
}

.battles-game-element .element-info .avatar-image {
  width: 100%;
  height: 100%;
}

.battles-game-element .element-info svg {
  width: 12px;
  height: 12px;
  fill: #596076;
  animation: waiting_animation 1.5s infinite linear both;
}

.battles-game-element .element-separator {
  margin-left: 4px;
  font-size: 12px;
  font-weight: 700;
  color: #596076;
}

.battles-game-element .right-actions {
  width: 100%;
  /* margin-left: auto;
    margin-right: auto; */
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.battles-game-element button.button-join,
.battles-game-element a.link-watch {
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
}

.battles-game-element button.button-join {
  width: calc(100% - 54px);
  margin-right: 8px;
  color: #ffffff;
  background: #00c74d;
  box-shadow: 0px 2px 0px 0px #00732c;
}

.battles-game-element a.link-watch {
  width: 46px;
  color: #767c8b;
  background: #212732;
  box-shadow: 0px 2px 0px 0px #1d222a;
}

.battles-game-element a.link-watch.link-full {
  width: 100%;
}

.battles-game-element a.link-watch:hover {
  color: #ffffff;
}

.battles-game-element a.link-watch svg {
  fill: #767c8b;
  transition: fill 0.3s ease;
}

.battles-game-element a.link-watch.link-full svg {
  margin-right: 8px;
}

.battles-game-element a.link-watch:hover svg {
  fill: #ffffff;
}

@keyframes waiting_animation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 700px) {

  .battles-game-element {
    grid-template-columns: 56px auto 223px;
    grid-template-rows: auto auto;
    grid-gap: 10px 30px;
  }

  .battles-game-element .element-cases {
    grid-column: 1 / 4;
  }

  .battles-game-element .element-rounds,
  .battles-game-element .element-amount,
  .battles-game-element .element-right {
    grid-row: 2 / 2;
    }

    .battles-game-element .element-amount {
      justify-content: center;
    }

  }
</style>