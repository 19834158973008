const state = {
    towersFilterRisk: 'easy',
    towersData: {
        game: null,
        loading: false
    }
}

const getters = {
    towersFilterRisk: state => state.towersFilterRisk,
    towersData: state => state.towersData
}

const mutations = {
    towers_set_filter_risk(state, value) {
        state.towersFilterRisk = value;
    },
    towers_set_data_game(state, game) {
        state.towersData.game = game;
    },
    towers_set_data_loading(state, status) {
        state.towersData.loading = status;
    }
}

const actions = {
    towersSetFilterRisk({ getters, commit }, value) {
        if(getters.towersFilterRisk === value) { return; }
        commit('towers_set_filter_risk', value);
    },
    towersClearGame({ commit }) {
        commit('towers_set_data_game', null);
    },
    towersGetDataSocket({ getters, commit, dispatch }, data) {
        if(getters.towersData.loading === true) { return; }
        commit('towers_set_data_loading', true);

        getters.socketClient.emit('towers:getData', data, (res) => {
            if(res.success === true) {
                commit('towers_set_data_game', res.game);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('towers_set_data_loading', false);
        });
    },
    towersSendCreateSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'TowersCreate');

        getters.socketClient.emit('towers:sendCreate', data, (res) => {
            if(res.success === true) {
                commit('auth_update_user', res.user);
                commit('towers_set_data_game', res.game);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    towersSendRevealSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'TowersReveal');

        getters.socketClient.emit('towers:sendReveal', data, (res) => {
            if(res.success === true) {
                commit('towers_set_data_game', res.game);

                if(getters.soundTowers === 1 && res.game.state === 'created') {
                    getters.soundTowersReveal.volume = getters.soundVolume;
                    getters.soundTowersReveal.currentTime = 0;
                    getters.soundTowersReveal.play();
                } else if(getters.soundTowers === 1 && res.game.state === 'completed') {
                    getters.soundTowersLoss.volume = getters.soundVolume;
                    getters.soundTowersLoss.play();
                }
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    towersSendCashoutSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'TowersCashout');

        getters.socketClient.emit('towers:sendCashout', data, (res) => {
            if(res.success === true) {
                commit('auth_update_user', res.user);
                commit('towers_set_data_game', res.game);

                if(getters.soundTowers === 1) {
                    getters.soundCash.volume = getters.soundVolume;
                    getters.soundCash.play();
                }
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    }
}

const towers = {
    state,
    mutations,
    actions,
    getters
}

export default towers;
