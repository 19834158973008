<template>
  <div class="modal-rain-tip">
    <div class="tip-close">
      <button @click="modalsSetShow(null)">
        <svg width="11" height="11" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0005 1.22222L9.77827 0L5.50019 4.27808L1.22222 0.000111474L0 1.22233L4.27797 5.5003L0.000501987 9.77776L1.22272 11L5.50019 6.72252L9.77776 11.0001L11 9.77788L6.72241 5.5003L11.0005 1.22222Z" />
        </svg>
        CLOSE
      </button>
    </div>
    <div class="tip-content">
      <div class="content-title">TIP RAIN</div>
      <div class="content-info">
        Donate your balance towards the rain, a message will appear in chat with your name. Minimum tip is <span>1.00</span>.
      </div>
      <div class="content-input">
        <img src="@/assets/img/icons/coin.png" />
        <input v-model="modalAmount"  @input="modalValidateInput" type="text" value="0.00" />
      </div>
      <div class="content-button">
        <button 
          @click="modalTipButton" 
          :class="['button-tip', { 'button-loading': socketSendLoading === 'GeneralRainTip' }]" 
          :disabled="socketSendLoading"
        >
          <Transition name="fade" mode="out-in">
            <div v-if="socketSendLoading === 'GeneralRainTip'" class="button-loading" key="loading">
              <LoadingAnimation />
            </div>
            <div v-else class="button-content" key="content">TIP RAIN</div>
          </Transition>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import LoadingAnimation from '@/components/LoadingAnimation';

  export default {
    components: {
      LoadingAnimation
    },
    data() {
      return {
        modalAmount: '1.00'
      }
    },
    methods: {
      ...mapActions([
        'modalsSetShow', 
        'notificationShow', 
        'generalSendRainTipSocket'
      ]),
      modalValidateInput() {
        this.modalAmount = this.modalAmount.replace(/[^\d.]/g, '');
      },
      modalTipButton() {
        if(this.socketSendLoading !== null) { return; }

        const amount = Math.floor(this.modalAmount * 1000);

        if(amount === undefined || isNaN(amount) === true || amount <= 0) {
          this.notificationShow({ type: 'error', message: 'Your entered rain tip amount is invalid.' });
          return;
        }
        
        this.generalSendRainTipSocket({ 
          rainId: this.generalRainData._id, 
          amount: amount 
        });
      }
    },
    computed: {
      ...mapGetters([
        'socketSendLoading', 
        'authUser',
        'generalRainData'
      ])
    }
  }
</script>

<style scoped>
  .modal-rain-tip {
    width: 620px;
    position: relative;
    display: flex;
    justify-content: center;
    padding: 25px 0 44px 0;
    border-radius: 18px;
    background: linear-gradient(180deg, #1A1E29 0%, #1C202C 100%);
    border-top: 32px solid #1e212e;
    border-bottom: 32px solid #1e212e;
  }

  .modal-rain-tip .tip-close {
    position: absolute;
    top: -14px;
    right: 12px;
  }

  .modal-rain-tip .tip-close button {
    height: 27px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 700;
    color: #767c8b;
    background-color: #212732;
    transition: all 0.3s ease;
  }

  .modal-rain-tip .tip-close button:hover {
    color: #ffffff;
  }

  .modal-rain-tip .tip-close button svg {
    margin-right: 8px;
    fill: #767c8b;
    transition: all 0.3s ease;
  }

  .modal-rain-tip .tip-close button:hover svg {
    fill: #ffffff;
  }

  .modal-rain-tip .tip-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modal-rain-tip .content-title {
    text-transform: uppercase;
    font-size: 26px;
    font-weight: 800;
    color: #323743;
  }

  .modal-rain-tip .content-info {
    width: 370px;
    margin-top: 7px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
  }

  .modal-rain-tip .content-info span {
    color: #00C74d;
  }

  .modal-rain-tip .content-input {
    width: 370px;
    position: relative;
    margin-top: 25px;
  }

  .modal-rain-tip .content-input img {
    width: 24px;
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translate(0, -50%);
  }

  .modal-rain-tip .content-input input {
    width: 100%;
    height: 54px;
    padding: 0 17px 0 54px;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 700;
    color: #ffffff;
    background-color: #191e27;
    border: 1px solid #262c3a;
  }

  .modal-rain-tip .content-input input::placeholder {
    font-size: 13px;
    font-weight: 700;
    color: #596076;
  }

  .modal-rain-tip .content-button {
    width: 370px;
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
  }

  .modal-rain-tip .content-button button.button-tip {
    width: 100%;
    height: 55px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    background-color: #00c74d;
    border-bottom: 2px solid #00732c;
    transition: all 0.3s ease;
  }

  .modal-rain-tip .content-button button.button-tip:hover {
    background-color: #00de56;
  }

  .modal-rain-tip .content-button button.button-tip.button-loading {
    background-color: #00c74d;
    cursor: not-allowed;
  }

  .modal-rain-tip .content-button button.button-tip .button-loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-rain-tip .content-button button.button-tip .button-loading svg {
    font-size: 16px;
  }

  .modal-rain-tip .content-button button.button-tip .button-loading.fade-leave-active {
    transition: opacity 0.5s;
  }

  .modal-rain-tip .content-button button.button-tip .button-loading.fade-leave-to {
    opacity: 0;
  }

  .modal-rain-tip .content-button button.button-tip .button-content.fade-enter-active {
    transition: opacity 0.5s;
  }

  .modal-rain-tip .content-button button.button-tip .button-content.fade-enter-from {
    opacity: 0;
  }

  @media only screen and (max-width: 650px) {

    .modal-rain-tip {
      width: 100%;
    }

    .modal-rain-tip .tip-coin-big {
      display: none;
    }

    .modal-rain-tip .tip-coin-medium {
      display: none;
    }

  }

  @media only screen and (max-width: 450px) {

    .modal-rain-tip .tip-content {
      padding: 0 20px;
    }

    .modal-rain-tip .content-info {
      width: 100%;
    }

    .modal-rain-tip .content-input {
      width: 100%;
    }

    .modal-rain-tip .content-button {
      width: 100%;
    }

  }
</style>