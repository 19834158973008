<template>
    <div class="mines">
        <div class="mines-container">
            <div class="container-actions">
                <div class="actions-controls">
                    <div class="controls-input">
                        <div class="input-title">PLACE YOUR BET</div>
                        <div class="input-content">
                            <img src="@/assets/img/icons/coin.png" alt="coins-image" />
                            <input v-model="minesAmount" v-on:input="minesAmount = generalFormatInputAmount(minesAmount)" v-bind:disabled="minesIsGameRunning === true" type="text" />
                            <div class="content-buttons">
                                <button v-on:click="minesSetAmount('1/2')" v-bind:disabled="minesIsGameRunning === true">1/2</button>
                                <button v-on:click="minesSetAmount('x2')" v-bind:disabled="minesIsGameRunning === true">x2</button>
                                <button v-on:click="minesSetAmount('max')" v-bind:disabled="minesIsGameRunning === true">MAX</button>
                            </div>
                        </div>
                    </div>
                    <div class="controls-mines">
                        <div class="mines-title">NUMBER OF MINES</div>
                        <div class="mines-content">
                            <div class="content-input">
                                <img src="@/assets/img/icons/bomb.png" alt="bomb" />
                                <input v-model="minesCount" type="number" min="1" max="24" />
                                <span>/25</span>
                            </div>
                            <div class="content-actions">
                                <button v-on:click="minesSetCount(1)" v-bind:class="{ 'button-active': Number(this.minesCount) === 1 }">
                                    <img src="@/assets/img/icons/bomb.png" alt="bomb" />
                                    1
                                </button>
                                <button v-on:click="minesSetCount(3)" v-bind:class="{ 'button-active': Number(this.minesCount) === 3 }">
                                    <img src="@/assets/img/icons/bomb.png" alt="bomb" />
                                    3
                                </button>
                                <button v-on:click="minesSetCount(5)" v-bind:class="{ 'button-active': Number(this.minesCount) === 5 }">
                                    <img src="@/assets/img/icons/bomb.png" alt="bomb" />
                                    5
                                </button>
                                <button v-on:click="minesSetCount(10)" v-bind:class="{ 'button-active': Number(this.minesCount) === 10 }">
                                    <img src="@/assets/img/icons/bomb.png" alt="bomb" />
                                    10
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="controls-bet">
                        <button v-if="minesIsGameRunning === false" v-on:click="minesCreateButton()" class="button-create" v-bind:disabled="socketSendLoading !== null">PLACE BET</button>
                        <button v-else v-on:click="minesCashoutButton()" class="button-cashout" v-bind:disabled="socketSendLoading !== null">
                            CASHOUT
                            <img src="@/assets/img/icons/coin.png" alt="coins-image" />
                            <div class="button-amount">
                                <span>{{generalFormatAmount(minesGetCashoutAmount).split('.')[0]}}</span>.{{generalFormatAmount(minesGetCashoutAmount).split('.')[1]}}
                            </div>
                        </button>
                    </div>
                    <div class="controls-risk" v-bind:class="'risk-' + minesGetRiskLevel">
                        <div class="risk-info">
                            <div class="info-text">RISK LEVEL</div>
                            <div class="info-value">{{minesGetRiskLevel.toUpperCase()}}</div>
                        </div>
                        <div class="risk-bar">
                            <div class="bar-progress" v-bind:style=" { 'width': minesGetRiskLevelPercentage + '%' }"></div>
                        </div>
                    </div>
                </div>
                <div class="actions-info">
                    <RouterLink to="/fair/mines" class="link-fair">
                        <IconFairness />
                        FAIRNESS
                    </RouterLink>
                    <button v-on:click="minesMuteButton()" class="button-sound">
                        <IconMute v-if="soundMines === 1" />
                        <IconUnmute v-else />
                        {{ soundMines === 1 ? 'MUTE' : 'UNMUTE' }}

                    </button>
                </div>
            </div>

            <MinesGame />
        </div>

        <Bets />
    </div>
</template>

<script>
    import mixins from '@/mixins';
    import { mapGetters, mapActions } from 'vuex';
    import IconFairness from '@/components/icons/IconFairness';
    import IconMute from '@/components/icons/IconMute';
    import IconUnmute from '@/components/icons/IconUnmute';
    import Bets from '@/components/bets/Bets';
    import MinesGame from '@/components/mines/MinesGame';

    export default {
        name: 'Mines',
        metaInfo: {
            title: 'Mines - RustRoll'
        },
        components: {
            IconFairness,
            IconMute,
            IconUnmute,
            MinesGame,
            Bets
        },
        mixins: [
            mixins
        ],
        data() {
            return {
                minesAmount: '0.00',
                minesCount: 5
            }
        },
        methods: {
            ...mapActions([
                'notificationShow', 
                'minesGetDataSocket',
                'minesSendCreateSocket', 
                'minesSendCashoutSocket', 
                'minesClearGame', 
                'soundSetMines'
            ]),
            minesSetAmount(action) {
                let amount = Math.floor(this.minesAmount * 1000);

                if(action === '1/2') {
                    amount = Math.floor(amount / 2);
                } else if(action === 'x2') {
                    amount = Math.floor(amount * 2);
                } else if(action === 'max') {
                    amount = this.authUser.user.balance;
                }

                amount = amount >= this.authUser.user.balance ? this.authUser.user.balance : amount;
                this.minesAmount = (Math.floor(amount / 10) / 100).toFixed(2);
            },
            minesSetCount(amount) {
                if(this.minesData.game !== null && this.minesData.game.state === 'created') { return; }
                this.minesCount = amount;
            },
            minesCreateButton() {
                if(this.authUser.user === null) {
                    this.notificationShow({ type: 'error', message: 'Please sign in to perform this action.' });
                    return;
                }

                if(isNaN(this.minesAmount) === true || this.minesAmount < 0.1) {
                    this.notificationShow({type: 'error', message: 'Your provided bet amount is invalid.'});
                    return;
                }

                if(this.minesCount < 1 || this.minesCount > 24) {
                    this.notificationShow({type: 'error', message: 'Your provided mines count is invalid.'});
                    return;
                }

                this.minesSendCreateSocket({ amount: Math.floor(this.minesAmount * 1000), minesCount: Math.floor(this.minesCount) });
            },
            minesCashoutButton() {
                this.minesSendCashoutSocket({});
            },
            minesMuteButton() {
                if(this.soundMines === 1) {
                    this.soundSetMines(0);
                } else {
                    this.soundSetMines(1);
                }
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading',
                'authUser', 
                'minesData', 
                'soundMines'
            ]),
            minesIsGameRunning() {
                return this.minesData.game !== null && this.minesData.game.state !== 'completed';
            },
            minesGetCashoutAmount() {
                let amount = 0;

                if(this.minesData.game !== null && this.minesData.game.revealed.length >= 1) {
                    amount = Math.floor(this.minesData.game.amount * this.minesGetMultiplier(this.minesData.game));
                }

                return amount;
            },
            minesGetRiskLevel() {
                let level = 'low';

                if(this.minesData.game !== null) {
                    const left = 25 - (this.minesData.game.minesCount + this.minesData.game.revealed.length);

                    if(left <= 5) { level = 'high'; } 
                    else if(left <= 15) { level = 'medium'; }
                }

                return level;
            },
            minesGetRiskLevelPercentage() {
                let percentage = 0;

                if(this.minesData.game !== null) {
                    percentage =  (100 / 24) * (this.minesData.game.minesCount + this.minesData.game.revealed.length);
                }

                return percentage;
            }
        },
        created() {
            if(this.authUser.user !== null) {this.minesGetDataSocket({}); }
        }
    }
</script>

<style scoped>
    .mines {
        width: 1300px;
        min-height: calc(100vh - 112px);
        padding: 70px 0;
    }

    .mines .mines-container {
        width: 100%;
        display: flex;
    }

    .mines .container-actions {
        width: 380px;
        height: 700px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 32px 24px;
        border-radius: 18px;
        background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
    }

    .mines .actions-controls {
        width: 100%;
    }

    .mines .controls-input {
        width: 100%;
    }

    .mines .input-title {
        width: 100%;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
        color: #596076;
    }

    .mines .input-content {
        width: 100%;
        position: relative;
        margin-top: 12px;
        border-radius: 8px;
        background: #191e27;
        border: 1px solid #262c3a;
    }

    .mines .input-content img {
        width: 24px;
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translate(0, -50%);
    }

    .mines .input-content input {
        width: 100%;
        height: 58px;
        padding: 0 160px 0 54px;
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .mines .content-buttons {
        height: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        right: 0;
    }

    .mines .content-buttons button {
        margin: 0 15px;
        font-size: 14px;
        font-weight: 700;
        color: #626c7e;
    }

    .mines .content-buttons button:not([disabled]):hover {
        color: #ffffff;
    }

    .mines .controls-mines {
        width: 100%;
        margin-top: 24px;
    }

    .mines .mines-title {
        width: 100%;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
        color: #596076;
    }

    .mines .mines-content {
        width: 100%;
        margin-top: 12px;
    }

    .mines .content-input {
        width: 100%;
        position: relative;
    }

    .mines .content-input img {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translate(0, -50%);
    }

    .mines .content-input span {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translate(0, -50%);
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .mines .content-input input {
        width: 100%;
        height: 58px;
        padding: 0 54px;
        border-radius: 8px;
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
        background: #212732;
        -moz-appearance: textfield;
    }

    .mines .content-input input::-webkit-outer-spin-button,
    .mines .content-input input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    .mines .content-actions {
        width: 100%;
        display: flex;
        margin-top: 18px;
    }

    .mines .content-actions button {
        width: calc(25% - 12px);
        height: 39px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background: #212732;
        box-shadow: 0px 2px 0px 0px #1d222a;
    }

    .mines .content-actions button:last-of-type {
        margin-right: 0;
    }

    .mines .content-actions button.button-active {
        color: #fd3b31;
        background: #252129;
        box-shadow: 0px 0px 0px 1px #59272b;
    }

    .mines .content-actions button img {
        width: 24px;
        margin-right: 15px;
    }

    .mines .controls-bet {
        width: 100%;
        margin-top: 32px;
    }

    .mines .controls-bet button.button-create,
    .mines .controls-bet button.button-cashout {
        width: 100%;
        height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
    }

    .mines .controls-bet button.button-create {
        background: #00c74d;
        border-bottom: 2px solid #00732c;
    }

    .mines .controls-bet button.button-cashout {
        background: #fd3b31;
        border-bottom: 2px solid #97302b;
    }

    .mines .controls-bet button.button-create:not([disabled]):hover {
        background: #00de56;
    }

    .mines .controls-bet button.button-cashout:not([disabled]):hover {
        background: #fe524a;
    }

    .mines .controls-bet button.button-cashout img {
        width: 21px;
        margin-left: 12px;
    }

    .mines .controls-bet button.button-cashout .button-amount {
        margin-left: 10px;
        font-size: 12px;
        font-weight: 700;
        color: #ffffff;
    }

    .mines .controls-bet button.button-cashout .button-amount span {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .mines .controls-risk {
        width: 100%;
        margin-top: 18px;
    }

    .mines .risk-info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .mines .info-text {
        font-size: 13px;
        font-weight: 700;
        color: #596076;
    }

    .mines .info-value {
        font-size: 13px;
        font-weight: 700;
        transition: all 0.3s ease;
    }

    .mines .controls-risk.risk-low .info-value {
        color: #00c74d;
    }

    .mines .controls-risk.risk-medium .info-value {
        color: #f4d03f;
    }

    .mines .controls-risk.risk-high .info-value {
        color: #fd3b31;
    }

    .mines .risk-bar {
        width: 100%;
        height: 8px;
        display: flex;
        margin-top: 12px;
        border-radius: 4px;
        background: #212732;
    }

    .mines .bar-progress {
        height: 8px;
        border-radius: 4px;
        transition: all 0.3s ease;
    }

    .mines .controls-risk.risk-low .bar-progress {
        background: #00c74d;
    }

    .mines .controls-risk.risk-medium .bar-progress {
        background: #f4d03f;
    }

    .mines .controls-risk.risk-high .bar-progress {
        background: #fd3b31;
    }

    .mines .actions-info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
    }

    .mines .actions-info a.link-fair,
    .mines .actions-info button.button-sound {
        width: calc(50% - 8px);
        height: 41px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 700;
        color: #767c8b;
        background: #212732;
        border-bottom: 2px solid #1d222a;
    }

    .mines .actions-info a.link-fair:hover,
    .mines .actions-info button.button-sound:hover {
        color: #ffffff;
    }

    .mines .actions-info a.link-fair svg,
    .mines .actions-info button.button-sound svg {
        width: 16px;
        margin-right: 8px;
        fill: #767c8b;
        transition: fill 0.3s ease;
    }

    .mines .actions-info a.link-fair:hover svg,
    .mines .actions-info button.button-sound:hover svg {
        fill: #ffffff;
    }
    
    @media only screen and (max-width: 1330px) {

        .mines {
            width: 100%;
            padding: 20px 10px;
        }

    }

    @media only screen and (max-width: 1100px) {

        .mines .mines-container {
            flex-direction: column-reverse;
        }

        .mines .container-actions {
            width: 100%;
            height: auto;
            margin-top: 15px;
        }

    }
</style>
