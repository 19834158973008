<template>
    <div v-bind:class="['filter-item-price', { 
        'price-open': filterDropdown === true 
    }]">
        <div class="price-title">PRICE RANGE:</div>
        <div class="price-dropdown">
            <button class="dropdown-toggle" v-on:click="filterSetDropdown(!filterDropdown)">
                <img src="@/assets/img/icons/coin.png" alt="coins-image" />
                <div v-if="filterValue === 'all'" class="toggle-all">
                    {{filterValue}}
                </div>
                <div v-else-if="filterValue === '100.00+'" class="toggle-amount">
                    <span>{{filterValue.split('.')[0]}}</span>.{{filterValue.split('.')[1]}}
                </div>
                <div v-else class="toggle-amount">
                    <span>{{filterValue.split('-')[0].split('.')[0]}}</span>.{{filterValue.split('-')[0].split('.')[1]}} <span>- {{filterValue.split('-')[1].split('.')[0]}}</span>.{{filterValue.split('-')[1].split('.')[1]}}
                </div>
                <IconCaretDown />
            </button>
            <div class="dropdown-menu">
                <div class="menu-inner">
                    <button v-on:click="filterSetValue('all')">
                        <img src="@/assets/img/icons/coin.png" alt="coins-image" />
                        <div class="button-value">
                            <span>ALL</span>
                        </div>
                    </button>
                    <button v-on:click="filterSetValue('0.00 - 5.00')">
                        <img src="@/assets/img/icons/coin.png" alt="coins-image" />
                        <div class="button-value">
                            <span>0</span>.00 <span> - 5</span>.00
                        </div>
                    </button>
                    <button v-on:click="filterSetValue('5.00 - 25.00')">
                        <img src="@/assets/img/icons/coin.png" alt="coins-image" />
                        <div class="button-value">
                            <span>5</span>.00 <span> - 25</span>.00
                        </div>
                    </button>
                    <button v-on:click="filterSetValue('25.00 - 100.00')">
                        <img src="@/assets/img/icons/coin.png" alt="coins-image" />
                        <div class="button-value">
                            <span>25</span>.00 <span> - 100</span>.00
                        </div>
                    </button>
                    <button v-on:click="filterSetValue('100.00+')">
                        <img src="@/assets/img/icons/coin.png" alt="coins-image" />
                        <div class="button-value">
                            <span>100</span>.00+
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import IconCaretDown from '@/components/icons/IconCaretDown';

    export default {
        name: 'SteamFilterPrice',
        components: {
            IconCaretDown
        },
        props: [
            'filterValue'
        ],
        data() {
            return {
                filterDropdown: false
            }
        },
        methods: {
            filterSetDropdown(value) {
                this.filterDropdown = value;
            },
            filterSetValue(value) {
                this.$emit('setFilterValue', value);
                this.filterSetDropdown(false);
            }
        },
        mounted() {
            let self = this;
            document.addEventListener('click', function(event) {
                if(!self.$el.contains(event.target) && self.filterDropdown == true) {
                    self.filterSetDropdown(false);
                }
            });
        }
    }
</script>

<style scoped>
    .filter-item-price {
        display: flex;
        align-items: center;
        margin-right: 25px;
    }

    .filter-item-price .price-title {
        margin-right: 8px;
        font-size: 14px;
        font-weight: 700;
        color: #596076;
    }

    .filter-item-price .price-dropdown {
        position: relative;
    }

    .filter-item-price button.dropdown-toggle {
        display: flex;
        align-items: center;
    }

    .filter-item-price button.dropdown-toggle img {
        width: 21px;
        margin-right: 8px;
    }

    .filter-item-price button.dropdown-toggle .toggle-all {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
    }

    .filter-item-price button.dropdown-toggle .toggle-amount {
        font-size: 14px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .filter-item-price button.dropdown-toggle .toggle-amount span {
        color: #ffffff;
    }

    .filter-item-price button.dropdown-toggle svg {
        height: 9px;
        margin-left: 6px;
        fill: #ffffff;
        transition: all 0.3s ease;
    }

    .filter-item-price.price-open button.dropdown-toggle svg {
        transform: rotate(180deg);
    }

    .filter-item-price .dropdown-menu {
        width: 150px;
        height: 0;
        position: absolute;
        top: 35px;
        left: 50%;
        transform: translate(-50%, 0);
        overflow: hidden;
        transition: height 0.2s ease;
        z-index: 10;
    }

    .filter-item-price.price-open .dropdown-menu {
        height: 183px;
    }

    .filter-item-price .menu-inner {
        width: 100%;
        padding: 3px;
        border-radius: 5px;
        background: #212732;
        border: 1px solid #191e27;
    }

    .filter-item-price .menu-inner button {
        width: 100%;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
    }

    .filter-item-price .menu-inner button:hover {
        background: #191d26;
    }

    .filter-item-price .menu-inner button img {
        width: 21px;
        margin-right: 8px;
    }

    .filter-item-price .button-value {
        font-size: 14px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .filter-item-price .button-value span {
        color: #ffffff;
    }
</style>