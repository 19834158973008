<template>
  <div :class="['blackjack-card', `card-${card.suit}`, { 
    'card-hidden': card.rank === 'hidden' 
  }]">
    <div class="card-inner">
      <div class="inner-front" v-if="card.rank !== 'hidden'">
        <img :src="require(`@/assets/img/cards/${card.rank.toLowerCase()}_${card.suit}.jpg`)" alt="card" />
      </div>
      <div class="inner-back">
        <img src="@/assets/img/cards/back.webp" alt="card-back" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      card: Object
    }
  }
</script>

<style scoped>
  .blackjack-card {
    width: 86px;
    height: 120px;
    position: absolute;
    perspective: 1000px;
  }

  .blackjack-card:nth-child(2) {
    top: 10px;
    left: 40px;
  }

  .blackjack-card:nth-child(3) {
    top: 20px;
    left: 80px;
  }

  .blackjack-card:nth-child(4) {
    top: 30px;
    left: 120px;
  }

  .blackjack-card:nth-child(5) {
    top: 40px;
    left: 160px;
  }

  .blackjack-card.card-club,
  .blackjack-card.card-spade {
    color: #000000;
  }

  .blackjack-card.card-heart,
  .blackjack-card.card-diamond {
    color: #db3d2e;
  }

  .blackjack-card .card-inner {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    animation: dealcard-dealer 0.6s linear forwards;
  }

  .blackjack-card.card-hidden .card-inner {
    transform: rotateY(-180deg);
    animation: none;
  }

  .blackjack-card .inner-front,
  .blackjack-card .inner-back {
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .blackjack-card .inner-front {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px;
    border-radius: 8px;
    background: #ebe9e6;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.3);
    transition: all 0.3s;
  }

  .result-won .blackjack-card .inner-front {
    box-shadow: 0 0 0 3px rgba(0, 199, 77, 0.8);
  }

  .result-draw .blackjack-card .inner-front {
    box-shadow: 0 0 0 3px rgba(255, 201, 61, 0.8);
  }

  .result-lose .blackjack-card .inner-front {
    box-shadow: 0 0 0 3px rgba(253, 59, 49, 0.8);
  }

  .blackjack-card.card-hidden .inner-front {
    display: none;
  }

  .blackjack-card .inner-front img {
    height: 120px;
    border-radius: 8px;
  }

  .blackjack-card .inner-back {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 5px;
    transform: rotateY(-180deg);
    background: #ebe9e6;
    box-shadow: 0 0 9px 0px rgba(0, 0, 0, 0.3);
  }

  .blackjack-game .inner-back img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }

  @keyframes dealcard-dealer {
    0% {
      transform: rotateY(-180deg);
    }
    50% {
      transform: rotateY(-180deg);
    }
    100% {
      transform: rotateY(0deg);
    }
  }

  @media only screen and (max-width: 1100px) {

    .blackjack-card {
      width: 72px;
      height: 100px;
    }

    .blackjack-card:nth-child(2) {
      top: 10px;
      left: 35px;
    }

    .blackjack-card:nth-child(3) {
      top: 20px;
      left: 70px;
    }

    .blackjack-card:nth-child(4) {
      top: 30px;
      left: 105px;
    }

    .blackjack-card:nth-child(5) {
      top: 40px;
      left: 140px;
    }

    .blackjack-card .inner-front img {
      height: 100px;
    }


  }
</style>
