<template>
    <div class="notifications-message" v-bind:class="'message-'+notification.type" v-on:click="sendNotificationRemove">
        <div class="message-content">
            <div class="content-icon">
                <svg v-if="notification.type === 'success' || notification.type === 'transaction'" width="13.745" height="10.673" viewBox="0 0 13.745 10.673" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.263,10.873a.7.7,0,0,1-.992,0L.308,6.91a1.052,1.052,0,0,1,0-1.488l.5-.5a1.052,1.052,0,0,1,1.489,0L4.767,7.4,11.452.715a1.052,1.052,0,0,1,1.489,0l.5.5a1.052,1.052,0,0,1,0,1.488Zm0,0" />
                </svg>
                <svg v-else-if="notification.type === 'error'" width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.98,6l3.712-3.712a1.05,1.05,0,0,0,0-1.485L11.2.308a1.049,1.049,0,0,0-1.485,0L6,4.021,2.288.308A1.05,1.05,0,0,0,.8.308L.308.8a1.05,1.05,0,0,0,0,1.485L4.021,6,.309,9.713a1.049,1.049,0,0,0,0,1.485l.495.495a1.05,1.05,0,0,0,1.485,0L6,7.98l3.712,3.712a1.05,1.05,0,0,0,1.485,0l.495-.495a1.05,1.05,0,0,0,0-1.485Zm0,0" />
                </svg>
            </div>
            <div class="content-info">
                <div class="info-text" v-html="notification.message"></div>
                <a v-if="notification.type === 'transaction'" v-bind:href="'https://steamcommunity.com/tradeoffer/'+notification.transaction.data.transaction" target="_blank">ACCEPT</a>
            </div>
        </div>
        <div class="message-timer">
            <div class="timer-progress" v-bind:style="{ width: timerWidth+'%'}"></div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';

    export default {
        name: 'NotificationsMessage',
        props: ['notification'],
        data: function() {
            return {
                timer: 5000,
                notificationInterval: null
            }
        },
        methods: {
            ...mapActions(['notificationRemove']),
            sendNotificationRemove() {
                this.notificationRemove(this.notification);
            }
        },
        computed: {
            getNotificationType: function() {
                return this.notification.type.charAt(0).toUpperCase() + this.notification.type.slice(1);
            },
            timerWidth: function() {
                return (100 / 5000) * this.timer;
            }
        },
        created() {
            let self = this;
            const target = Date.now() + self.timer;

            this.notificationInterval = setInterval(function() {
                self.timer = target - Date.now();

                if(self.timer <= 0) {
                    clearInterval(self.notificationInterval);
                    self.notificationRemove(self.notification);
                }
            }, 1);
        },
        beforeUnmount() {
            clearInterval(this.notificationInterval);
        }
    }
</script>

<style scoped>
    .notifications-message {
        width: 375px;
        margin-top: 10px;
        padding: 10px 12px 4px 12px;
        border-radius: 5px;
        background: linear-gradient(180deg, #1A1E29 0%, #1C202C 100%);
        cursor: pointer;
    }

    .notifications-message:first-of-type {
        margin-top: 0px;
    }

    .notifications-message .message-content {
        display: flex;
        align-items: center;
    }

    .notifications-message .content-icon {
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }

    .notifications-message.message-success .content-icon,
    .notifications-message.message-transaction .content-icon {
        background: rgba(0, 199, 77, 0.12);
    }

    .notifications-message.message-error .content-icon {
        background: rgba(253, 59, 49, 0.12);
    }

    .notifications-message .content-icon svg {
        width: 13px;
        height: auto;
    }

    .notifications-message.message-success .content-icon svg,
    .notifications-message.message-transaction .content-icon svg {
        fill: #00c74d;
    }

    .notifications-message.message-error .content-icon svg {
        fill: #fd3b31;
    }

    .notifications-message .content-info {
        width: calc(100% - 45px);
        display: flex;
        align-items: center;
        padding-left: 18px;
    }

    .notifications-message .content-info a {
        width: 85px;
        height: 41px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background: #fd3b31;
        border-bottom: 2px solid #97302b;
    }

    .notifications-message .content-info a:hover {
        background: #fe524a;
    }

    .notifications-message .info-text {
        width: 100%;
        font-size: 12px;
        font-weight: 600;
        color: #767c8b;
    }

    .notifications-message.message-transaction .info-text {
        width: calc(100% - 90px);
    }

    .notifications-message .info-text span {
        color: #fd3b31;
    }

    .notifications-message .message-timer {
        width: 100%;
        height: 2px;
        display: flex;
        margin-top: 12px;
        border-radius: 1px;
        background: #0d1520;
    }

    .notifications-message .timer-progress {
        height: 2px;
        border-radius: 1px;
    }

    .notifications-message.message-success .timer-progress,
    .notifications-message.message-transaction .timer-progress {
        background: #00c74d;
    }

    .notifications-message.message-error .timer-progress {
        background: #fd3b31;
    }
</style>
