<template>
  <nav id="navbar">
    <div class="navbar-primary">
      <div class="primary-logo">
        <RouterLink to="/">
          <!--
          <video
            autoplay
            muted
            loop
            playsinline
            preload="metadata"
            :poster="require(`@/assets/img/logo/logo-preview.webp`)"
          >
            <source src="@/assets/img/logo/logo-animated.mp4" type="video/mp4" />
            <source src="@/assets/img/logo/logo-animated.webm" type="video/webm" />
          </video>
          -->
          <img src="@/assets/img/logo-new.webp" alt="logo" />
        </RouterLink>
      </div>
      <div class="primary-content">
        <div class="content-links">
          <RouterLink class="link-home" to="/">
            <IconHome />
          </RouterLink>
          <NavbarFilterGames />
        </div>
        <div class="content-user">
          <AuthButton v-if="authUser.user === null" />
          <NavbarUser v-else />

          <div class="user-toggle">
            <button @click="navbarSetMobile(!navbarMobile)">
              <IconBars />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="navbar-secondary" :class="{ 'secondary-open': navbarMobile === true }">
      <div class="secondary-mobile">
        <NavbarUser v-if="authUser.user" />
        <NavbarFilterGames />
      </div>
      <div class="secondary-links">
        <RouterLink v-if="authUser.user && authUser.user.rank === 'admin'" @click="navbarSetMobile(false)" to="/admin" class="secondary-admin">ADMIN</RouterLink>
        <button @click="navbarRedeemButton()" class="secondary-redeem">REDEEM CODE</button>
        <RouterLink @click="navbarSetMobile(false)" to="/leaderboard" class="secondary-leaderboard">LEADERBOARD</RouterLink>
        <button @click="navbarAffiliatesButton()">AFFILIATES</button>
        <RouterLink @click="navbarSetMobile(false)" to="/fair">PROVABLY FAIR</RouterLink>
        <button @click="navbarSupportButton()">SUPPORT</button>
      </div>
      <div class="secondary-user" v-if="authUser.user">
        <button @click="authLogoutUser" class="button-logout">
          <IconLogout />
          LOGOUT
        </button>
      </div>
    </div>
  </nav>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import IconHome from '@/components/icons/IconHome';
  import IconBars from '@/components/icons/IconBars';
  import IconLogout from '@/components/icons/IconLogout';
  import AuthButton from '@/components/AuthButton';
  import NavbarFilterGames from '@/components/navbar/NavbarFilterGames';
  import NavbarUser from '@/components/navbar/NavbarUser';

  export default {
    components: {
      IconHome,
      IconBars,
      IconLogout,
      AuthButton,
      NavbarFilterGames,
      NavbarUser
    },
    data() {
      return {
        navbarMobile: false
      }
    },
    methods: {
      ...mapActions([
        'chatToggleSidebar',
        'modalsSetShow', 
        'authLogoutUser'
      ]),
      navbarSetMobile(status) {
        this.navbarMobile = status;
      },
      navbarRedeemButton() {
        if(this.authUser.user === null) { return; }
        this.modalsSetShow('Redeem');
        this.navbarSetMobile(false);
      },
      navbarAffiliatesButton() {
        if(this.authUser.user === null) { return; }
        this.modalsSetShow('Affiliates');
        this.navbarSetMobile(false);
      },
      navbarSupportButton() {
        if(window.$crisp.is("chat:hidden") === true) { window.$crisp.push(['do', 'chat:show']); }
        window.$crisp.push(['do', 'chat:toggle']);
        this.navbarSetMobile(false);
      }
    },
    computed: {
      ...mapGetters([
        'authUser'
      ])
    }
  }
</script>

<style scoped>
  nav#navbar {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
  }

  nav#navbar .navbar-primary {
    width: 100%;
    height: 67px;
    display: flex;
    background: #1c2029;
  }

  nav#navbar .primary-logo {
    width: 300px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  nav#navbar .primary-logo a {
    display: flex;
    align-items: center;
  }

  nav#navbar .primary-logo a video {
    height: 50px;
  }

  nav#navbar .primary-logo a img {
    height: 42px;
  }

  nav#navbar .primary-content {
    width: calc(100% - 300px);
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 28px;
  }

  nav#navbar .content-links {
    height: 100%;
    display: flex;
    align-items: center;
  }

  nav#navbar .content-links a.link-home {
    width: 39px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    border-radius: 5px;
    background: #212732;
    box-shadow: 0px 2px 0px 0px #191e27;
  }

  nav#navbar .content-links a.link-home svg {
    fill: #767c8b;
    transition: fill 0.3s ease;
  }

  nav#navbar .content-links a.link-home:hover svg {
    fill: #ffffff;
  }

  nav#navbar .content-user {
    height: 100%;
    display: flex;
    align-items: center;
  }

  nav#navbar .user-toggle {
    display: none;
    margin-left: 28px;
  }

  nav#navbar .user-toggle button {
    width: 41px;
    height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: #212732;
    border-bottom: 2px solid #191e27;
  }

  nav#navbar .user-toggle button svg {
    fill: #767c8b;
    transition: all 0.3s ease;
  }

  nav#navbar .user-toggle button:hover svg {
    fill: #ffffff;
  }

  nav#navbar .navbar-secondary {
    width: calc(100% - 300px);
    height: 45px;
    display: flex;
    justify-content: space-between;
    margin-left: 300px;
    padding: 0 28px;
    background: #191d26;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease;
  }

  nav#navbar .navbar-secondary::-webkit-scrollbar-thumb {
    width: 0;
  }

  nav#navbar .navbar-secondary::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  nav#navbar .navbar-secondary::-webkit-scrollbar-track {
    background: transparent;
  }

  .page-full nav#navbar .navbar-secondary {
    width: 100%;
    margin-left: 0;
  }

  nav#navbar .secondary-mobile {
    width: 100%;
    display: none;
  }

  nav#navbar .secondary-mobile .link-home {

  }

  nav#navbar .secondary-mobile .link-home {
    width: 100%;
    height: 39px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 700;
    color: #767c8b;
    background: #212732;
    box-shadow: 0px 2px 0px 0px #191e27;
    transition: color 0.3s ease;
  }

  nav#navbar .secondary-mobile .link-home:hover {
    color: #ffffff;
  }

  nav#navbar .secondary-mobile .link-home svg {
    margin-right: 12px;
    fill: #767c8b;
  }

  nav#navbar .secondary-links {
    display: flex;
    align-items: center;
  }

  nav#navbar .secondary-links button {
    margin-right: 28px;
    padding: 0;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    color: #5f6779;
    background: transparent;
    transition: all 0.3s ease;
  }

  nav#navbar .secondary-links button:hover {
    color: #ffffff;
  }

  nav#navbar .secondary-links button.secondary-redeem {
    color: #00c74d;
  }

  nav#navbar .secondary-links button.secondary-redeem:hover {
    color: #00c74d;
  }

  nav#navbar .secondary-links a {
    margin-right: 28px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    color: #5f6779;
    transition: all 0.3s ease;
  }

  nav#navbar .secondary-links button:last-of-type {
    margin-right: 0;
  }

  nav#navbar .secondary-links a.secondary-admin {
    color: #fd3b31;
  }

  nav#navbar .secondary-links a.secondary-leaderboard {
    color: #ffc93d;
  }

  nav#navbar .secondary-links a:hover {
    color: #ffffff;
  }

  nav#navbar .secondary-links a.secondary-admin:hover {
    color: #fe524a;
  }

  nav#navbar .secondary-user {
    display: flex;
    align-items: center;
  }

  nav#navbar .secondary-user button.button-logout {
    display: flex;
    padding: 0;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
    color: #5f6779;
    background: transparent;
    transition: all 0.3s ease;
  }

  nav#navbar .secondary-user button.button-logout:hover {
    color: #ffffff;
  }

  nav#navbar .secondary-user button.button-logout svg {
    width: 14px;
    margin-right: 6px;
    fill: #5f6779;
    transition: all 0.3s ease;
  }

  nav#navbar .secondary-user button.button-logout:hover svg {
    fill: #ffffff;
  }

  @media only screen and (max-width: 1300px) {

    nav#navbar .content-links a.link-home {
      display: none;
    }

    nav#navbar .user-toggle {
      display: block;
    }

    nav#navbar .navbar-secondary {
      width: 260px!important;
      height: calc(100vh - 67px);
      position: absolute;
      flex-direction: column;
      justify-content: flex-start;
      top: 67px;
      right: -260px;
      margin-left: 0;
      padding: 10px;
      background: #151920;
      overflow-x: hidden;
      overflow-y: scroll;
      transition: all 0.3s ease;
      z-index: 10;
    }

    nav#navbar .navbar-secondary.secondary-open {
      right: 0;
    }

    nav#navbar .secondary-mobile {
      display: block;
    }

    nav#navbar .secondary-links {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 20px;
    }

    nav#navbar .secondary-links a,
    nav#navbar .secondary-links button {
      margin-top: 12px;
      margin-right: 0;
    }

    nav#navbar .secondary-links a:first-child,
    nav#navbar .secondary-links button:first-child {
      margin-top: 0;
    }

    nav#navbar .secondary-user button.button-logout {
      margin-top: 12px;
    }

    nav#navbar .secondary-user button.button-logout svg {
      display: none;
    }


  }

  @media only screen and (max-width: 900px) {

    nav#navbar .primary-logo {
      
    }

    nav#navbar .primary-logo a span {
      display: none;
    }

    nav#navbar .primary-content {
      width: calc(100% - 55px);
    }

  }
</style>
