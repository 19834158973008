<template>
  <button @click="steamItemButton" :class="['steam-item-element', { 
    'element-selected': steamSelectedCount > 0 
  }]" :disabled="item.accepted === false">
    <div class="element-amount">
      <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.5938 3.80243L8.2788 0.132582C8.1017 0.0430454 7.89929 0.0430454 7.72126 0.132582L0.406642 3.80243C0.167877 3.92203 0.0102018 4.18528 0.00134712 4.47952C-0.00719685 4.77319 0.132562 5.04756 0.363456 5.18574L7.67797 9.57422C7.77884 9.6351 7.8894 9.66486 8.00026 9.66486C8.1117 9.66486 8.22209 9.6351 8.32312 9.57422L15.638 5.18574C15.8687 5.04756 16.0086 4.77319 16.0002 4.47952C15.9902 4.18528 15.8329 3.92203 15.5938 3.80243Z" />
        <path d="M14.9922 7.08579L7.99933 11.2824L1.00762 7.08579C0.672903 6.88487 0.258443 7.02742 0.0810392 7.40327C-0.0976074 7.77896 0.0291022 8.24511 0.362886 8.4455L7.6774 12.8352C7.77827 12.8957 7.88883 12.9258 7.99969 12.9258C8.11112 12.9258 8.22152 12.8961 8.32255 12.8352L15.6374 8.4455C15.9704 8.24511 16.097 7.77896 15.9194 7.40327C15.7406 7.02736 15.3259 6.88481 14.9922 7.08579Z" />
        <path d="M14.9922 10.0961L7.99933 14.2914L1.00762 10.0961C0.672903 9.89503 0.258443 10.0378 0.0810392 10.4131C-0.0976074 10.7888 0.0291022 11.2554 0.362886 11.4555L7.6774 15.8438C7.77827 15.9047 7.88883 15.9348 7.99969 15.9348C8.11112 15.9348 8.22152 15.9051 8.32255 15.8438L15.6374 11.4555C15.9704 11.2554 16.097 10.7888 15.9194 10.4131C15.7406 10.0378 15.3264 9.89497 14.9922 10.0961Z" />
      </svg>
      {{item.count}}
    </div>
    <div class="element-image">
        <img :src="item.image" alt="item-image" />
    </div>
    <div class="element-info">
      <div class="info-select">
        <button @click="steamRemoveButton()" class="button-remove">
          <svg width="14" height="2" viewBox="0 0 14 2" xmlns="http://www.w3.org/2000/svg">
            <rect width="14" height="2" />
          </svg>
        </button>
        <div class="select-value">{{steamSelectedCount}}</div>
        <button @click="steamAddButton()" class="button-add">
          <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6 8V14H8V8H14V6H8V0H6V6H0V8H6Z" />
          </svg>
        </button>
      </div>
      <div class="info-name">{{item.name}}</div>
      <div class="info-price">
        <img src="@/assets/img/icons/coin.png" alt="coins-image" />
        <div class="price-amount">
          <span>{{generalFormatAmount(item.amount).split('.')[0]}}</span>.{{
          generalFormatAmount(item.amount).split('.')[1]}}
        </div>
      </div>
    </div>
  </button>
</template>

<script>
  import mixins from '@/mixins';
  import { mapGetters, mapActions } from 'vuex';

  export default {
    mixins: [
      mixins
    ],
    props: {
      item: Object
    },
    methods: {
      ...mapActions([
        'upgraderAddItemsDataSelected',
        'upgraderRemoveItemsDataSelected',
        'steamAddDepositSelected', 
        'steamRemoveDepositSelected', 
        'steamAddWithdrawSelected', 
        'steamRemoveWithdrawSelected'
      ]),
      steamItemButton(e) {
        if(
          !['button-remove', 'button-add'].includes(e.target.className) && 
          !['button-remove', 'button-add'].includes(e.target.parentElement.className) &&
          !['button-remove', 'button-add'].includes(e.target.parentElement.parentElement.className)
        ) {
          if(this.$route.name === 'Upgrader') {
            if(this.upgraderItemsData.selected.some((element) => element.name === this.item.name)) {
              const count = this.upgraderItemsData.selected.filter((element) => element.name === this.item.name).length;

              for(let i = 0; i < count; i++) {
                this.upgraderRemoveItemsDataSelected(this.item);
              }
            } else if(this.upgraderItemsData.selected.length < 4) {
              this.upgraderAddItemsDataSelected(this.item);
            }
          } else if(this.$route.name === 'DepositRust') {
            if(this.steamDepositData.selected.some((element) => element.name === this.item.name)) {
              const count = this.steamDepositData.selected.filter((element) => element.name === this.item.name).length;

              for(let i = 0; i < count; i++) {
                this.steamRemoveDepositSelected(this.item);
              }
            } else {
              this.steamAddDepositSelected(this.item);
            }
          } else if(this.$route.name === 'WithdrawRust') {
            if(this.steamWithdrawData.selected.some((element) => element.name === this.item.name)) {
              const count = this.steamWithdrawData.selected.filter((element) => element.name === this.item.name).length;

              for(let i = 0; i < count; i++) {
                this.steamRemoveWithdrawSelected(this.item);
              }
            } else if(this.steamWithdrawData.selected.length < 1) {
              this.steamAddWithdrawSelected(this.item);
            }
          }
        }
      },
      steamRemoveButton() {
        if(this.$route.name === 'Upgrader') {
          this.upgraderRemoveItemsDataSelected(this.item);
        } else if(this.$route.name === 'DepositRust') {
          this.steamRemoveDepositSelected(this.item);
        } else {
          this.steamRemoveWithdrawSelected(this.item);
        }
      },
      steamAddButton() {
        if(this.steamSelectedCount < this.item.count) {
          if(this.$route.name === 'Upgrader' && this.upgraderItemsData.selected.length < 4) {
            this.upgraderAddItemsDataSelected(this.item);
          } else if(this.$route.name === 'DepositRust') {
            this.steamAddDepositSelected(this.item);
          } else if(this.$route.name === 'WithdrawRust' && this.steamWithdrawData.selected.length < 1) {
            this.steamAddWithdrawSelected(this.item);
          }
        }
      }
    },
    computed: {
      ...mapGetters([
        'upgraderItemsData', 
        'steamDepositData', 
        'steamWithdrawData'
      ]),
      steamSelectedCount() {
        if(this.$route.name === 'Upgrader') {
          return this.upgraderItemsData.selected.filter((element) => element.name === this.item.name).length;
        } else if(this.$route.name === 'DepositRust') {
          return this.steamDepositData.selected.filter((element) => element.name === this.item.name).length;
        } else {
          return this.steamWithdrawData.selected.filter((element) => element.name === this.item.name).length;
        }
      }
    }
  }
</script>

<style scoped>
  button.steam-item-element {
    width: calc(16.66% - 13.33px);
    height: 222px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 33px 20px 16px 20px;
    border-radius: 18px;
    background: linear-gradient(180deg, #1a1e29, #1c202c);
    transition: all 0.3s ease;
  }

  button.steam-item-element.element-selected,
  button.steam-item-element:not([disabled]):hover {
    background: linear-gradient(180deg, #1a1d29, #182b2d);
  }

  button.steam-item-element::before {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    border-radius: 18px;
    transition: all 0.3s ease;
  }

  button.steam-item-element.element-selected::before,
  button.steam-item-element:not([disabled]):hover::before {
    border: 1px solid #00c74d;
  }

  button.steam-item-element .element-amount {
    position: absolute;
    display: flex;
    align-items: center;
    top: 10px;
    left: 14px;
    font-size: 14px;
    font-weight: 700;
    color: #596076;
  }

  button.steam-item-element .element-amount svg {
    margin-right: 6px;
    fill: #596076;
  }

  button.steam-item-element .element-image {
    width: 108px;
    height: 108px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 33px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 42px;
    background: #1e222e;
    transition: all 0.3s ease;
    z-index: 0;
  }

  button.steam-item-element.element-selected .element-image,
  button.steam-item-element:not([disabled]):hover .element-image {
    width: 72px;
    height: 72px;
    opacity: 0.3;
  }

  button.steam-item-element .element-image img {
    width: 84px;
    height: 84px;
    transition: all 0.3s ease;
  }

  button.steam-item-element.element-selected .element-image img,
  button.steam-item-element:not([disabled]):hover .element-image img {
    width: 56px;
    height: 56px;
  }

  button.steam-item-element .element-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
  }

  button.steam-item-element .info-select {
    width: 100%;
    height: 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    background: #1b1f2a;
    border: 1px solid #262c3a;
    opacity: 0;
    transition: all 0.3s ease;
  }

  button.steam-item-element.element-selected .info-select,
  button.steam-item-element:not([disabled]):hover .info-select {
    opacity: 1;
  }

  button.steam-item-element .info-select button.button-remove {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0 0 0 12px;
    border-radius: 12px 0 0 12px;
    background: transparent;
  }

  button.steam-item-element .info-select button.button-add {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0 12px 0 0;
    border-radius: 0 12px 12px 0;
    background: transparent;
  }

  button.steam-item-element .info-select button.button-remove svg,
  button.steam-item-element .info-select button.button-add svg {
    fill: #39414e;
    transition: all 0.3s ease;
  }

  button.steam-item-element .info-select button.button-remove:hover svg,
  button.steam-item-element .info-select button.button-add:hover svg {
    fill: #ffffff;
  }

  button.steam-item-element .select-value {
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
  }

  button.steam-item-element .info-name {
    width: 100%;
    margin-top: 8px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 700;
    color: #596076;
  }

  button.steam-item-element .info-price {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }

  button.steam-item-element .info-price img {
    width: 21px;
    margin-right: 10px;
  }

  button.steam-item-element .price-amount {
    font-size: 12px;
    font-weight: 700;
    color: #bbbbbb;
  }

  button.steam-item-element .price-amount span {
    font-size: 15px;
    font-weight: 700;
    color: #ffffff;
  }

  @media only screen and (max-width: 1350px) {

    button.steam-item-element {
      width: calc(20% - 12.8px);
    }

  }

  @media only screen and (max-width: 800px) {

    button.steam-item-element {
      width: calc(25% - 12px);
    }

  }

  @media only screen and (max-width: 650px) {

    button.steam-item-element {
      width: calc(33.33% - 10.66px);
    }

  }

  @media only screen and (max-width: 500px) {

    button.steam-item-element {
      width: calc(50% - 8px);
    }

  }
</style>
