import { createStore } from 'vuex';

import modals from './modules/modals';
import notifications from './modules/notifications';
import sound from './modules/sound';
import socket from './modules/socket';
import auth from './modules/auth';
import general from './modules/general';
import user from './modules/user';
import chat from './modules/chat';
import blackjack from './modules/blackjack';
import mines from './modules/mines';
import towers from './modules/towers';
import upgrader from './modules/upgrader';
import roulette from './modules/roulette';
import cases from './modules/cases';
import battles from './modules/battles';
import affiliates from './modules/affiliates';
import promo from './modules/promo';
import steam from './modules/steam';
import crypto from './modules/crypto';
import gift from './modules/gift';
import credit from './modules/credit';
import fair from './modules/fair';
import rakeback from './modules/rakeback';
import leaderboard from './modules/leaderboard';
import admin from './modules/admin';

export default createStore({
  modules: {
    modals,
    notifications,
    sound,
    socket,
    auth,
    general,
    user,
    chat,
    blackjack,
    mines,
    towers,
    upgrader,
    roulette,
    cases,
    battles,
    affiliates,
    promo,
    steam,
    crypto,
    gift,
    credit,
    fair,
    rakeback,
    leaderboard,
    admin
  }
});
