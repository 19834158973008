import { createRouter, createWebHistory } from 'vue-router'

// Load store
import store from '@/store';

// Load utils
import auth from '@/utils/auth';

// Load views
import Home from '@/views/Home';
import Blackjack from '@/views/Blackjack';
import Mines from '@/views/Mines';
import Towers from '@/views/Towers';
import Upgrader from '@/views/Upgrader';
import Roulette from '@/views/Roulette';
import Cases from '@/views/cases/Cases';
import CasesOverview from '@/views/cases/CasesOverview';
import CasesBox from '@/views/cases/CasesBox';
import Battles from '@/views/battles/Battles';
import BattlesOverview from '@/views/battles/BattlesOverview';
import BattlesCreate from '@/views/battles/BattlesCreate';
import BattlesGame from '@/views/battles/BattlesGame';
import Leaderboard from '@/views/Leaderboard';
import Terms from '@/views/Terms';
import Faq from '@/views/Faq';
import Fair from '@/views/fair/Fair';
import FairOverview from '@/views/fair/FairOverview';
import FairBlackjack from '@/views/fair/FairBlackjack';
import FairMines from '@/views/fair/FairMines';
import FairTowers from '@/views/fair/FairTowers';
import FairUpgrader from '@/views/fair/FairUpgrader';
import FairRoulette from '@/views/fair/FairRoulette';
import FairCases from '@/views/fair/FairCases';
import FairBattles from '@/views/fair/FairBattles';

const Deposit = () => import(/* webpackChunkName: "group-auth" */ '@/views/deposit/Deposit');
const DepositRust = () => import(/* webpackChunkName: "group-auth" */ '@/views/deposit/DepositRust');
const DepositCrypto = () => import(/* webpackChunkName: "group-auth" */ '@/views/deposit/DepositCrypto');
const DepositGift = () => import(/* webpackChunkName: "group-auth" */ '@/views/deposit/DepositGift');
const DepositCredit = () => import(/* webpackChunkName: "group-auth" */ '@/views/deposit/DepositCredit');
const Withdraw = () => import(/* webpackChunkName: "group-auth" */ '@/views/withdraw/Withdraw');
const WithdrawRust = () => import(/* webpackChunkName: "group-auth" */ '@/views/withdraw/WithdrawRust');
const Profile = () => import(/* webpackChunkName: "group-auth" */ '@/views/profile/Profile');
const ProfileOverview = () => import(/* webpackChunkName: "group-auth" */ '@/views/profile/ProfileOverview');
const ProfileSettings = () => import(/* webpackChunkName: "group-auth" */ '@/views/profile/ProfileSettings');
const ProfileBets = () => import(/* webpackChunkName: "group-auth" */ '@/views/profile/ProfileBets');
const ProfileTransactions = () => import(/* webpackChunkName: "group-auth" */ '@/views/profile/ProfileTransactions');

const Admin = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/Admin');
const AdminDashboard = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminDashboard');
const AdminUsers = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminUsers');
const AdminUser = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminUser');
const AdminTransactions = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminTransactions');
const AdminBoxes = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminBoxes');
const AdminBoxesCreate = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminBoxesCreate');
const AdminPromo = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminPromo');
const AdminPromoCreate = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminPromoCreate');
const AdminCodes = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminCodes');
const AdminCodesCreate = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminCodesCreate');
const AdminLeaderboards = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminLeaderboards');
const AdminLeaderboardsCreate = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminLeaderboardsCreate');
const AdminReports = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminReports');

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/blackjack',
    name: 'Blackjack',
    component: Blackjack
  },
  {
    path: '/mines',
    name: 'Mines',
    component: Mines
  },
  {
    path: '/towers',
    name: 'Towers',
    component: Towers
  },
  {
    path: '/upgrader',
    name: 'Upgrader',
    component: Upgrader
  },
  {
    path: '/roulette',
    name: 'Roulette',
    component: Roulette
  },
  {
    path: '/cases',
    component: Cases,
    children: [
      {
        path: '',
        name: 'CasesOverview',
        component: CasesOverview
      },
      {
        path: ':boxId',
        name: 'CasesBox',
        component: CasesBox
      }
    ]
  },
  {
    path: '/battles',
    component: Battles,
    children: [
      {
        path: '',
        name: 'BattlesOverview',
        component: BattlesOverview
      },
      {
        path: 'create',
        name: 'BattlesCreate',
        component: BattlesCreate
      },
      {
        path: ':gameId',
        name: 'BattlesGame',
        component: BattlesGame
      }
    ]
  },
  {
    path: '/deposit',
    component: Deposit,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'DepositRust',
        component: DepositRust
      },
      {
        path: 'crypto',
        name: 'DepositCrypto',
        component: DepositCrypto
      },
      {
        path: 'gift',
        name: 'DepositGift',
        component: DepositGift
      },
      {
        path: 'credit',
        name: 'DepositCredit',
        component: DepositCredit
      }
    ]
  },
  {
    path: '/withdraw',
    component: Withdraw,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'WithdrawRust',
        component: WithdrawRust
      }
    ]
  },
  {
    path: '/profile',
    component: Profile,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'ProfileOverview',
        component: ProfileOverview
      },
      {
        path: 'settings',
        name: 'ProfileSettings',
        component: ProfileSettings
      },
      {
        path: 'bets',
        name: 'ProfileBets',
        component: ProfileBets
      },
      {
        path: 'transactions',
        name: 'ProfileTransactions',
        component: ProfileTransactions
      }
    ]
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq
  },
  {
    path: '/fair',
    component: Fair,
    children: [
      {
        path: '',
        name: 'FairOverview',
        component: FairOverview
      },
      {
        path: 'blackjack',
        name: 'FairBlackjack',
        component: FairBlackjack
      },
      {
        path: 'mines',
        name: 'FairMines',
        component: FairMines
      },
      {
        path: 'towers',
        name: 'FairTowers',
        component: FairTowers
      },
      {
        path: 'upgrader',
        name: 'FairUpgrader',
        component: FairUpgrader
      },
      {
        path: 'roulette',
        name: 'FairRoulette',
        component: FairRoulette
      },
      {
        path: 'cases',
        name: 'FairCases',
        component: FairCases
      },
      {
        path: 'battles',
        name: 'FairBattles',
        component: FairBattles
      }
    ]
  },
  {
    path: '/leaderboard',
    name: 'Leaderboard',
    component: Leaderboard
  },
  {
    path: '/admin',
    component: Admin,
    meta: {
      auth: true,
      admin: true
    },
    children: [
      {
        path: '',
        name: 'AdminDashboard',
        component: AdminDashboard
      },
      {
        path: 'users',
        name: 'AdminUsers',
        component: AdminUsers
      },
      {
        path: 'users/:userId',
        name: 'AdminUser',
        component: AdminUser
      },
      {
        path: 'transactions',
        name: 'AdminTransactions',
        component: AdminTransactions
      },
      {
        path: 'boxes',
        name: 'AdminBoxes',
        component: AdminBoxes
      },
      {
        path: 'boxes/create',
        name: 'AdminBoxesCreate',
        component: AdminBoxesCreate
      },
      {
        path: 'promo',
        name: 'AdminPromo',
        component: AdminPromo
      },
      {
        path: 'promo/create',
        name: 'AdminPromoCreate',
        component: AdminPromoCreate
      },
      {
        path: 'codes',
        name: 'AdminCodes',
        component: AdminCodes
      },
      {
        path: 'codes/create',
        name: 'AdminCodesCreate',
        component: AdminCodesCreate
      },
      {
        path: 'leaderboards',
        name: 'AdminLeaderboards',
        component: AdminLeaderboards
      },
      {
        path: 'leaderboards/create',
        name: 'AdminLeaderboardsCreate',
        component: AdminLeaderboardsCreate
      },
      {
        path: 'reports',
        name: 'AdminReports',
        component: AdminReports
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async function(to, from, next) {
  // Start loading progress bar
  if(from.name) {
    NProgress.start();
  }

  // Handle get user and referral code
  await auth.authHandleAuth(store, to);
  auth.authHandleCode(store, to);

  // Connect socket client if not connected
  if(store.getters.socketClient.connected !== true) {
    await store.dispatch('socketConnectClient');
  }

  // Check if user has access to route
  if(auth.authRequiresUser(store, to) || auth.authRequiresAdmin(store, to)) {
    return next({ name: 'Home' });
  }

  next();
});

router.afterEach((to, from) => {
  // Complete loading progress bar
  NProgress.done();
});

export default router;
