<template>
    <div class="towers-row" v-bind:class="{
        'row-medium': towersGetRisk === 'medium',
        'row-active': towersData.game !== null && towersData.game.state === 'created' && towersData.game.revealed.length === row,
        'row-revealed': towersData.game !== null && towersData.game.revealed[row] !== undefined
    }">

        <button v-for="tile in towersGetTilesCount" v-bind:key="tile" v-on:click="towersRevealButton(tile - 1)" class="row-button" v-bind:class="{
            'button-won': towersData.game !== null && towersData.game.revealed[row] !== undefined && towersData.game.revealed[row].tile === (tile - 1) && towersData.game.revealed[row].row[tile - 1] === 'coin',
            'button-lose': towersData.game !== null && towersData.game.revealed[row] !== undefined && towersData.game.revealed[row].tile === (tile - 1) && towersData.game.revealed[row].row[tile - 1] === 'lose'
        }" v-bind:disabled="towersData.game === null || towersData.game.state !== 'created' || towersData.game.revealed.length !== row">
            <div v-if="towersData.game === null || (towersData.game !== null && (towersData.game.state === 'created' || (towersData.game.state === 'completed' && towersData.game.deck[row][tile - 1] === 'coin')))" class="button-won">
                <img src="@/assets/img/icons/coin.png" alt="coins-image" />
                <div class="won-amount">
                    <span>{{generalFormatAmount(towersGetRowCashout).split('.')[0]}}</span>.{{generalFormatAmount(towersGetRowCashout).split('.')[1]}}
                </div>
            </div>
            <div v-else class="button-lose">
                <img src="@/assets/img/icons/bomb.png" alt="bomb" />
            </div>
        </button>

    </div>
</template>

<script>
    import mixins from '@/mixins';
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'TowersRow',
        mixins: [
            mixins
        ],
        props: [
            'row', 
            'amount', 
            'risk'
        ],
        methods: {
            ...mapActions([
                'notificationShow', 
                'towersSendRevealSocket'
            ]),
            towersRevealButton(tile) {
                if(this.socketSendLoading !== null) { return; }
                if(this.towersData.game === null || this.towersData.game.state !== 'created' || this.towersData.game.revealed.length !== this.row) { return; }

                if(this.authUser.user === null) {
                    this.notificationShow({ type: 'error', message: 'Please sign in to perform this action.' });
                    return;
                }

                this.towersSendRevealSocket({ tile: tile });
            }
        },
        computed: {
            ...mapGetters([
                'authUser', 
                'towersData', 
                'socketSendLoading'
            ]),
            towersGetRisk() {
                let risk = this.risk;

                if(this.towersData.game !== null && this.towersData.game.state === 'created') { risk = this.towersData.game.risk; }

                return risk;
            },
            towersGetTilesCount() {
                let count = 3;

                if(this.towersGetRisk === 'medium') { count = 2; }

                return count;
            },
            towersGetRowCashout() {
                let amount = this.amount * 1000;

                if(this.towersData.game !== null && this.towersData.game.state === 'created') { amount = this.towersData.game.amount; }

                return Math.floor(amount * Math.pow(this.towersGetMultiplierRow(this.towersGetRisk), this.row + 1));
            }
        }
    }
</script>

<style scoped>
    .towers-row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .towers-row button.row-button {
        width: calc(33.33% - 12px);
        height: 51px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        background-color: #212732;
        background-image: url('~@/assets/img/towers/tile.webp');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border: 1px solid #1d222a;
    }

    .towers-row.row-medium button.row-button {
        width: calc(50% - 8px);
    }

    .towers-row button.row-button:disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }

    .towers-row.row-active button.row-button {
        opacity: 1;
    }

    .towers-row.row-revealed button.row-button {
        opacity: 0.12;
    }

    .towers-row button.row-button.button-won {
        background-color: rgba(0, 199, 77, 0.12);
        border: 1px solid #00C74d;
        opacity: 1;
    }

    .towers-row button.row-button.button-lose {
        background-color: rgba(253, 59, 49, 0.12);
        border: 1px solid #fd3b31;
        opacity: 1;
    }

    .towers-row button.row-button .button-won,
    .towers-row button.row-button .button-lose {
        display: flex;
        align-items: center;
    }

    .towers-row .button-won img {
        width: 21px;
        margin-right: 12px;
    }

    .towers-row .won-amount {
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .towers-row .won-amount span {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .towers-row button.row-button .button-lose img {
        width: 28px;
    }

    @media only screen and (max-width: 1100px) {

        .towers-row button.row-button {
            height: 41px;
        }

    }

    @media only screen and (max-width: 550px) {

        .towers-row .button-won img {
            width: 16px;
            height: 16px;
            margin-right: 10px;
        }

        .towers-row .won-amount {
            font-size: 10px;
            font-weight: 700;
            color: #bbbbbb;
        }

        .towers-row .won-amount span {
            font-size: 13px;
            font-weight: 700;
            color: #ffffff;
        }

    }
</style>
