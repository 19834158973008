<template>
    <svg width="21" height="21" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#towers-clip)">
            <path d="M16.6765 0H13.5883V1.23529H12.353V0H8.64714V1.23529H7.41184V0H4.32361V3.70588H16.6765V0Z" />
            <path d="M10.5001 16.0588C9.81892 16.0588 9.26477 16.613 9.26477 17.2941V21H11.7354V17.2941C11.7354 16.613 11.1812 16.0588 10.5001 16.0588Z" />
            <path d="M17.227 19.7647L16.5143 13.5882H12.9706V12.3529H16.3718L15.5166 4.94116H5.4835L4.62826 12.3529H8.02944V13.5882H4.48575L3.77311 19.7647H0.617676V21H8.02944C8.02944 20.5467 8.02944 17.6921 8.02944 17.2941C8.02944 15.9318 9.13775 14.8235 10.5 14.8235C11.8623 14.8235 12.9706 15.9318 12.9706 17.2941V21H20.3824V19.7647H17.227ZM8.02944 11.1176V8.64705C8.02944 7.28477 9.13775 6.17646 10.5 6.17646C11.8623 6.17646 12.9706 7.28477 12.9706 8.64705V11.1176H8.02944Z" />
            <path d="M10.5001 7.41187C9.81892 7.41187 9.26477 7.96602 9.26477 8.64716V9.88246H11.7354V8.64716C11.7354 7.96602 11.1812 7.41187 10.5001 7.41187Z" />
        </g>
        <defs>
            <clipPath id="towers-clip">
                <rect width="21" height="21" />
            </clipPath>
        </defs>
    </svg>
</template>
