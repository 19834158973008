<template>
  <aside id="chat">
    <div class="chat-toggle">
      <button @click="chatSetSidebar(true)">
        <IconChat />
      </button>
    </div>
    <div class="chat-header">
      <div class="header-online">
        <div class="online-dot">
          <div class="dot-wave"></div>
        </div>
        {{generalOnline}} Online
      </div>
      <div class="header-socials">
        <a href="https://x.com/rustrollcom" target="_blank" class="socials-twitter">
          <IconTwitter />
        </a>
        <a href="https://discord.gg/wszdJwxXMR" target="_blank" class="socials-discord">
          <IconDiscord />
        </a>
      </div>
    </div>
    <div class="chat-room">
      <ChatRoomDropdown />
    </div>
    <div class="chat-content">
      <div @scroll="chatHandleScroll" class="content-messages" ref="chatMessages">

        <ChatMessageElement 
          v-for="message in chatGetMessages" 
          :key="message._id" 
          :message="message" 
        />

      </div>
      <div class="content-rain">
        <ChatRain />
      </div>
      <div class="content-info">
        <Transition name="slide-fade" mode="out-in">
          <button v-if="chatScroll === false" @click="chatScrollToBottom" class="button-scroll">
            SCROLL TO BOTTOM
          </button>
        </Transition>
      </div>
    </div>
    <div class="chat-footer">
      <div class="footer-input">
        <input v-model="chatMessage" @keyup.enter="chatMessageButton()" type="text" placeholder="Type message here..." />
        <button @click="chatMessageButton()">
          <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.6874 9.0002C19.6874 9.06116 19.6696 9.12079 19.6361 9.17176C19.6027 9.22272 19.5551 9.26281 19.4991 9.28707L0.749144 17.4121C0.688818 17.4382 0.621773 17.4447 0.557552 17.4306C0.493331 17.4164 0.435208 17.3824 0.391457 17.3333C0.347706 17.2842 0.320557 17.2226 0.313874 17.1572C0.307191 17.0917 0.321316 17.0259 0.354237 16.9689L4.03861 10.5814C4.07409 10.52 4.12941 10.4724 4.19549 10.4465L7.89239 9.0002L4.19552 7.55398C4.12944 7.5281 4.07412 7.48053 4.03864 7.41907L0.354237 1.03145C0.321316 0.974522 0.307191 0.908662 0.313874 0.843243C0.320557 0.777824 0.347706 0.716181 0.391457 0.667087C0.435208 0.617994 0.493331 0.583954 0.557552 0.569812C0.621773 0.55567 0.688818 0.562148 0.749144 0.588323L19.4991 8.71332C19.5551 8.73759 19.6027 8.77767 19.6361 8.82864C19.6696 8.8796 19.6874 8.93923 19.6874 9.0002Z" />
          </svg>
        </button>
      </div>
      <div class="footer-actions">
        <button @click="modalsSetShow('ChatRules')">
          <svg width="11" height="17" viewBox="0 0 11 17" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11 0H0V16.0827L5.5 12L11 16.0827V0Z" />
          </svg>
          CHAT RULES
        </button>
        <button @click="chatSetSidebar(false)">
          <IconClose />
          HIDE CHAT
        </button>
      </div>
    </div>
  </aside>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import IconChat from '@/components/icons/IconChat';
  import IconTwitter from '@/components/icons/IconTwitter';
  import IconDiscord from '@/components/icons/IconDiscord';
  import IconClose from '@/components/icons/IconClose';
  import ChatRoomDropdown from '@/components/chat/ChatRoomDropdown';
  import ChatMessageElement from '@/components/chat/ChatMessageElement';
  import ChatRain from '@/components/chat/ChatRain';

  export default {
    components: {
      IconChat,
      IconTwitter,
      IconDiscord,
      IconClose,
      ChatRoomDropdown,
      ChatMessageElement,
      ChatRain
    },
    data() {
      return {
        chatScroll: true,
        chatMessage: null
      }
    },
    methods: {
      ...mapActions([
        'chatSetSidebar', 
        'notificationShow', 
        'chatSendMessageSocket', 
        'modalsSetShow'
      ]),
      chatMessageButton() {
        if(!this.authUser.user) {
            this.notificationShow({ 
              type: 'error', 
              message: 'Please sign in to perform this action.' 
            });
            return;
        }

        if(!this.generalSettings.chat.enabled && this.authUser.user.rank !== 'admin') {
            this.notificationShow({ 
              type: 'error', 
              message: 'You can not send a chat message because the action is not available at the moment.' 
            });
            return;
        }

        if(
          !this.chatMessage || 
          this.chatMessage.trim() === ''
        ) {
            this.notificationShow({ 
              type: 'error', 
              message: 'Please enter a message.' 
            });
            return;
        }

        this.chatSendMessageSocket({ 
          message: this.chatMessage, 
          room: this.chatRoom 
        });

        this.chatMessage = '';
      },
      chatHandleScroll() {
        let container = this.$refs.chatMessages;

        if(container.scrollHeight - (container.scrollTop + container.clientHeight) >= 80) {
          this.chatScroll = false;
        } else {
          this.chatScroll = true;
        }
      },
      chatScrollToBottom() {
        this.$nextTick(function() {
          let container = this.$refs.chatMessages;
          container.scrollTop = container.scrollHeight;
        });
      }
    },
    computed: {
      ...mapGetters([
        'socketSendLoading', 
        'authUser', 
        'generalSettings', 
        'generalOnline', 
        'chatSidebar',
        'chatRoom', 
        'chatMessages'
      ]),
      chatGetMessages() {
        return this.chatMessages[this.chatRoom];
      }
    },
    watch: {
      'chatMessages': {
        handler(state, oldState)  {
          if(this.chatScroll || oldState[this.chatRoom].length === 0) {
            this.chatScrollToBottom();
          }
        },
        deep: true
      },
      'chatRoom': {
        handler() {
          setTimeout(() => { this.chatScrollToBottom(); }, 100);
        }
      },
      'chatSidebar': {
        handler() {
          setTimeout(() => { this.chatScrollToBottom(); }, 100);
        }
      }
    },
    created() {
      this.chatScrollToBottom();

      if(window.innerWidth <= 1700 && this.chatSidebar === true) {
        this.chatSetSidebar(false);
      }
    }
  }
</script>

<style scoped>
  aside#chat {
    width: 300px;
    height: calc(100% - 67px);
    position: fixed;
    top: 67px;
    left: 0px;
    background: #151920;
    transition: all 0.3s ease;
    z-index: 20;
  }

  .app-page.page-full aside#chat {
    transform: translateX(-300px);
  }

  aside#chat .chat-toggle {
    position: absolute;
    display: none;
    right: -56px;
    bottom: 43px;
  }

  .app-page.page-full aside#chat .chat-toggle {
    display: block;
  }

  aside#chat .chat-toggle button {
    width: 56px;
    height: 56px;
    border-radius: 0 5px 5px 0;
    background: #212732;
    border-bottom: 2px solid #191e27;
  }

  aside#chat .chat-toggle button svg {
    width: 23px;
    margin-top: 6px;
    fill: #767c8b;
    transition: all 0.3s ease;
  }

  aside#chat .chat-toggle button:hover svg {
    fill: #ffffff;
  }

  aside#chat .chat-header {
    width: 100%;
    padding: 15px 15px 0 15px;
    background: #151820;
  }

  aside#chat .chat-header {
    width: 100%;
    height: 45px;
    padding: 0 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #191d26;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  }

  aside#chat .header-online {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    color: #00c74d;
  }

  aside#chat .online-dot {
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 6px;
  }

  aside#chat .online-dot::before {
    content: '';
    width: 5px;
    height: 5px;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 50%;
    background: #00c74d;
  }

  aside#chat .dot-wave {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    animation: online-counter 2s linear forwards infinite;
  }

  aside#chat .header-socials {
    display: flex;
  }

  aside#chat .header-socials a {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }

  aside#chat .header-socials a.socials-twitter {
    background: #234965;
  }

  aside#chat .header-socials a.socials-discord {
    margin-left: 10px;
    background: #2f3762;
  }

  aside#chat .header-socials a.socials-twitter svg {
    width: 14px;
    fill: #53b5ff;
  }

  aside#chat .header-socials a.socials-discord svg {
    width: 16px;
    fill: #a4b1ff;
  }

  aside#chat .chat-room {
    width: 100%;
    padding: 15px 15px 0 15px;
    background: #151820;
  }

  aside#chat .chat-content {
    width: 100%;
    height: calc(100% - 202px);
    position: relative;
  }

  aside#chat .content-messages {
    height: 100%;
    padding: 0 10px 12px 10px;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
  }

  aside#chat.chat-rain .content-messages {
    height: calc(100% - 111px);
  }

  aside#chat .content-messages::-webkit-scrollbar-track {
    background: transparent;
  }

  aside#chat .content-messages::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  aside#chat .content-rain {
    width: 100%;
    position: absolute;
    top: 0;
    padding: 10px 13px 10px 13px;
    background: linear-gradient(180deg, rgba(21, 24, 32, 0.7) 90%, rgba(21, 24, 32, 0));
  }

  aside#chat .content-info {
    width: calc(100% - 40px);
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 12px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  aside#chat.chat-rain .content-info {
    bottom: 123px;
  }

  aside#chat .content-rain .slide-fade-enter-from,
  aside#chat .content-info .slide-fade-enter-from {
    opacity: 0;
  }

  aside#chat .content-rain .slide-fade-leave-to,
  aside#chat .content-info .slide-fade-leave-to {
    transform: translate(0, 10px);
    opacity: 0;
  }

  aside#chat .chat-footer {
    width: 100%;
  }

  aside#chat .content-info button.button-scroll {
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    border-radius: 5px;
    font-size: 11px;
    font-weight: 600;
    color: #626c7e;
    background: #212732;
    border-bottom: 2px solid #191e27;
  }

  aside#chat .content-info button.button-scroll:hover {
    color: #ffffff;
  }

  aside#chat .footer-input {
    width: 100%;
    height: 56px;
    background: #1a1e27;
  }

  aside#chat .footer-input {
    width: 100%;
    height: 56px;
    position: relative;
    background: #1a1e27;
  }

  aside#chat .footer-input input {
    width: 100%;
    height: 56px;
    padding: 0 56px 0 18px;
    font-size: 13px;
    font-weight: 500;
    color: #ffffff;
    background: transparent;
  }

  aside#chat .footer-input input::placeholder {
    color: #717687;
  }

  aside#chat .footer-input button {
    position: absolute;
    top: 50%;
    right: 18px;
    transform: translate(0, -50%);
    padding: 0;
    margin: 0;
  }

  aside#chat .footer-input button svg {
    fill: #00C74D
  }

  aside#chat .footer-actions {
    width: 100%;
    height: 43px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 18px;
    background: #1a1e27;
    border-top: 1px solid #151920;
  }

  aside#chat .footer-actions button {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: #5f6779;
    background: transparent;
  }

  aside#chat .footer-actions button:hover {
    color: #ffffff;
  }

  aside#chat .footer-actions button svg {
    margin-right: 6px;
    fill: #5f6779;
    transition: all 0.3s ease;
  }

  aside#chat .footer-actions button:hover svg {
    fill: #ffffff;
  }

  @keyframes online-counter {
    0% {
      transform: scale(0.2, 0.2);
      background: rgba(0, 199, 77, 0.2);
    }
    50% {
      transform: scale(0.7, 0.7);
      background: rgba(0, 199, 77, 0.2);
    }
    100% {
      transform: scale(1.2, 1.2);
      background: rgba(0, 199, 77, 0);
    }
  }

  @media screen and (-webkit-min-device-pixel-ratio: 0) and (max-device-width: 1024px) {

    aside#chat .footer-input input {
      font-size: 16px;
    }

  }

  @media only screen and (max-width: 300px) {

    aside#chat {
      width: 100%;
    }

    .app-page.page-full aside#chat {
      width: 300px;
    }

  }
</style>
