<template>
  <div class="cases-overview">
    <div class="overview-header">
      <div class="header-select">
        <button @click="casesSetFilterSelect('featured')" :class="{ 'button-active': casesFilterSelect === 'featured' }">FEATURED</button>
        <button @click="casesSetFilterSelect('low')" :class="{ 'button-active': casesFilterSelect === 'low' }">LOW RISK</button>
        <button @click="casesSetFilterSelect('high')" :class="{ 'button-active': casesFilterSelect === 'high' }">HIGH RISK</button>
        <button @click="casesSetFilterSelect('partner')" :class="{ 'button-active': casesFilterSelect === 'partner' }">PARTNER</button>
      </div>
      <div class="header-filter">
        <CasesFilterSearch />
      </div>
    </div>
    <div class="overview-content">
      <div v-if="casesData.loading" class="content-loading" key="loading">

        <SkeletonLoader v-for="index in 18" :key="index" v-once />

      </div>
      <div v-else-if="casesGetBoxes.length > 0" class="content-list" key="data">

        <CasesBoxElement 
          v-for="box of casesGetBoxes" 
          :key="box._id" 
          :box="box" 
        />

      </div>
      <div v-else class="content-empty" key="empty">There are no cases.</div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import SkeletonLoader from '@/components/SkeletonLoader';
  import CasesFilterSearch from '@/components/cases/CasesFilterSearch';
  import CasesBoxElement from '@/components/cases/CasesBoxElement';

  export default {
    components: {
      SkeletonLoader,
      CasesFilterSearch,
      CasesBoxElement
    },
    methods: {
      ...mapActions([
        'casesSetFilterSelect'
      ])
    },
    computed: {
      ...mapGetters([
        'casesFilterSelect',
        'casesFilterSearch',
        'casesData'
      ]),
      casesGetBoxes() {
        let boxes = [];

        for(const box of this.casesData.boxes) {
          if(
            box.categories.includes(this.casesFilterSelect) && 
            box.name.toLowerCase().includes(this.casesFilterSearch.toLowerCase().trim())
          ) {
            boxes.push(box);
          }
        }

        return boxes;
      }
    }
  }
</script>

<style scoped>
  .cases-overview .overview-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cases-overview .header-select,
  .cases-overview .header-filter {
    display: flex;
    align-items: center;
  }

  .cases-overview .header-select button {
    height: 44px;
    margin-right: 12px;
    padding: 0 16px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    color: #767c8b;
    background: #212732;
    box-shadow: 0px 2px 0px 0px #191e27;
    transition: all 0.3s ease;
  }

  .cases-overview .header-select button:last-of-type {
    margin-right: 0;
  }

  .cases-overview .header-select button:hover {
    color: #ffffff;
  }

  .cases-overview .header-select button.button-active {
    color: #fd3b31;
    background: #252129;
    box-shadow: 0px 0px 0px 1px #59272b;
  }

  .cases-overview .overview-content {
    width: 100%;
    margin-top: 26px;
  }

  .cases-overview .content-loading,
  .cases-overview .content-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  .cases-overview .skeleton-loader {
    width: calc(16.66% - 13.33px);
    height: 267px;
    border-radius: 18px;
    background: linear-gradient(180deg, #1a1e29, #1c202c);
  }

  .cases-overview .content-empty {
    width: 100%;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    color: #626c7e;
  }

  @media only screen and (max-width: 1250px) {

    .cases-overview .skeleton-loader {
      width: calc(20% - 12.8px);       
    }

  }

  @media only screen and (max-width: 1000px) {

    .cases-overview .skeleton-loader {
      width: calc(25% - 12px);       
    }

  }

  @media only screen and (max-width: 750px) {

    .cases-overview .skeleton-loader {
      width: calc(33.33% - 10.66px);       
    }

  }

  @media only screen and (max-width: 700px) { 

    .cases-overview .overview-header {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }

    .cases-overview .header-select,
    .cases-overview .header-filter {
      width: 100%;
    }

    .cases-overview .header-select {
      flex-direction: column;
    }

    .cases-overview .header-select button {
      width: 100%;
      margin-bottom: 8px;
      margin-right: 0;
    }

    .cases-overview .header-select button:last-of-type {
      margin-bottom: 0;
    }

    .cases-overview .header-filter {
      margin-top: 15px;
    }

  }

  @media only screen and (max-width: 550px) {

    .cases-overview .skeleton-loader {
      width: calc(50% - 8px);       
    }

  }
</style>