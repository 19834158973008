<template>
    <div class="modal-redeem">
        <div class="redeem-title">
            REDEEM CODE
        </div>
        <div class="redeem-close">
            <button v-on:click="modalsSetShow(null)">
                <svg width="11" height="11" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0005 1.22222L9.77827 0L5.50019 4.27808L1.22222 0.000111474L0 1.22233L4.27797 5.5003L0.000501987 9.77776L1.22272 11L5.50019 6.72252L9.77776 11.0001L11 9.77788L6.72241 5.5003L11.0005 1.22222Z" />
                </svg>
                CLOSE
            </button>
        </div> 
        <div class="redeem-content">
            <div class="content-affiliate">
                <div class="affiliate-title">AFFILIATE CODE</div>
                <div class="affiliate-info">
                    Use Code <span class="info-code">“ROLL”</span> for a free
                    <img src="@/assets/img/icons/coin.png" alt="coins-image" />
                    <div class="info-amount">
                        <span>0</span>.50
                    </div>
                </div>
                <div class="affiliate-input">
                    <input v-model="modalAffiliateCode" type="text" placeholder="Enter code..." />
                    <button v-on:click="modalAffiliateButton()">REDEEM CODE</button>
                </div>
            </div>
            <div class="content-promo">
                <div class="promo-title">PROMO CODE</div>
                <div class="promo-info">
                    Be active on our social media Accounts to get access to new promo codes.
                </div>
                <div class="promo-input">
                    <input v-model="modalPromoCode" type="text" placeholder="Enter code..." />
                    <button v-on:click="modalPromoButton()">REDEEM CODE</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'ModalRedeem',
        data() {
            return {
                modalAffiliateCode: null,
                modalPromoCode: null
            }
        },
        methods: {
            ...mapActions([
                'modalsSetShow', 
                'modalsSetData', 
                'notificationShow'
            ]),
            modalAffiliateButton() {
                if(this.modalAffiliateCode === null || this.modalAffiliateCode.trim() === '') {
                    this.notificationShow({ type: 'error', message: 'Your entered affiliate code is invalid.' });
                    return;
                }

                this.modalsSetShow(null);

                setTimeout(() => {
                    this.modalsSetData({ type: 'affiliatesClaim', data: { code: this.modalAffiliateCode } });
                    this.modalsSetShow('Captcha');
                }, 200);

                localStorage.removeItem('ref-code');
            },
            modalPromoButton() {
                if(this.modalPromoCode === null || this.modalPromoCode.trim() === '') {
                    this.notificationShow({ type: 'error', message: 'Your entered promo code is invalid.' });
                    return;
                }

                this.modalsSetShow(null);

                setTimeout(() => {
                    this.modalsSetData({ type: 'promoClaim', data: { code: this.modalPromoCode } });
                    this.modalsSetShow('Captcha');
                }, 200);
            }
        },
        computed: {
            ...mapGetters([
                'generalSettings', 
                'authUser',  
                'modalsData'
            ])
        },
        created() {
            if(this.modalsData !== null && this.modalsData.code !== undefined) {
                this.modalAffiliateCode = this.modalsData.code;
            }
        }
    }
</script>

<style scoped>
     .modal-redeem {
        width: 620px;
        position: relative;
        display: flex;
        justify-content: center;
        padding: 60px 0 10px 0;
        border-radius: 8px;
        background: #222635;
    }

    .modal-redeem .redeem-title {
        height: 60px;
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        left: 0;
        padding-left: 22px;
        font-size: 18px;
        font-weight: 700;
        color: #596076;
    }

    .modal-redeem .redeem-close {
        position: absolute;
        top: 17px;
        right: 12px;
    }

    .modal-redeem .redeem-close button {
        height: 27px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
        background: #191e27;
    }

    .modal-redeem .redeem-close button:hover {
        color: #ffffff;
    }

    .modal-redeem .redeem-close button svg {
        margin-right: 8px;
        fill: #767c8b;
        transition: all 0.3s ease;
    }

    .modal-redeem .redeem-close button:hover svg {
        fill: #ffffff;
    }

    .modal-redeem .redeem-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px 15px 25px 15px;
        background: #1c202c;
    }

    .modal-redeem .content-affiliate,
    .modal-redeem .content-promo {
        width: 100%;
    }

    .modal-redeem .content-promo {
        margin-top: 20px;
    }

    .modal-redeem .affiliate-title,
    .modal-redeem .promo-title {
        padding: 0 5px;
        font-size: 15px;
        font-weight: 600;
        color: #ffffff;
    }

    .modal-redeem .affiliate-info,
    .modal-redeem .promo-info {
        margin-top: 3px;
        padding: 0 5px;
        font-size: 14px;
        font-weight: 700;
        color: #767c8b;
    }

    .modal-redeem .affiliate-info {
        display: flex;
        align-items: center;
    }

    .modal-redeem .affiliate-info span.info-code,
    .modal-redeem .promo-info span.info-code {
        margin: 0 5px;
        color: #fd3b31;
    }

    .modal-redeem .affiliate-info img {
        width: 21px;
        margin-left: 10px;
        margin-right: 8px;
    }

    .modal-redeem .info-amount {
        font-size: 12px;
        font-weight: 700;
        color: #00C74d;
    }

    .modal-redeem .info-amount span {
        font-size: 15px;
    }

    .modal-redeem .affiliate-input,
    .modal-redeem .promo-input {
        width: 100%;
        position: relative;
        margin-top: 9px;
    }

    .modal-redeem .affiliate-input input,
    .modal-redeem .promo-input input {
        width: 100%;
        height: 54px;
        padding: 0 17px;
        border-radius: 8px;
        font-size: 13px;
        font-weight: 700;
        color: #ffffff;
        background: #191e27;
        border: 1px solid #262c3a;
    }

    .modal-redeem .affiliate-input input::placeholder,
    .modal-redeem .promo-input input::placeholder {
        font-size: 13px;
        font-weight: 700;
        color: #596076;
    }

    .modal-redeem .affiliate-input button,
    .modal-redeem .promo-input button {
        width: 130px;
        height: 42px;
        position: absolute;
        top: 6px;
        right: 6px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background: #00c74d;
        border-bottom: 2px solid #00732c;
    }

    .modal-redeem .affiliate-input button:hover,
    .modal-promo .affiliate-input button:hover {
        background: #00de56;
    }

    @media only screen and (max-width: 650px) {

        .modal-redeem {
            width: calc(100vw - 20px);
        }

    }
</style>
