<template>
  <div class="modal-box-info">
    <div class="info-header">
      <img :src="modalsData.box.image" />
      <div class="header-name">{{ modalsData.box.name }}</div>
    </div>
    <div class="info-close">
      <button v-on:click="battlesBoxButton()">
        <svg width="11" height="11" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M11.0005 1.22222L9.77827 0L5.50019 4.27808L1.22222 0.000111474L0 1.22233L4.27797 5.5003L0.000501987 9.77776L1.22272 11L5.50019 6.72252L9.77776 11.0001L11 9.77788L6.72241 5.5003L11.0005 1.22222Z" />
        </svg>
        CLOSE
      </button>
    </div>
    <div class="info-content">
      <div class="content-list">

        <CasesItemElement 
          v-for="item of modalsData.box.items" 
          :key="item._id" 
          :item="item" 
          :box="modalsData.box" 
        />

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CasesItemElement from '@/components/cases/CasesItemElement';

export default {
  components: {
    CasesItemElement
  },
  methods: {
    ...mapActions([
      'modalsSetShow',
      'modalsSetData'
    ]),
    battlesBoxButton() {
      this.modalsSetShow('BattlesSelect');
    },
  },
  computed: {
    ...mapGetters([
      'modalsData'
    ])
  }
}
</script>

<style scoped>
  .modal-box-info {
    width: 1020px;
    position: relative;
    display: flex;
    justify-content: center;
    padding: 60px 0 10px 0;
    border-radius: 8px;
    background: #222635;
  }

  .modal-box-info .info-header {
    width: calc(100% - 125px);
    position: absolute;
    display: flex;
    align-items: center;
    top: 8px;
    left: 12px;
  }

  .modal-box-info .info-header img {
    width: 42px;
  }

  .modal-box-info .header-name {
    margin-left: 8px;
    font-size: 18px;
    font-weight: 700;
    color: #596076;
  }

  .modal-box-info .info-close {
    position: absolute;
    top: 17px;
    right: 12px;
  }

  .modal-box-info .info-close button {
    height: 27px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 700;
    color: #767c8b;
    background: #191e27;
  }

  .modal-box-info .info-close button:hover {
    color: #ffffff;
  }

  .modal-box-info .info-close button svg {
    margin-right: 8px;
    fill: #767c8b;
    transition: all 0.3s ease;
  }

  .modal-box-info .info-close button:hover svg {
    fill: #ffffff;
  }

  .modal-box-info .info-content {
    width: 100%;
    padding: 0 12px;
    background: #1c202c;
  }

  .modal-box-info .content-list {
    width: 100%;
    height: 480px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 12px;
    overflow-y: scroll;
  }

  .modal-box-info .content-list .cases-item-element {
    background: #12161d;
  }

  @media only screen and (max-width: 1040px) {

    .modal-box-info {
      width: calc(100vw - 20px);   
    }

  }

</style>