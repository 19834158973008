<template>
  <div class="chat-message-element">
    <div class="element-top">
      <div v-if="this.message.user.rank === 'system'" class="top-system">
        <div class="system-avatar">!</div>
        <div class="system-rank">BOT</div>
        <div class="system-username">System</div>
      </div>
      <button v-else @click="chatUserButton()" :class="['button-user',
        `user-${userGetLevelColor(message.user.level)}`,
        `user-${message.user.rank}`
      ]">
        <AvatarImage :image="message.user.avatar" />
        <div class="user-rank">
          <IconStar v-if="!['system', 'admin', 'mod'].includes(message.user.rank)" />
          {{ ['admin', 'mod'].includes(message.user.rank) ? message.user.rank.toUpperCase() : message.user.level }}
        </div>
        <div v-html="message.user.username" class="user-username"></div>
      </button>
      <div v-if="authUser.user && ['admin', 'mod'].includes(authUser.user.rank)" class="top-actions">
        <button @click="chatMuteButton()" class="button-mute">
          <IconUser />
        </button>
        <button @click="chatRemoveButton()" class="button-remove">
          <IconClose />
        </button>
      </div>
    </div>
    <div :class="['element-text', { 
      'text-system': message.user.rank === 'system' 
    }]" v-html="message.message"></div>
  </div>
</template>

<script>
  import mixins from '@/mixins';
  import { mapGetters, mapActions } from 'vuex';
  import IconStar from '@/components/icons/IconStar';
  import IconUser from '@/components/icons/IconUser';
  import IconClose from '@/components/icons/IconClose';
  import AvatarImage from '@/components/AvatarImage';

  export default {
    components: {
      IconStar,
      IconUser,
      IconClose,
      AvatarImage
    },
    mixins: [
      mixins
    ],
    props: {
      message: Object
    },
    methods: {
      ...mapActions([
        'modalsSetShow', 
        'modalsSetData'
      ]),
      chatUserButton() {
        this.modalsSetData({ user: this.message.user });
        this.modalsSetShow('ChatUser');
      },
      chatMuteButton() {
        this.modalsSetData({ user: this.message.user });
        this.modalsSetShow('Mute');
      },
      chatRemoveButton() {
        this.modalsSetData({ message: this.message });
        this.modalsSetShow('Remove');
      }
    },
    computed: {
      ...mapGetters([
        'authUser'
      ])
    }
  }
</script>

<style scoped>
  .chat-message-element {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 12px;
    padding: 8px;
    border-radius: 5px;
  }

  .chat-message-element .element-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .chat-message-element .top-system,
  .chat-message-element button.button-user {
    display: flex;
    align-items: center;
  }

  .chat-message-element .system-avatar,
  .chat-message-element button.button-user .avatar-image {
    width: 20px;
    height: 20px;
    margin-right: 6px;
    border-radius: 3px;
  }

  .chat-message-element .system-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    color: #ffffff;
    background: #67c4f8;
  }

  .chat-message-element .system-rank,
  .chat-message-element .user-rank {
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    padding: 0 5px;
    border-radius: 3px;
    font-size: 11px;
    font-weight: 600;
    color: #ffffff;
    background: #a1aeb5;
  }

  .chat-message-element .system-rank {
    background: #67c4f8;
  }

  .chat-message-element button.button-user.user-gold .user-rank {
    background: #f7d814;
  }

  .chat-message-element button.button-user.user-diamond .user-rank {
    background: #1fb1ff;
  }

  .chat-message-element button.button-user.user-pink .user-rank {
    background: #e614f7;
  }

  .chat-message-element button.button-user.user-mod .user-rank {
    background: #00c74d;
  }

  .chat-message-element button.button-user.user-admin .user-rank {
    background: #fd3b31;
  }

  .chat-message-element .user-rank svg {
    width: 11px;
    margin-right: 5px;
    margin-bottom: 1px;
    fill: #ffffff;
  }

  .chat-message-element .system-username,
  .chat-message-element .user-username {
    max-width: 150px;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .chat-message-element button.button-user.user-mod .user-username {
    max-width: 112px;
  }

  .chat-message-element button.button-user.user-admin .user-username {
    max-width: 100px;
  }

  .chat-message-element .top-actions {
    display: flex;
  }

  .chat-message-element button.button-mute,
  .chat-message-element button.button-remove {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
  }

  .chat-message-element button.button-mute {
    margin-right: 5px;
    background: #ffc93d;
  }

  .chat-message-element button.button-remove {
    background: #fd3b31;
  }

  .chat-message-element button.button-mute svg,
  .chat-message-element button.button-remove svg {
    width: 8px;
    fill: #ffffff;
  }

  .chat-message-element .element-text {
    margin-top: 8px;
    padding: 10px 15px;
    border-radius: 8px;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    text-align: left;
    font-size: 13px;
    font-weight: 500;
    color: #ffffff;
    background: #1b222f;
  }
</style>
