<template>
    <div class="modal-affiliates-user-element">
        <div class="element-section section-user">
            <div class="section-title">USER</div>
            <a v-bind:href="`https://steamcommunity.com/profiles/${user.steamid}`" target="_blank" class="section-content">
                <AvatarImage v-bind:image="user.avatar" />
                <div class="content-username">
                    <div v-html="user.username" class="username-text"></div>
                    <span class="username-rank" v-bind:class="{ 'rank-mod': user.rank === 'mod', 'rank-admin': user.rank === 'admin' }">{{user.rank.toUpperCase()}}</span>
                </div>
            </a>
        </div>
        <div class="element-section section-deposited">
            <div class="section-title">DEPOSITED</div>
            <div class="section-content">
                <img src="@/assets/img/icons/coin.png" alt="coins-image" />
                <div class="content-amount">
                    <span>{{generalFormatAmount(user.stats.total.deposit).split('.')[0]}}</span>.{{generalFormatAmount(user.stats.total.deposit).split('.')[1]}} DEPOSITED
                </div>
            </div>
        </div>
        <div class="element-section section-earned">
            <div class="section-title">EARNED</div>
            <div class="section-content">
                <img src="@/assets/img/icons/coin.png" alt="coins-image" />
                <div class="content-amount">
                    <span>{{generalFormatAmount(user.affiliates.generated).split('.')[0]}}</span>.{{generalFormatAmount(user.affiliates.generated).split('.')[1]}} EARNED
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import mixins from '@/mixins';
    import AvatarImage from '@/components/AvatarImage';

    export default {
        components: {
            AvatarImage
        },
        mixins: [
            mixins
        ],
        props: {
            user: Object
        }
    }
</script>

<style scoped>
    .modal-affiliates-user-element {
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
    }

    .modal-affiliates-user-element:nth-child(odd) {
        border-radius: 8px;
        background: #1d212d;
    }

    .modal-affiliates-user-element .element-section {
        display: flex;
        flex-direction: column;
    }

    .modal-affiliates-user-element .element-section.section-user {
        width: 40%;
    }

    .modal-affiliates-user-element .element-section.section-deposited,
    .modal-affiliates-user-element .element-section.section-earned {
        width: 30%;
    }

    .modal-affiliates-user-element .section-title {
        display: none;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .modal-affiliates-user-element .section-content {
        display: flex;
        align-items: center;
    }

    .modal-affiliates-user-element .element-section.section-earned .section-content {
        justify-content: flex-end;
    }

    .modal-affiliates-user-element .element-section.section-user .avatar-image {
        width: 24px;
        height: 24px;
        margin-right: 12px;
        border-radius: 5px;
    }

    .modal-affiliates-user-element .element-section.section-user .content-username {
        display: flex;
        align-items: center;
    }

    .modal-affiliates-user-element .element-section.section-user .username-text {
        max-width: 160px;
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .modal-affiliates-user-element .element-section.section-user .content-username span.username-rank {
        margin-left: 10px;
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .modal-affiliates-user-element .element-section.section-user .content-username span.username-rank.rank-mod {
        color: #00c74d;
    }

    .modal-affiliates-user-element .element-section.section-user .content-username span.username-rank.rank-admin {
        color: #fd3b31;
    }

    .modal-affiliates-user-element .element-section.section-deposited img,
    .modal-affiliates-user-element .element-section.section-earned img {
        width: 21px;
        margin-right: 10px;
    }

    .modal-affiliates-user-element .element-section.section-deposited .content-amount,
    .modal-affiliates-user-element .element-section.section-earned .content-amount {
        font-size: 12px;
        font-weight: 700;
    }

    .modal-affiliates-user-element .element-section.section-deposited .content-amount {
        color: #bbbbbb;
    }

    .modal-affiliates-user-element .element-section.section-earned .content-amount {
        color: #00c74d;
    }

    .modal-affiliates-user-element .element-section.section-deposited .content-amount span,
    .modal-affiliates-user-element .element-section.section-earned .content-amount span {
        font-size: 15px;
    }

    .modal-affiliates-user-element .element-section.section-deposited .content-amount span {
        color: #ffffff;
    }

    @media only screen and (max-width: 750px) {

        .modal-affiliates-user-element {
            height: auto;
            flex-direction: column;
            align-items: flex-start;
            padding: 10px 20px;
        }

        .modal-affiliates-user-element .element-section {
            width: 100%!important;
            margin-top: 10px;
        }

        .modal-affiliates-user-element .element-section.section-user {
            margin-top: 0;
        }

        .modal-affiliates-user-element .element-section.section-earned {
            align-items: flex-start;
        }

        .modal-affiliates-user-element .section-title {
            display: block;
        }

        .modal-affiliates-user-element .section-content {
            margin-top: 5px;
        }

    }
</style>
