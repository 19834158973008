<template>
  <button class="home-method-element">
    <img :src="require(`@/assets/img/cashier/${method}.webp`)" />
  </button>
</template>

<script>
  export default {
    props: {
      method: String
    }
  }
</script>

<style scoped>
  button.home-method-element {
    height: 40px;
    margin-right: 30px;
  }

  button.home-method-element:last-child {
    margin-right: 0;
  }

  button.home-method-element img {
    width: 40px;
    height: 40px;
    transition: transform 0.3s ease;
  }

  button.home-method-element:hover img {
    transform: scale(1.1);
  }
</style>