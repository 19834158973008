<template>
  <div class="battles-overview">
    <div class="overview-header">
      <div class="header-sort">
        <button @click="battlesSetFilterSort('price')" :class="{ 'button-active': battlesFilterSort === 'price' }">PRICE</button>
        <button @click="battlesSetFilterSort('date')" :class="{ 'button-active': battlesFilterSort === 'date' }">DATE</button>
      </div>
      <div class="header-active">
        ACTIVE BATTLES <span>{{ battlesData.games.length }}</span> 
      </div>
      <RouterLink to="/battles/create" class="link-create">CREATE BATTLE</RouterLink>
    </div>
    <div class="overview-content">
      <div v-if="battlesData.loading" class="content-loading" key="loading">

        <SkeletonLoader v-for="index in 4" :key="index" v-once />

      </div>
      <div v-else-if="battlesGetGames.length > 0" class="content-list" key="data">

        <BattlesGameElement 
          v-for="game of battlesGetGames" 
          :key="game._id" 
          :game="game" 
        />

      </div>
      <div v-else class="content-empty" key="empty">There are no games.</div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import SkeletonLoader from '@/components/SkeletonLoader';
  import BattlesGameElement from '@/components/battles/BattlesGameElement';

  export default {
    components: {
      SkeletonLoader,
      BattlesGameElement
    },
    data() {
      return {
        battlesFilterSort: 'price'
      }
    },
    methods: {
      battlesSetFilterSort(value) {
        this.battlesFilterSort = value;
      }
    },
    computed: {
      ...mapGetters([
        'battlesData'
      ]),
      battlesGetGames() {
        let games = this.battlesData.games;

        if(this.battlesFilterSort === 'price') { games.sort(function(a, b) { return b.amount - a.amount; }); }
        else { games.sort(function(a, b) { return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(); }); }

        games = [...games, ...this.battlesData.history];

        return games;
      }
    }
  }
</script>

<style scoped>
  .battles-overview {
    width: 100%;
  }

  .battles-overview .overview-header {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr auto auto;
  }

  .battles-overview .header-sort {
    display: flex;
    align-items: center;
  }

  .battles-overview .header-sort button {
    height: 44px;
    margin-right: 12px;
    padding: 0 16px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    color: #767c8b;
    background: #212732;
    box-shadow: 0px 2px 0px 0px #191e27;
  }

  .battles-overview .header-sort button:last-of-type {
    margin-right: 0;
  }

  .battles-overview .header-sort button:hover {
    color: #ffffff;
  }

  .battles-overview .header-sort button.button-active {
    color: #fd3b31;
    background: #252129;
    box-shadow: 0px 0px 0px 1px #59272b;
  }

  .battles-overview .header-active {
    grid-column: 3 / 3;
    font-size: 14px;
    font-weight: 600;
    color: #596076;
  }

  .battles-overview .header-active span {
    margin-left: 4px;
    color: #ffffff;
  }

  .battles-overview a.link-create {
    height: 44px;
    display: flex;
    grid-column: 4 / 4;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    padding: 0 16px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    background: #00c74d;
    box-shadow: 0px 2px 0px 0px #00732c;
  }

  .battles-overview .overview-content {
    width: 100%;
    margin-top: 26px;
  }

  .battles-overview .content-loading,
  .battles-overview .content-list {
    width: 100%;
  }

  .battles-overview .content-loading.fade-leave-active {
    transition: opacity 0.5s;
  }

  .battles-overview .content-loading.fade-leave-to {
    opacity: 0;
  }

  .battles-overview .skeleton-loader {
    width: 100%;
    height: 120px;
    margin-top: 15px;
    border-radius: 8px;
    background: #1c202c;
  }

  .battles-overview .skeleton-loader:first-child {
    margin-top: 0;
  }

  .battles-overview .content-empty {
    width: 100%;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    color: #626c7e;
  }

  .battles-overview .content-list.fade-enter-active,
  .battles-overview .content-empty.fade-enter-active {
    transition: opacity 0.5s;
  }

  .battles-overview .content-list.fade-enter-from,
  .battles-overview .content-empty.fade-enter-from {
    opacity: 0;
  }

  @media only screen and (max-width: 600px) {

    .battles-overview a.link-create {
      width: 100%;
      grid-column: 1 / 5;
      margin-left: 0;
      margin-top: 8px;
    }

  }
</style>
