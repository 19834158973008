const state = {
    minesData: {
        game: null,
        loading: false
    }
}

const getters = {
    minesData: state => state.minesData
}

const mutations = {
    mines_set_data_game(state, game) {
        state.minesData.game = game;
    },
    mines_set_data_loading(state, status) {
        state.minesData.loading = status;
    }
}

const actions = {
    minesClearGame({ commit }) {
        commit('mines_set_data_game', null);
    },
    minesGetDataSocket({ getters, commit, dispatch }, data) {
        if(getters.minesData.loading === true) { return; }
        commit('mines_set_data_loading', true);

        getters.socketClient.emit('mines:getData', data, (res) => {
            if(res.success === true) {
                commit('mines_set_data_game', res.game);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('mines_set_data_loading', false);
        });
    },
    minesSendCreateSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'MinesCreate');

        getters.socketClient.emit('mines:sendCreate', data, (res) => {
            if(res.success === true) {
                commit('auth_update_user', res.user);
                commit('mines_set_data_game', res.game);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    minesSendRevealSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'MinesReveal');

        getters.socketClient.emit('mines:sendReveal', data, (res) => {
            if(res.success === true) {
                commit('mines_set_data_game', res.game);

                if(getters.soundMines === 1 && res.game.state === 'created') {
                    getters.soundMinesReveal.volume = getters.soundVolume;
                    getters.soundMinesReveal.currentTime = 0;
                    getters.soundMinesReveal.play();
                } else if(getters.soundMines === 1 && res.game.state === 'completed') {
                    getters.soundMinesLoss.volume = getters.soundVolume;
                    getters.soundMinesLoss.play();
                }
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    minesSendCashoutSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'MinesCashout');

        getters.socketClient.emit('mines:sendCashout', data, (res) => {
            if(res.success === true) {
                commit('auth_update_user', res.user);
                commit('mines_set_data_game', res.game);

                if(getters.soundMines === 1) {
                    getters.soundCash.volume = getters.soundVolume;
                    getters.soundCash.play();
                }
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    }
}

const mines = {
    state,
    mutations,
    actions,
    getters
}

export default mines;
