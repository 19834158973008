<template>
  <div class="upgrader">
    <UpgraderGame />
    <UpgraderItems />
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import UpgraderGame from '@/components/upgrader/UpgraderGame';
  import UpgraderItems from '@/components/upgrader/UpgraderItems';

  export default {
    metaInfo: {
      title: 'Upgrader - RustRoll'
    },
    components: {
      UpgraderGame,
      UpgraderItems
    },
    methods: {
      ...mapActions([
        'upgraderSetItemsDataPage',
        'upgraderEmptyItemsDataSelected'
      ])
    },
    beforeRouteLeave(to, from, next) {
      this.upgraderSetItemsDataPage(1);
      this.upgraderEmptyItemsDataSelected();

      next();
    }
  }
</script>

<style scoped>
  .upgrader {
    width: 1300px;
    min-height: calc(100vh - 112px);
    padding: 70px 0;
  }

  @media only screen and (max-width: 1330px) {

    .upgrader {
      width: 100%;
      padding: 20px 10px;
    }

  }
</style>
