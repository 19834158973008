<template>
    <div
        v-bind:class="{ 'mines-tile': true, 'tile-revealed': (minesData.game !== null && isTileRevealed), 'tile-completed': (minesData.game !== null && minesData.game.state === 'completed') }">
            <div v-if="minesData.game !== null && minesGetRevealedTile === 'coin'" class="tile-coin">
                <div class="coin-back"></div>
                <div v-if="minesData.game.revealed.some((element) => element.tile === tile)" class="coin-multiplier">
                    <div class="multiplier-amount">
                        <span>x{{minesGetMultiplierTile.split('.')[0]}}</span>.{{minesGetMultiplierTile.split('.')[1]}}
                    </div>
                </div>
            </div>
            <div v-else-if="minesData.game !== null && minesGetRevealedTile === 'mine'" class="tile-mine">
                <div class="coin-back"></div>
            </div>
            <button v-else v-on:click="minesRevealTile()" class="button-reveal"
                v-bind:class="{ 'button-active': minesData.game !== null &&  minesData.game.state === 'created' }">
            </button>
    </div>
</template>

<script>
    import mixins from '@/mixins';
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'MinesTile',
        mixins: [
            mixins
        ],
        props: [
            'tile'
        ],
        methods: {
            ...mapActions([
                'notificationShow', 
                'minesSendRevealSocket'
            ]),
            minesRevealTile() {
                if(this.socketSendLoading !== null) { return; }

                if(this.authUser.user === null) {
                    this.notificationShow({ type: 'error', message: 'Please sign in to perform this action.' });
                    return;
                }

                if(this.minesData.game === null) {
                    this.notificationShow({type: 'error', message: 'You have no open mines game at the moment.' });
                    return;
                }

                this.minesSendRevealSocket({ tile: this.tile });
            }
        },
        computed: {
            ...mapGetters([
                'authUser', 
                'minesData', 
                'socketSendLoading'
            ]),
            minesGetRevealedTile() {
                if(this.minesData.game.state === 'completed') {
                    return this.minesData.game.deck[this.tile];
                } else {
                    const index = this.minesData.game.revealed.findIndex(element => element.tile === this.tile);
                    if(index !== -1) {
                        return this.minesData.game.revealed[index].value;
                    } else { return null; }
                }
            },
            minesGetMultiplierTile() {
                const index = this.minesData.game.revealed.findIndex((element) => element.tile === this.tile);

                const first = 25 === (index + 1) ? 1 : this.minesGetFactorialNumber(25) / (this.minesGetFactorialNumber(index + 1) * this.minesGetFactorialNumber(25 - (index + 1)));
                const second = (25 - this.minesData.game.minesCount) === (index + 1) ? 1 : this.minesGetFactorialNumber(25 - this.minesData.game.minesCount) / (this.minesGetFactorialNumber(index + 1) * this.minesGetFactorialNumber((25 - this.minesData.game.minesCount) - (index + 1)));
                let multiplier = 0.93 * (first / second);
                multiplier = multiplier < 1 ? 1.01 : multiplier;

                return parseFloat(Math.round(multiplier * 100) / 100).toFixed(2).toString();
            },
            isTileRevealed() {
                const index = this.minesData.game.revealed.findIndex((element) => element.tile === this.tile);
                return (index !== -1);
            }
        }
    }
</script>

<style scoped>
    .mines-tile {
        width: 100%;
        height: 100%;
    }

    .mines-tile button.button-reveal {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        background-color: #212732;
        background-image: url('~@/assets/img/mines/tile.webp');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        /* border-bottom: 3px solid #1d222a; */
        opacity: 0.5;
        cursor: not-allowed;
    }

    .mines-tile button.button-reveal:hover {
        background-color: #2d3441;
        transform: translateY(-3px);
    }

    .mines-tile button.button-reveal.button-active {
        opacity: 1;
        cursor: pointer;
    }

    .mines-tile .tile-coin {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        opacity: 0.3;
    }
    
    .mines-tile .tile-coin .coin-back {
        position: absolute;
        inset: 0px;
        background-size: cover;
        animation: 0.5s steps(19) 0s 1 normal forwards running sprites;
        background-image: url("~@/assets/img/mines/mines-win.png");
    }
    
    .mines-tile .tile-coin .coin-multiplier {
        position: absolute;
        bottom: 0.375rem;
        left: 20px;
        display: flex;
        align-items: center;
        gap: 0.25rem;
        font-size: 0.75rem;
        line-height: 1rem;
        font-weight: 700;
        --tw-text-opacity: 1;
        color: rgb(255 199 1 / var(--tw-text-opacity));
    }

    .mines-tile .tile-mine {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        opacity: 0.3;
        /* transition: all .3s; */
    }
    
    .mines-tile .tile-mine .coin-back {
        position: absolute;
        inset: 0px;
        background-size: cover;
        animation: 0.5s steps(99) 0s 1 normal forwards running sprites;
        background-image: url("~@/assets/img/mines/mines-loss.png");
    }

    @keyframes sprites {
        0% {
            background-position: 0px 0px;
        }

        100% {
            background-position: 0px 100%;
        }        
    }

    .mines-tile.tile-revealed .tile-coin,
    .mines-tile.tile-revealed .tile-mine {
        opacity: 1;
        transform: scale(1.0);
        transition: transform .2s ease 0ms;
        animation: size-item-animation 0.3s ease-in-out normal forwards;
        animation-delay: 0s, .3s;
    }

    @keyframes size-item-animation {

        0%,
        100% {
            transform: scale(1.0);
        }

        50% {
            transform: scale(1.03);
        }
    }

    @media only screen and (max-width: 850px) {
        .mines-tile .tile-coin .coin-multiplier {
            bottom: -1px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 9px;
        }
    }
</style>
