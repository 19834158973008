<template>
    <div class="leaderboard-user-element">
        <div class="element-section section-rank">
            <div class="section-title">RANK</div>
            <div class="section-content">
                {{pos}}th
            </div>
        </div>
        <div class="element-section section-user">
            <div class="section-title">USER</div>
            <div class="section-content">
                <AvatarImage v-bind:image="leaderboardGetAvatar" />
                <span v-html="leaderboardGetUsername"></span>
            </div>
        </div>
        <div class="element-section section-wagered">
            <div class="section-title">WAGERED</div>
            <div class="section-content">
                <img src="@/assets/img/icons/coin.png" alt="coins-image" />
                <div class="content-value">
                    <span>{{generalFormatAmount(winner.points).split('.')[0]}}</span>.{{generalFormatAmount(winner.points).split('.')[1]}}
                </div>
            </div>
        </div>
        <div class="element-section section-prize">
            <div class="section-title">PRIZE</div>
            <div class="section-content">
                <img src="@/assets/img/icons/coin.png" alt="coins-image" />
                <div class="content-value">
                    <span>{{generalFormatAmount(winner.prize).split('.')[0]}}</span>.{{generalFormatAmount(winner.prize).split('.')[1]}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import mixins from '@/mixins';
    import AvatarImage from '@/components/AvatarImage';

    export default {
        name: 'LeaderboardUserElement',
        components: {
            AvatarImage
        },
        mixins: [
            mixins
        ],
        props: [
            'pos',
            'winner'
        ],
        methods: {
            
        },
        computed: {
            leaderboardGetAvatar() {
                let avatar = null;

                if(this.winner.user !== null) { avatar = this.winner.user.avatar; }

                return avatar;
            },
            leaderboardGetUsername() {
                let username = 'Empty';

                if(this.winner.user !== null) { username = this.winner.user.username; }

                return username;
            }
        }
    }
</script>

<style scoped>
    .leaderboard-user-element {
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
    }

    .leaderboard-user-element:nth-child(odd) {
        border-radius: 8px;
        background: #1d212d;
    }

    .leaderboard-user-element .element-section {
        display: flex;
        flex-direction: column;
    }

    .leaderboard-user-element .element-section.section-rank {
        width: 10%;
    }

    .leaderboard-user-element .element-section.section-user {
        width: 50%;
    }

    .leaderboard-user-element .element-section.section-wagered,
    .leaderboard-user-element .element-section.section-prize {
        width: 20%;
    }

    .leaderboard-user-element .section-title {
        display: none;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .leaderboard-user-element .section-content {
        display: flex;
        align-items: center;
    }

    .leaderboard-user-element .element-section.section-rank .section-content,
    .leaderboard-user-element .element-section.section-user .section-content {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .leaderboard-user-element .element-section.section-prize .section-content {
        justify-content: flex-end;
    }

    .leaderboard-user-element .avatar-image {
        width: 24px;
        height: 24px;
        margin-right: 12px;
        border-radius: 5px;
    }

    .leaderboard-user-element .element-section.section-wagered .section-content img,
    .leaderboard-user-element .element-section.section-prize .section-content img {
        width: 21px;
        margin-right: 10px;
    }

    .leaderboard-user-element .content-value {
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .leaderboard-user-element .element-section.section-prize .content-value {
        color: #00c74d;
    }

    .leaderboard-user-element .content-value span {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .leaderboard-user-element .element-section.section-prize .content-value span {
        color: #00c74d;
    }

    @media only screen and (max-width: 900px) {

        .leaderboard-user-element {
            height: auto;
            flex-direction: column;
            align-items: flex-start;
            padding: 10px 20px;
        }

        .leaderboard-user-element .element-section {
            width: 100%!important;
            margin-top: 10px;
        }

        .leaderboard-user-element .element-section.section-rank {
            margin-top: 0;
        }

        .leaderboard-user-element .element-section.section-prize {
            align-items: flex-start;
        }

        .leaderboard-user-element .section-title {
            display: block;
        }

        .leaderboard-user-element .section-content {
            margin-top: 5px;
        }

    }
</style>