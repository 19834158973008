<template>
    <svg width="25" height="25" viewBox="0 0 403 370" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M372.403 260.464L251.708 51.7634C229.375 13.1452 173.625 13.1452 151.292 51.7634L30.5969 260.464C8.23536 299.13 36.1383 347.5 80.8054 347.5H322.195C366.862 347.5 394.765 299.13 372.403 260.464ZM270.753 40.7496C239.948 -12.5169 163.052 -12.5169 132.247 40.7496L11.5523 249.45C-19.2912 302.783 19.1956 369.5 80.8054 369.5H322.195C383.804 369.5 422.291 302.783 391.448 249.45L270.753 40.7496Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M149 206C158.941 206 167 197.941 167 188C167 178.059 158.941 170 149 170C139.059 170 131 178.059 131 188C131 197.941 139.059 206 149 206ZM149 223C168.33 223 184 207.33 184 188C184 168.67 168.33 153 149 153C129.67 153 114 168.67 114 188C114 207.33 129.67 223 149 223Z" />
        <path d="M161 188C161 194.627 155.627 200 149 200C142.373 200 137 194.627 137 188C137 181.373 142.373 176 149 176C155.627 176 161 181.373 161 188Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M254 206C263.941 206 272 197.941 272 188C272 178.059 263.941 170 254 170C244.059 170 236 178.059 236 188C236 197.941 244.059 206 254 206ZM254 223C273.33 223 289 207.33 289 188C289 168.67 273.33 153 254 153C234.67 153 219 168.67 219 188C219 207.33 234.67 223 254 223Z" />
        <circle cx="254" cy="188" r="12" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M93.5 302C104.27 302 113 293.27 113 282.5C113 271.73 104.27 263 93.5 263C82.7304 263 74 271.73 74 282.5C74 293.27 82.7304 302 93.5 302ZM93.5 320C114.211 320 131 303.211 131 282.5C131 261.789 114.211 245 93.5 245C72.7893 245 56 261.789 56 282.5C56 303.211 72.7893 320 93.5 320Z" />
        <path d="M106.357 282.5C106.357 289.601 100.601 295.357 93.5 295.357C86.3992 295.357 80.6429 289.601 80.6429 282.5C80.6429 275.399 86.3992 269.643 93.5 269.643C100.601 269.643 106.357 275.399 106.357 282.5Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M198.5 302C209.27 302 218 293.27 218 282.5C218 271.73 209.27 263 198.5 263C187.73 263 179 271.73 179 282.5C179 293.27 187.73 302 198.5 302ZM198.5 320C219.211 320 236 303.211 236 282.5C236 261.789 219.211 245 198.5 245C177.789 245 161 261.789 161 282.5C161 303.211 177.789 320 198.5 320Z" />
        <path d="M211.357 282.5C211.357 289.601 205.601 295.357 198.5 295.357C191.399 295.357 185.643 289.601 185.643 282.5C185.643 275.399 191.399 269.643 198.5 269.643C205.601 269.643 211.357 275.399 211.357 282.5Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M308.5 302C319.27 302 328 293.27 328 282.5C328 271.73 319.27 263 308.5 263C297.73 263 289 271.73 289 282.5C289 293.27 297.73 302 308.5 302ZM308.5 320C329.211 320 346 303.211 346 282.5C346 261.789 329.211 245 308.5 245C287.789 245 271 261.789 271 282.5C271 303.211 287.789 320 308.5 320Z" />
        <path d="M321.357 282.5C321.357 289.601 315.601 295.357 308.5 295.357C301.399 295.357 295.643 289.601 295.643 282.5C295.643 275.399 301.399 269.643 308.5 269.643C315.601 269.643 321.357 275.399 321.357 282.5Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M201 116C213.703 116 224 105.703 224 93C224 80.2975 213.703 70 201 70C188.297 70 178 80.2975 178 93C178 105.703 188.297 116 201 116ZM201 136C224.748 136 244 116.748 244 93C244 69.2518 224.748 50 201 50C177.252 50 158 69.2518 158 93C158 116.748 177.252 136 201 136Z" />
        <path d="M215.743 93C215.743 101.142 209.142 107.743 201 107.743C192.858 107.743 186.257 101.142 186.257 93C186.257 84.8577 192.858 78.2571 201 78.2571C209.142 78.2571 215.743 84.8577 215.743 93Z" />
    </svg>
</template>
