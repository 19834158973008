<template>
    <div class="info-cases">
        <div class="cases-list" name="list" tag="div" v-bind:style="battlesProcessStyle">

            <img v-for="(box, index) in boxes" v-bind:key="`${box._id}-${index}`" :src="box.image" v-bind:class="{'case-active': battlesGetRound === (index + 1)}" />

        </div>
    </div>
</template>

<script>
export default {
    name: 'BattlesGameHeaderBoxes',
    props: ['boxes', 'game'],
    computed: {
        battlesGetRound() {
          let round = 1;
          if (this.game !== null && this.game.bets[0].outcomes !== undefined && this.game.bets[0].outcomes.length >= 1) { round = this.game.bets[0].outcomes.length; }

          return round;
      },
      battlesProcessStyle() {
          let processDiff = (this.battlesGetRound - 1) * 52;
          let processStyle = { transform: 'translate(' + (-processDiff) + 'px, 0%)' };

          return processStyle;
      },
    },
}
</script>

<style scoped>
.info-cases {
    width: 100%;
    height: 56px;
    grid-column: 2 / 2;
    border-radius: 8px;
    background: #12161d;
    padding: 0px 10px;
    overflow: hidden;
}

.info-cases .cases-list {
    height: 100%;
    display: flex;
    align-items: center;
    transition: all .3s;
}

.info-cases .cases-list img {
    height: 40px;
    margin-right: 12px;
    filter: grayscale(100%);
    opacity: 0.5;
}

.info-cases .cases-list img.case-active {
    opacity: 1;
    filter: grayscale(0%);
}

@media only screen and (max-width: 700px) {
    .info-cases {
        grid-template-columns: 56px auto 223px;
        grid-template-rows: auto auto;
        grid-gap: 10px 30px
    }

    .info-cases .info-cases {
        grid-column: 1/4
    }

}
</style>