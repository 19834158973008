<template>
  <div class="app-loader">
    <div class="loader-inner">
      <video
        autoplay
        muted
        loop
        playsinline
        preload="metadata"
      >
        <source src="@/assets/img/logo/loading.mp4" type="video/mp4" />
        <source src="@/assets/img/logo/loading.webm" type="video/webm" />
      </video>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AppLoader'
  }
</script>

<style scoped>
  .app-loader {
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    background: #17191d;
    z-index: 100;
    opacity: 1;
  }

  .app-loader .loader-inner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .app-loader .loader-inner video {
    width: 500px;
  }
</style>