import { createApp } from 'vue';
import axios from 'axios';

import App from './App.vue';
import router from './router';
import store from './store';

import '@/assets/css/global.css';

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

createApp(App)
  .use(store)
  .use(router)
  .mount('#app');
