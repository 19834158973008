<template>
  <RouterLink class="cases-box-element" :to="`/cases/${box._id}`">
    <div class="element-image">
      <img :src="box.image" alt="box-image" />
    </div>
    <div class="element-name">{{box.name}}</div>
    <div class="element-amount">
      <img src="@/assets/img/icons/coin.png" alt="icons-image" />
      <div class="amount-value">
        <span>{{generalFormatAmount(box.amount).split('.')[0]}}</span>.{{
        generalFormatAmount(box.amount).split('.')[1]}}
      </div>
    </div>
  </RouterLink>
</template>

<script>
  import mixins from '@/mixins';

  export default {
    mixins: [
      mixins
    ],
    props: {
      box: Object
    }
  }
</script>

<style scoped>
  a.cases-box-element {
    width: calc(16.66% - 13.33px);
    display: flex;
    flex-direction: column;
    align-items: center;     
    padding: 16px 16px 16px 16px;
    border-radius: 18px;
    background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
  }

  a.cases-box-element:nth-child(6n) {
    margin-right: 0; 
  }

  a.cases-box-element .element-image {
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a.cases-box-element .element-image img {
    width: 145px;
    transition: transform 0.3s ease;
  }

  a.cases-box-element:hover .element-image img {
    transform: scale(1.06);
  }

  a.cases-box-element .element-name {
    margin-top: 18px;
    font-size: 15px;
    font-weight: 600;
    color: #767c8b;
  }

  a.cases-box-element .element-amount {
    width: 100%;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    border-radius: 5px;
    background: #212732;
  }

  a.cases-box-element .element-amount img {
    width: 21px;
    margin-right: 10px;
  }

  a.cases-box-element .amount-value {
    font-size: 12px;
    font-weight: 700;
    color: #bbbbbb;
  }

  a.cases-box-element .amount-value span {
    font-size: 15px;
    font-weight: 700;
    color: #ffffff;
  }

  @media only screen and (max-width: 1250px) {

    a.cases-box-element {
      width: calc(20% - 12.8px);       
    }

  }

  @media only screen and (max-width: 1000px) {

    a.cases-box-element {
      width: calc(25% - 12px);       
    }

  }

  @media only screen and (max-width: 750px) {

    a.cases-box-element {
      width: calc(33.33% - 10.66px);       
    }

  }

  @media only screen and (max-width: 550px) {

    a.cases-box-element {
      width: calc(50% - 8px);       
    }

  }
</style>