import socketIo from 'socket.io-client';

const state = {
  socketSendLoading: null,
  socketClient: socketIo(process.env.VUE_APP_SOCKET_URL, { 
    path: '/api/socket.io', 
    autoConnect: false, 
    reconnection: true, 
    reconnectionDelay: 5000, 
    transports: ['websocket']
  })
}

const getters = {
  socketSendLoading: state => state.socketSendLoading,
  socketClient: state => state.socketClient
}

const mutations = {
  socket_set_send_loading(state, value) {
    state.socketSendLoading = value;
  }
}

const actions = {
  socketSetSendLoading({ commit }, value) {
    commit('socket_set_send_loading', value);
  },
  socketConnectClient({ getters, dispatch }) {
    if(getters.socketClient.connected === true) { getters.socketClient.removeAllListeners(); }
    getters.socketClient.disconnect().connect();

    dispatch('socketListenClient');
  },
  socketDisconnectClient({ getters }) {
    getters.socketClient.removeAllListeners();
    getters.socketClient.disconnect();
  },
  socketListenClient({ getters, dispatch }) {
    getters.socketClient.on('init', (data) => { dispatch('generalSocketInit', data); dispatch('chatSocketInit', data); });
    getters.socketClient.on('general:online', (data) => { dispatch('generalSocketOnline', data); });
    getters.socketClient.on('general:setting', (data) => { dispatch('generalSocketSettings', data); });
    getters.socketClient.on('general:rain', (data) => { dispatch('generalSocketRain', data); });
    getters.socketClient.on('general:bet', (data) => { dispatch('generalSocketBet', data); });
    getters.socketClient.on('general:user', (data) => { dispatch('generalSocketUser', data); });
    getters.socketClient.on('general:chat:message', (data) => { dispatch('chatSocketMessage', data); });
    getters.socketClient.on('general:chat:tip', (data) => { dispatch('chatSocketTip', data); });
    getters.socketClient.on('general:chat:mute', (data) => { dispatch('chatSocketMute', data); });
    getters.socketClient.on('general:chat:remove', (data) => { dispatch('chatSocketRemove', data); });
    getters.socketClient.on('roulette:game', (data) => { dispatch('rouletteSocketGame', data); });
    getters.socketClient.on('roulette:bet', (data) => { dispatch('rouletteSocketBet', data); });
    getters.socketClient.on('battles:game', (data) => { dispatch('battlesSocketGame', data); });
    getters.socketClient.on('cashier:steam:transaction', (data) => { dispatch('steamSocketTransaction', data); });
    getters.socketClient.on('cashier:steam:withdrawItem', (data) => { dispatch('steamSocketWithdrawItem', data); });
    getters.socketClient.on('cashier:crypto:transaction', (data) => { dispatch('cryptoSocketTransaction', data); });
  },
  socketClientJoin({ getters, dispatch }, data) {
    getters.socketClient.emit('socket:join', data, (res) => {
      if(res.success === false) {
        dispatch('notificationShow', res.error);
      }
    });
  },
  socketClientLeave({ getters, dispatch }, data) {
    getters.socketClient.emit('socket:leave', data, (res) => {
      if(res.success === false) {
        dispatch('notificationShow', res.error);
      }
    });
  }
}

const socket = {
    state,
    mutations,
    actions,
    getters
}

export default socket;
