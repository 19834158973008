<template>
  <div :class="['battles-filter-mode', { 
    'mode-open': battlesDropdown 
  }]">
    <button @click="battlesSetDropdown(!battlesDropdown)" class="button-toggle">
      <div class="button-text">
        Players:
        <span>{{ mode }}</span>
      </div>
      <svg width="13" height="9" viewBox="0 0 13 9" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.2142 0.361328L6.5 5.07555L1.78577 0.361328L0 2.1471L6.5 8.6471L13 2.1471L11.2142 0.361328Z" />
      </svg>
    </button>
    <div class="mode-menu">
      <div class="menu-inner">
        <button @click="battlesSetMode('1v1')">1v1</button>
        <button @click="battlesSetMode('1v1v1')">1v1v1</button>
        <button @click="battlesSetMode('1v1v1v1')">1v1v1v1</button>
        <button @click="battlesSetMode('1v1v1v1v1v1')">1v1v1v1v1v1</button>
        <button @click="battlesSetMode('2v2')">2v2</button>
        <button @click="battlesSetMode('3v3')">3v3</button>
        <button @click="battlesSetMode('2v2v2')">2v2v2</button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      mode: String
    },
    data() {
      return {
        battlesDropdown: false
      }
    },
    methods: {
      battlesSetDropdown(value) {
        this.battlesDropdown = value;
      },
      battlesSetMode(value) {
        this.$emit('setMode', value);
        this.battlesSetDropdown(false);
      },
      battlesHandleOutside(event) {
        if(!this.$el.contains(event.target) && this.battlesDropdown) {
          this.battlesSetDropdown(false);
        }
      }
    },
    mounted() {
      document.addEventListener('click', this.battlesHandleOutside);
    },
    beforeUnmount() {
      document.removeEventListener('click', this.battlesHandleOutside);
    }
  }
</script>

<style scoped>
  .battles-filter-mode {
    width: 210px;
    position: relative;
    margin-right: 12px;
    z-index: 10;
  }

  .battles-filter-mode button.button-toggle {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    border-radius: 5px;
    background: #212732;
    box-shadow: 0px 2px 0px 0px #191e27;
  }

  .battles-filter-mode  button.button-toggle .button-text {
    font-size: 14px;
    font-weight: 700;
    color: #767c8b;
  }

  .battles-filter-mode  button.button-toggle .button-text span {
    margin-left: 8px;
    color: #ffffff;
  }

  .battles-filter-mode button.button-toggle svg {
    fill: #626c7e;
    transition: all 0.3s ease;
  }

  .battles-filter-mode.mode-open button.button-toggle svg {
    transform: rotate(180deg);
  }

  .battles-filter-mode .mode-menu {
    width: 100%;
    height: 0;
    position: absolute;
    top: 52px;
    left: 0;
    overflow: hidden;
    transition: height 0.2s ease;
  }

  .battles-filter-mode.mode-open .mode-menu {
    height: 384px;
  }

  .battles-filter-mode .menu-inner {
    width: 100%;
    padding: 3px;
    border-radius: 5px;
    background: #212732;
    border: 1px solid #191e27;
  }

  .battles-filter-mode .menu-inner button {
    width: 100%;
    height: 47px;
    display: flex;
    align-items: center;
    padding: 0 14px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 700;
    color: #ffffff;
    transition: 0.3s ease;
  }

  .battles-filter-mode .menu-inner button:hover {
    background: #191d26;
  }

  @media only screen and (max-width: 600px) {

    .battles-filter-mode {
      width: 100%;
      margin-right: 0;
      margin-bottom: 8px;
    }

  }
</style>