<template>
  <div class="skeleton-loader"></div>
</template>

<style scoped>
  .skeleton-loader {
    position: relative;
    overflow: hidden;
  }

  .skeleton-loader:after {
    width: 100%;
    height: 100%;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    animation-name: loading_animation;
    animation-duration: 3s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0) 100%);
  }

  @keyframes loading_animation {
    0% { transform: translateX(-100%); }
    50% { transform: translateX(100%); }
    100% { transform: translateX(100%); }
  }
</style>