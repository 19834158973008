<template>
  <div class="terms">
    <div class="terms-header">
        <div class="header-title">TERMS OF SERVICE</div>
        <div class="header-support">
            Cant find anything related to you problem?
            <RouterLink to="/support">Contact Support</RouterLink>
        </div>
    </div>
    <div class="terms-content">

      <div class="content-section">
        <div class="section-text">
          Date of Last Revision: February 24th, 2025<br />
        </div>
      </div>

      <div class="content-section">
        <div class="section-title">1. Introduction</div>
        <div class="section-text">Welcome to RustRoll.com, a sweepstakes platform. By accessing and using RustRoll.com, you signify your acceptance and agreement to be bound by these Terms of Service and any other relevant policies, conditions, and notices pertaining to this site.</div>
      </div>

      <div class="content-section">
        <div class="section-title">2. Eligibility & Acceptance</div>
        <div class="section-text">
          By accessing and utilizing RustRoll.com, you represent and warrant to us that:<br />
          a. You are at least eighteen (18) years old or the age of majority in your jurisdiction, whichever is greater.<br />
          b. You possess the legal capacity and authority to enter into these Terms of Service.<br />
          c. You will not use this website in any manner that contravenes these Terms or any applicable law.<br />
          d. You are accessing and using the Services of your own free will, for personal non-commercial purposes, and in a state that allows you to make decisions responsibly.<br />
          e. You are not a resident of Washington.
        </div>
      </div>

      <div class="content-section">
        <div class="section-title">3. User Accounts & Security</div>
        <div class="section-text">
          a. Registration: Users must register through the secured Steam login provided by Valve Corporation.<br />
          b. Responsibility: You are entirely responsible for maintaining the confidentiality of your password and account. Furthermore, you are entirely responsible for any and all activities that occur under your account.<br />
          c. Unauthorized Use: You must promptly notify us of any unauthorized use of your account or any other breach of security.
        </div>
      </div>

      <div class="content-section">
        <div class="section-title">4. Account Policies</div>
        <div class="section-text">
          a. Non-Transferability: Your account, any accrued XP rewards, or stats are strictly non-transferable.<br />
          b. Restrictions: Possession of multiple accounts for the purpose of manipulating or abusing website mechanics is strictly prohibited.<br />
          c. User Responsibility: You are solely responsible for all actions performed from your account. This includes, but is not limited to, gameplay, chat interactions, and transactional activities.<br />
          d. Account Security: Ensuring the security of your account and device against unauthorized access lies within your purview.
        </div>
      </div>

      <div class="content-section">
          <div class="section-title">5. Experience Points (XP) Rewards</div>
          <div class="section-text">
            a. Active players are rewarded with XP based on gameplay.<br />
            b. The ratio at which XP is awarded can be adjusted at our discretion at any time.<br />
            c. XP is strictly non-transferable and can be used by players to level up, subsequently unlocking enhanced rewards.
          </div>
      </div>

      <div class="content-section">
        <div class="section-title">6. Account Termination</div>
        <div class="section-text">
          a. Company's Rights: We reserve the exclusive right to terminate or suspend your account at our discretion without any prior notice.<br />
          b. User Misconduct: Accounts may be terminated due to violations of these Terms, or if the user is deemed malicious or harmful.<br />
          c. Funds: In cases of account termination due to user misconduct, only funds acquired legitimately can be withdrawn.<br />
          d. User Request: If you wish to close your account, contact our live support. Requests will be processed within 30 days.
        </div>
      </div>

      <div class="content-section">
        <div class="section-title">7. User Content</div>
        <div class="section-text">
          Definition: For the purposes of these Terms, "User Content" refers to all content submitted, transmitted, posted, or uploaded by users on RustRoll.com, including but not limited to, usernames and chat messages in the on-site chat.<br />
          <br />
          Ownership: RustRoll.com does not claim ownership of any User Content provided, uploaded, or submitted by users. Users retain all rights to their User Content and are solely responsible for any consequences resulting from their submission or posting.<br />
          <br />
          Responsibility: While users retain ownership of their User Content, they are required to ensure that such content does not violate any laws, infringe upon the rights of others, or promote harmful activities. RustRoll.com is not responsible for and does not endorse any User Content, views, or opinions expressed therein. However, we reserve the right to monitor, remove, or restrict any User Content that violates these Terms, is unlawful, or is otherwise deemed harmful at our sole discretion.<br />
          <br />
          Usage: By submitting, transmitting, posting, or uploading User Content, users grant RustRoll.com a non-exclusive, royalty-free, worldwide license to use, host, store, reproduce, modify, adapt, and display such content solely for the purpose of operating and providing the services of RustRoll.com.
        </div>
      </div>

      <div class="content-section">
        <div class="section-title">8. On-Site Balances</div>
        <div class="section-text">
          a. Funds added to your RustRoll.com account yield on-site credits with no real-world monetary value.<br />
          b. Balances can only be utilized for gameplay and can be withdrawn using our available methods.<br />
          c. Deposits are facilitated by third-party providers. Opportunities to earn free on-site credits are available.
        </div>
      </div>

      <div class="content-section">
        <div class="section-title">9. Fees and Payments</div>
        <div class="section-text">
          FIAT Deposits: For deposits made using FIAT currency, the associated payment fees are determined and displayed by the corresponding payment processor at the time of transaction. RustRoll.com is not responsible for these fees, and users are advised to review them before initiating a deposit.<br />
          <br />
          Crypto Deposits: Deposits made using cryptocurrency are subject to network fees. These fees are not set or collected by RustRoll.com but are inherent to the respective cryptocurrency's network. Users are responsible for covering these network fees, and they should be aware of them prior to confirming a transaction.<br />
          <br />
          Rust Item Deposits: There are no fees associated with deposits made using Rust in-game items on RustRoll.com. Users can deposit these items freely, without incurring additional charges from our platform.
        </div>
      </div>

      <div class="content-section">
        <div class="section-title">10. Item Pricing</div>
        <div class="section-text">
          Price Fluctuation: Prices of Rust in-game items can be volatile and may fluctuate due to a variety of factors, including market demand, rarity, and external platform valuations.<br />
          <br />
          No Liability for Inaccuracies: RustRoll.com strives to provide the most up-to-date and accurate item prices for deposit rates. However, we cannot guarantee the accuracy, completeness, or timeliness of these rates at any given moment.<br />
          <br />
          User Discretion: Users are advised to exercise discretion and conduct their own research or analysis when assessing the value of their Rust items. Any transactions made based on the displayed rates on RustRoll.com are done at the user's risk and discretion.<br />
          <br />
          Updates and Revisions: RustRoll.com reserves the right to update or revise item deposit rates without prior notice.
        </div>
      </div>

      <div class="content-section">
        <div class="section-title">11. Refunds</div>
        <div class="section-text">
          All deposits to RustRoll.com are final. We do not process refunds for any reason.
        </div>
      </div>

      <div class="content-section">
        <div class="section-title">12. Gameplay</div>
        <div class="section-text">
          a. RustRoll.com provides various sweepstakes game modes, which are free to enter.<br />
          b. While users can deposit funds using multiple methods, free credits can be claimed through various offers.<br />
          c. We reserve the right to modify game modes, sweepstakes, or reward structures without prior notice.<br />
          d. Technical issues that may affect gameplay or outcomes give us the right to disqualify affected parties.
        </div>
      </div>

      <div class="content-section">
        <div class="section-title">13. Technical Issues & Liability</div>
        <div class="section-text">
          a. Visual or auditory glitches do not entitle users to refunds. Game outcomes are determined by a backend system ensuring fairness.<br />
          b. RustRoll.com assumes no liability for user actions stemming from personal technical issues.
        </div>
      </div>

      <div class="content-section">
        <div class="section-title">14. Governing Law</div>
        <div class="section-text">
          These Terms of Service, and any dispute arising out of or related to them, shall be governed by and construed in accordance with the laws of Belize.
        </div>
      </div>

      <div class="content-section">
        <div class="section-title">15. Affiliation Disclaimer</div>
        <div class="section-text">
          RustRoll.com operates independently and is not affiliated with Valve Corporation or Facepunch Studios.
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    metaInfo: {
      title: 'Terms - RustRoll'
    }
  }
</script>

<style scoped>
  .terms {
    width: 100%;
    min-height: calc(100vh - 112px);
    padding: 40px;
  }

  .terms .terms-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    border-bottom: 1px solid #20252D;
  }

  .terms .header-title {
    font-size: 24px;
    font-weight: 700;
    color: #596076;
  }

  .terms .header-support {
    display: flex;
    font-size: 14px;
    font-weight: 500;
    color: #596076;
  }

  .terms .header-support a {
    margin-left: 5px;
    font-size: 14px;
    font-weight: 600;
    color: #fd3b31;
  }

  .terms .terms-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
  }

  .terms .content-section {
    width: 100%;
    margin-top: 24px;
  }

  .terms .content-section:first-of-type {
    margin-top: 0;
  }

  .terms .section-title {
    font-size: 18px;
    font-weight: 600;
    color: #596076;
  }

  .terms .section-text {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
  }

  @media only screen and (max-width: 1650px) {

    .terms {
      padding: 40px 15px 40px 15px;
    }

  }

  @media only screen and (max-width: 1000px) {

    .terms .terms-header {
      flex-direction: column;
      align-items: flex-start;
    }

    .terms .header-support {
      margin-top: 12px;
    }

  }

  @media only screen and (max-width: 475px) {

    .terms .header-support {
      flex-direction: column;
    }

    .terms .header-support a {
      margin-left: 0;
    }

  }
</style>
