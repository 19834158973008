const state = {
  generalOnline: 0,
  generalSettings: null,
  generalTimeDiff: null,
  generalRainData: null,
  generalBets: {
    bets: null,
    loading: false
  }
}

const getters = {
  generalOnline: state => state.generalOnline,
  generalSettings: state => state.generalSettings,
  generalTimeDiff: state => state.generalTimeDiff,
  generalRainData: state => state.generalRainData,
  generalBets: state => state.generalBets
}

const mutations = {
  general_set_online(state, online) {
    state.generalOnline = online;
  },
  general_set_settings(state, settings) {
    state.generalSettings = settings;
  },
  general_set_time_diff(state, time) {
    state.generalTimeDiff = time - Date.now();
  },
  general_set_rain(state, rain) {
    state.generalRainData = rain;
  },
  general_set_bets(state, bets) {
    state.generalBets.bets = bets;
  },
  general_add_bets_all(state, bet) {
    state.generalBets.bets.all.unshift(bet);
    state.generalBets.bets.all.sort((a, b) => { return new Date(b.createdAt) - new Date(a.createdAt); });
  },
  general_remove_bets_all(state) {
    state.generalBets.bets.all.pop();
  },
  general_add_bets_high(state, bet) {
    state.generalBets.bets.high.unshift(bet);
    state.generalBets.bets.high.sort((a, b) => { return new Date(b.createdAt) - new Date(a.createdAt); });
  },
  general_remove_bets_high(state) {
    state.generalBets.bets.high.pop();
  },
  general_add_bets_my(state, bet) {
    state.generalBets.bets.my.unshift(bet);
    state.generalBets.bets.my.sort((a, b) => { return new Date(b.createdAt) - new Date(a.createdAt); });
  },
  general_remove_bets_my(state) {
    state.generalBets.bets.my.pop();
  },
  general_set_bets_loading(state, status) {
    state.generalBets.loading = status;
  }
}

const actions = {
  generalSocketInit({ commit }, data) {
    commit('general_set_online', data.online);
    commit('general_set_settings', data.setting);
    commit('general_set_time_diff', data.time);
    commit('general_set_rain', data.rain);
  },
  generalSocketOnline({ commit }, data) {
    commit('general_set_online', data.online);
  },
  generalSocketUser({ getters, commit }, data) {
    if(new Date(getters.authUser.user.updatedAt).getTime() <= new Date(data.user.updatedAt).getTime()) {
      commit('auth_update_user', data.user);
    }
  },
  generalSocketSettings({ commit }, data) {
    commit('general_set_settings', data.setting);
  },
  generalSocketRain({ commit }, data) {
    commit('general_set_rain', data.rain);
  },
  generalSocketBet({ getters, commit }, data) {
    if(getters.generalBets.bets !== null) {
      commit('general_add_bets_all', data.bet);
      if(getters.generalBets.bets.all.length >= 8) { commit('general_remove_bets_all'); }

      if(data.bet.amount >= 100000) {
        commit('general_add_bets_high', data.bet);
        if(getters.generalBets.bets.high.length >= 8) { commit('general_remove_bets_high'); }
      }

      if(
        getters.authUser.user !== null && 
        data.bet.user !== null && 
        getters.authUser.user._id === data.bet.user._id
      ) {
        commit('general_add_bets_my', data.bet);
        if(getters.generalBets.bets.my.length >= 8) { commit('general_remove_bets_my'); }
      }
    }
  },
  generalGetBetsDataSocket({ getters, commit, dispatch }, data) {
    commit('general_set_bets_loading', true);

    getters.socketClient.emit('general:bet:getData', data, (res) => {
      if(res.success === true) {
        commit('general_set_bets', res.bets);
      } else {
        dispatch('notificationShow', res.error);
      }

      commit('general_set_bets_loading', false);
    });
  },
  generalSendAgreeSocket({ getters, commit, dispatch }, data) {
    commit('socket_set_send_loading', 'GeneralAgree');

    getters.socketClient.emit('general:user:sendAgree', data, (res) => {
      if(res.success === true) {
        commit('auth_set_user_agree');
      } else {
        dispatch('notificationShow', res.error);
      }

      commit('socket_set_send_loading', null);
    });
  },
  generalSendRainJoinSocket({ getters, commit, dispatch }, data) {
    commit('socket_set_send_loading', 'GeneralRainJoin');

    getters.socketClient.emit('general:rain:join', data, (res) => {
      if(!res.success) {
        dispatch('notificationShow', res.error);
      }

      commit('socket_set_send_loading', null);
    });
  },
  generalSendRainTipSocket({ getters, commit, dispatch }, data) {
      commit('socket_set_send_loading', 'GeneralRainTip');

      getters.socketClient.emit('general:rain:tip', data, (res) => {
        if(res.success === true) {
          dispatch('modalsSetShow', null);
        } else {
          dispatch('notificationShow', res.error);
        }

        commit('socket_set_send_loading', null);
      });
  }
}

const general = {
  state,
  mutations,
  actions,
  getters
}

export default general;
