<template>
    <div class="element-cases">
        <div class="cases-inner" name="list" tag="div" v-bind:style="battlesProcessStyle">

            <img v-for="(box, index) in boxes" v-bind:key="`${box._id}-${index}`" :src="box.image" />

        </div>
    </div>
</template>

<script>
export default {
    name: 'BattlesGameOverViewBoxes',
    props: ['boxes', 'game', 'boxWapperWidth'],
    data() {
        return {
            wapperWidth: 0,
        }
    },
    computed: {
        battlesGetRound() {
          let round = 1;
          if (this.game !== null && this.game.bets[0].outcomes !== undefined && this.game.bets[0].outcomes.length >= 1) { round = this.game.bets[0].outcomes.length; }

          return round;
      },
      battlesProcessStyle() {
          let processDiff = 0;
          if (this.wapperWidth > 0 && ((this.wapperWidth - 98 * 3) < 98 * (this.battlesGetRound - 1))) {
              processDiff = Math.ceil((this.battlesGetRound - 1) - ((this.wapperWidth - 98 * 2) / 98) ) * 98;
          }
          let processStyle = { transform: 'translate(' + (-processDiff) + 'px, 0%)' };

          return processStyle;
      },
    },
    watch: {
        boxWapperWidth: {
            handler(newValue) {
                this.wapperWidth = newValue;
            },
            immediate: true,
        },
  },
}
</script>

<style scoped>
.battles-game-element .element-cases {
    width: 100%;
    border-radius: 8px;
    overflow: hidden
}

.battles-game-element .cases-inner {
    height: 100%;
    display: flex;
    align-items: center;
    transition: all .3s;
}

.list-enter-active, .list-leave-active {
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.list-enter-from, 
.list-leave-to {
    transform: translateX(-20px);
}

.battles-game-element .cases-inner img {
    width: 78px;
    margin-right: 20px
}

@media only screen and (max-width: 700px) {
    .battles-game-element {
        grid-template-columns: 56px auto 223px;
        grid-template-rows: auto auto;
        grid-gap: 10px 30px
    }

    .battles-game-element .element-cases {
        grid-column: 1/4
    }

}
</style>