import router from '../../../router';

const state = {
  battlesFilterSortCases: 'highest',
  battlesFilterSearch: '',
  battlesFilterPrice: 'ALL',
  battlesSelected: [],
  battlesData: {
    games: [],
    history: [],
    loading: false
  },
  battlesBoxesData: {
    boxes: [],
    loading: false
  },
  battlesGameData: {
    game: null,
    loading: false
  }
}

const getters = {
  battlesFilterSortCases: state => state.battlesFilterSortCases,
  battlesFilterSearch: state => state.battlesFilterSearch,
  battlesFilterPrice: state => state.battlesFilterPrice,
  battlesSelected: state => state.battlesSelected,
  battlesData: state => state.battlesData,
  battlesBoxesData: state => state.battlesBoxesData,
  battlesGameData: state => state.battlesGameData
}

const mutations = {
  battles_set_filter_sort_cases(state, value) {
    state.battlesFilterSortCases = value;
  },
  battles_set_filter_search(state, value) {
    state.battlesFilterSearch = value;
  },
  battles_set_filter_price(state, value) {
    state.battlesFilterPrice = value;
  },
  battles_set_boxes(state, boxes) {
    state.battlesBoxes = boxes;
  },
  battles_add_selected(state, box) {
    state.battlesSelected.push(box);
  },
  battles_remove_selected(state, index) {
    state.battlesSelected.splice(index, 1);
  },
  battles_empty_selected(state) {
    state.battlesSelected = [];
  },
  battles_set_data_games(state, games) {
    state.battlesData.games = games;
  },
  battles_add_data_games(state, game) {
    state.battlesData.games.push(game);
  },
  battles_update_data_games(state, game) {
    state.battlesData.games.splice(state.battlesData.games.findIndex((element) => element._id === game._id), 1, game);
  },
  battles_remove_data_games(state, game) {
    state.battlesData.games.splice(state.battlesData.games.findIndex((element) => element._id === game._id), 1);
  },
  battles_set_data_history(state, history) {
    state.battlesData.history = history;
  },
  battles_add_data_history(state, game) {
    state.battlesData.history.unshift(game);
  },
  battles_remove_last_data_history(state) {
    state.battlesData.history.pop();
  },
  battles_set_data_loading(state, status) {
    state.battlesData.loading = status;
  },
  battles_set_boxes_data_boxes(state, boxes) {
    state.battlesBoxesData.boxes = boxes;
  },
  battles_set_boxes_data_loading(state, status) {
    state.battlesBoxesData.loading = status;
  },
  battles_set_game_data_game(state, game) {
    state.battlesGameData.game = game;
  },
  battles_set_game_data_loading(state, status) {
    state.battlesGameData.loading = status;
  }
}

const actions = {
  battlesSetFilterSortCases({ commit }, value) {
    commit('battles_set_filter_sort_cases', value);
  },
  battlesSetFilterSearch({ commit }, value) {
    commit('battles_set_filter_search', value);
  },
  battlesSetFilterPrice({ commit }, value) {
    commit('battles_set_filter_price', value);
  },
  battlesResetFilter({ commit }) {
    commit('battles_set_filter_search', '');
    commit('battles_empty_selected');
  },
  battlesAddSelected({ commit }, item) {
    commit('battles_add_selected', item);
  },
  battlesRemoveSelected({ getters, commit }, box) {
    const index = getters.battlesSelected.findIndex((element) => element._id === box._id);
    if(index !== -1) { commit('battles_remove_selected', index); }
  },
  battlesEmptySelected({ commit }) {
    commit('battles_empty_selected');
  },
  battlesSetGameData({ commit }, game) {
    commit('battles_set_game_data_game', game);
  },
  battlesSocketGame({ getters, commit }, data) {
    if(!data.game.options.private) {
      if(data.game.state !== 'completed') {
        const exists = getters.battlesData.games.some((element) => element._id === data.game._id);
        commit(exists ? 'battles_update_data_games' : 'battles_add_data_games', data.game);
      } else {
        commit('battles_remove_data_games', data.game);
        commit('battles_add_data_history', data.game);
        if(getters.battlesData.history.length > 4) { commit('battles_remove_last_data_history'); }
      }
    }

    if(getters.battlesGameData.game && getters.battlesGameData.game._id === data.game._id) { 
      commit('battles_set_game_data_game', { ...data.game, boxes: getters.battlesGameData.game.boxes }); 
    }
  },
  async battlesGetDataSocket({ getters, commit, dispatch }, data) {
    commit('battles_set_data_loading', true);

    try {
      const res = await getters.socketClient
        .emitWithAck('battles:getData', data);

      if(res.success) {
        commit('battles_set_data_games', res.games);
        commit('battles_set_data_history', res.history);
      } else {
        dispatch('notificationShow', res.error);
      }
    } finally {
      commit('battles_set_data_loading', false);
    }
  },
  async battlesGetBoxesDataSocket({ getters, commit, dispatch }, data) {
    commit('battles_set_boxes_data_loading', true);

    try {
      const res = await getters.socketClient
        .emitWithAck('battles:getBoxesData', data);

      if(res.success) {
        commit('battles_set_boxes_data_boxes', res.boxes);
      } else {
        dispatch('notificationShow', res.error);
      }
    } finally {
      commit('battles_set_boxes_data_loading', false);
    }
  },
  async battlesGetGameDataSocket({ getters, commit, dispatch }, data) {
    commit('battles_set_game_data_loading', true);

    try {
      const res = await getters.socketClient
        .emitWithAck('battles:getGameData', data);

      if(res.success) {
        commit('battles_set_game_data_game', res.game);
      } else {
        dispatch('notificationShow', res.error);
      }
    } finally {
      commit('battles_set_game_data_loading', false);
    }
  },
  async battlesSendCreateSocket({ getters, commit, dispatch }, data) {
    commit('socket_set_send_loading', 'BattlesCreate');

    try {
      const res = await getters.socketClient
        .emitWithAck('battles:sendCreate', data);

      if(res.success) {
        commit('auth_update_user', res.user);
        router.push({ path: '/battles/' + res.game._id });
      } else {
        dispatch('notificationShow', res.error);
      }
    } finally {
      commit('socket_set_send_loading', null);
    }
  },
  async battlesSendBotSocket({ getters, commit, dispatch }, data) {
    commit('socket_set_send_loading', 'BattlesBot');

    try {
      const res = await getters.socketClient
        .emitWithAck('battles:sendBot', data);

      if(!res.success) {
        dispatch('notificationShow', res.error);
      }
    } finally {
      commit('socket_set_send_loading', null);
    }
  },
  async battlesSendJoinSocket({ getters, commit, dispatch }, data) {
    commit('socket_set_send_loading', 'BattlesJoin');

    try {
      const res = await getters.socketClient
        .emitWithAck('battles:sendJoin', data);

      if(res.success) {
        commit('auth_update_user', res.user);
        router.push({ path: '/battles/' + data.gameId });
      } else {
        dispatch('notificationShow', res.error);
      }
    } finally {
      commit('socket_set_send_loading', null);
    }
  }
}

const battles = {
    state,
    mutations,
    actions,
    getters
}

export default battles;