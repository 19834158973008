<template>
  <div :class="['navbar-filter-games', { 'games-open': navbarDropdown }]">
    <button @click="navbarToggleDropdown(false)" class="button-toggle">
      <IconGames class="icon-games" />
      GAMES
      <svg class="icon-state" width="13" height="9" viewBox="0 0 13 9" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.2142 0.361328L6.5 5.07555L1.78577 0.361328L0 2.1471L6.5 8.6471L13 2.1471L11.2142 0.361328Z" />
      </svg>
    </button>
    <div class="games-menu">
      <div class="menu-inner">
        <RouterLink @click="navbarToggleDropdown(true)" class="link-game" to="/battles">
          <IconBattles />
          BATTLES
        </RouterLink>
        <RouterLink @click="navbarToggleDropdown(true)" class="link-game" to="/roulette">
          <IconRoulette />
          ROULETTE
        </RouterLink>
        <RouterLink @click="navbarToggleDropdown(true)" class="link-game" to="/cases">
          <IconCases />
          CASES
        </RouterLink>
        <RouterLink @click="navbarToggleDropdown(true)" class="link-game" to="/blackjack">
          <IconBlackjack />
          BLACKJACK
        </RouterLink>
        <RouterLink @click="navbarToggleDropdown(true)" class="link-game" to="/mines">
          <IconMines />
          MINES
        </RouterLink>
        <RouterLink @click="navbarToggleDropdown(true)" class="link-game" to="/towers">
          <IconTowers />
          TOWERS
        </RouterLink>
        <RouterLink @click="navbarToggleDropdown(true)" class="link-game" to="/upgrader">
          <IconUpgrader />
          UPGRADER
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
  import IconGames from '@/components/icons/IconGames';
  import IconBattles from '@/components/icons/IconBattles';
  import IconRoulette from '@/components/icons/IconRoulette';
  import IconCases from '@/components/icons/IconCases';
  import IconBlackjack from '@/components/icons/IconBlackjack';
  import IconMines from '@/components/icons/IconMines';
  import IconTowers from '@/components/icons/IconTowers';
  import IconUpgrader from '@/components/icons/IconUpgrader';

  export default {
    components: {
      IconGames,
      IconBattles,
      IconRoulette,
      IconCases,
      IconBlackjack,
      IconMines,
      IconTowers,
      IconUpgrader
    },
    data: function() {
      return {
        navbarDropdown: false
      }
    },
    methods: {
      navbarToggleDropdown(navbar) {
        if(navbar === true) { this.$parent.navbarSetMobile(false); }
        this.navbarDropdown = !this.navbarDropdown;
      },
      navbarHandleOutside(event) {
        if(!this.$el.contains(event.target) && this.navbarDropdown) {
          this.navbarToggleDropdown(false);
        }
      }
    },
    mounted() {
      document.addEventListener('click', this.navbarHandleOutside);
    },
    beforeUnmount() {
      document.removeEventListener('click', this.navbarHandleOutside);
    }
  }
</script>

<style scoped>
  .navbar-filter-games {
    width: 210px;
    position: relative;
    z-index: 10;
  }

  .navbar-filter-games button.button-toggle {
    width: 100%;
    height: 39px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 700;
    color: #767c8b;
    background: #212732;
    box-shadow: 0px 2px 0px 0px #191e27;
    transition: all 0.3s ease;
  }

  .navbar-filter-games button.button-toggle:hover {
    color: #ffffff;
  }

  .navbar-filter-games.games-open button.button-toggle {
    color: #fd3b31;
    background: #252129;
    box-shadow: 0px 0px 0px 2px #59272b;
  }

  .navbar-filter-games button.button-toggle svg {
    fill: #626c7e;
    transition: all 0.3s ease;
  }

  .navbar-filter-games button.button-toggle svg.icon-games {
    margin-right: 10px;
  }

  .navbar-filter-games button.button-toggle svg.icon-state {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translate(0, -50%);
  }

  .navbar-filter-games.games-open button.button-toggle svg {
    fill: #fd3b31;
  }

  .navbar-filter-games.games-open button.button-toggle svg.icon-state {
    transform: translate(0, -50%) rotate(180deg);
  }

  .navbar-filter-games .games-menu {
    width: 100%;
    height: 0;
    position: absolute;
    top: 52px;
    left: 0;
    overflow: hidden;
    transition: height 0.2s ease;
  }

  .navbar-filter-games.games-open .games-menu {
    height: 337px;
  }

  .navbar-filter-games .menu-inner {
    width: 100%;
    padding: 3px;
    border-radius: 5px;
    background: #212732;
    border: 1px solid #191e27;
  }

  .navbar-filter-games .menu-inner a {
    width: 100%;
    height: 47px;
    display: flex;
    align-items: center;
    padding: 0 14px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 700;
    color: #ffffff;
    transition: 0.3s ease;
  }

  .navbar-filter-games .menu-inner a:hover {
    background: #191d26;
  }

  .navbar-filter-games .menu-inner a svg {
    margin-right: 12px;
    fill: #767c8b;
  }

  @media only screen and (max-width: 1300px) {

    .navbar-filter-games  {
      width: 100%;
      margin-top: 10px;
    }

    .content-links .navbar-filter-games {
      display: none;
    }

  }
</style>