<template>
  <div class="battles-reel">
    <div v-for="(item, index) in reel" :key="index" :class="['reel-element', { 
      'element-active': index === pos 
    }, 'rarity-' + item.item.color]">
      <div class="element-image">
          <div class="element-rarity">
              <img v-bind:src="require(`@/assets/img/cases/rusty-${item.item.color}.webp`)" />
          </div>
          <img class="item-image" v-bind:src="item.item.image" />
      </div>
      <Transition name="slide-fade">
        <div v-if="index === 60 && running === false" class="element-info">
          <span>{{item.item.name}}</span>
          <div class="info-amount">
            <img src="@/assets/img/icons/coin.png" alt="coins-image" />
            <div class="amount-value">
              <span>{{ generalFormatAmount(item.item.amountFixed).split('.')[0] }}</span>.
              {{ generalFormatAmount(item.item.amountFixed).split('.')[1] }}
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
  import mixins from '@/mixins';

  export default {
    mixins: [
      mixins
    ],
    props: {
      reel: Array,
      pos: Number,
      running: Boolean
    }
  }
</script>

<style scoped>
  .battles-reel {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .battles-reel .reel-element {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    opacity: 0.25;
  }

  .battles-reel .reel-element:last-child {
    margin-bottom: 0;
  }
  .battles-reel .reel-element.element-active {
    opacity: 1;
  }

  
  .battles-reel .reel-element .element-image {
    width: 105px;
    height: fit-content;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    position: relative;
  }

  .battles-reel .reel-element .element-image .element-rarity {
    width: 105px;
    height: 105px;
    z-index: -1;
  }

  .battles-reel .reel-element .element-image .element-rarity::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      filter: blur(20px);
      background: radial-gradient(50% 50% at 50% 50%, rgba(150, 150, 150, .3) 0%, rgba(74, 74, 74, 0) 90%);
  }

  .battles-reel .reel-element.rarity-gold .element-image .element-rarity::before {
      background: radial-gradient(50% 50% at 50% 50%, rgba(231, 212, 39, 0.3) 0%, rgba(74, 34, 34, 0) 90%);
  }

  .battles-reel .reel-element.rarity-red .element-image .element-rarity::before {
      background: radial-gradient(50% 50% at 50% 50%, rgba(231, 39, 39, 0.3) 0%, rgba(128, 34, 42, 0) 90%);
  }

  .battles-reel .reel-element.rarity-purple .element-image .element-rarity::before {
      background: radial-gradient(50% 50% at 50% 50%, rgba(172, 39, 231, .3) 0%, rgba(94, 34, 128, 0) 90%);
  }

  .battles-reel .reel-element.rarity-blue .element-image .element-rarity::before {
      background: radial-gradient(50% 50% at 50% 50%, rgba(39, 112, 231, .3) 0%, rgba(34, 74, 128, 0) 90%);
  }
  .battles-reel .reel-element .element-image .element-rarity img {
      width: 100%;
      height: 100%;
      opacity: 0.5;
  }
  .battles-reel .reel-element .element-image img.item-image {
      width: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
  }
  
  .battles-reel .reel-element.element-active .element-image {
    transform: scale(1.15);
  }

  .battles-reel .element-info {
    max-width: calc(100% - 115px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
  }

  .battles-reel .element-info.slide-fade-enter-active {
    transition: all 0.3s ease;
  }

  .battles-reel .element-info.slide-fade-enter-from {
    transform: translateX(-15px);
    opacity: 0;
  }

  .battles-reel .element-info span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    color: #596076;
  }

  .battles-reel .info-amount {
    display: flex;
    align-items: center;
    margin-top: 3px;
  }

  .battles-reel .info-amount img {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }

  .battles-reel .amount-value {
    font-size: 11px;
    font-weight: 600;
    color: #bbbfd0;
  }

  .battles-reel .amount-value span {
    font-size: 14px;
    font-weight: 800;
    color: #ffffff;
  }
</style>