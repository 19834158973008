<template>
  <div :class="['cases-item-element', `rarity-${casesPercent(item)}`]">
    <div class="element-percentage">
      {{(item.tickets / 100000 * 100).toFixed(2)}}%
    </div>
    <div class="element-image">
      <div class="element-rarity">
        <img :src="require(`@/assets/img/cases/rusty-${casesPercent(item)}.webp`)" alt="item-background" />
      </div>
      <img :src="item.item.image" alt="item-image" />
    </div>
    <div class="element-name">{{item.item.name}}</div>
    <div class="element-price">
      <img src="@/assets/img/icons/coin.png" alt="coins-image" />
      <div class="price-amount">
        <span>{{generalFormatAmount(item.item.amountFixed).split('.')[0]}}</span>.{{
        generalFormatAmount(item.item.amountFixed).split('.')[1]}}
      </div>
    </div>
  </div>
</template>

<script>
  import mixins from '@/mixins';

  export default {
    mixins: [
      mixins
    ],
    props: {
      item: Object,
      box: Object
    },
    methods: {
      casesPercent(item) {
        let color = 'gold';

        if(item.item.amountFixed < this.box.amount * 0.25) {
          color = 'grey'
        } else if(item.item.amountFixed < this.box.amount * 1) {
          color = 'blue';
        } else if(item.item.amountFixed < this.box.amount * 2) {
          color = 'purple';
        } else if(item.item.amountFixed < this.box.amount * 3) {
          color = 'red';
        }

        return color;
      }
    }
  }
</script>

<style scoped>
  .cases-item-element {
    width: calc(16.66% - 8.33px);
    height: 218px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 33px 20px 16px 20px;
    border-radius: 18px;
    background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
  }

  .cases-item-element:nth-child(6n) {
    margin-right: 0;
  }

  .cases-item-element .element-percentage {
    position: absolute;
    top: 10px;
    right: 14px;
    font-size: 13px;
    font-weight: 700;
    color: #596076;
  }

  .cases-item-element .element-image {
    width: 108px;
    height: 108px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 42px;
    background: #1e222e;
    z-index: 0;
  }
  
  .cases-item-element .element-image img {
    width: 84px;
    height: 84px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .cases-item-element .element-image .element-rarity {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .cases-item-element .element-image .element-rarity::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    filter: blur(20px);
    background: radial-gradient(50% 50% at 50% 50%, rgba(150, 150, 150, .3) 0%, rgba(74, 74, 74, 0) 90%);
  }

  .cases-item-element.rarity-gold .element-image .element-rarity::before {
    background: radial-gradient(50% 50% at 50% 50%, rgba(231, 212, 39, 0.3) 0%, rgba(74, 34, 34, 0) 90%);
  }

  .cases-item-element.rarity-red .element-image .element-rarity::before {
    background: radial-gradient(50% 50% at 50% 50%, rgba(231, 39, 39, 0.3) 0%, rgba(128, 34, 42, 0) 90%);
  }

  .cases-item-element.rarity-purple .element-image .element-rarity::before {
    background: radial-gradient(50% 50% at 50% 50%, rgba(172, 39, 231, .3) 0%, rgba(94, 34, 128, 0) 90%);
  }

  .cases-item-element.rarity-blue .element-image .element-rarity::before {
    background: radial-gradient(50% 50% at 50% 50%, rgba(39, 112, 231, .3) 0%, rgba(34, 74, 128, 0) 90%);
  }

  .cases-item-element .element-image .element-rarity img {
    width: 100%;
    height: 100%;
    opacity: 0.5;
  }

  .cases-item-element .element-name {
    width: 100%;
    margin-top: 12px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 700;
    color: #596076;
  }

  .cases-item-element .element-price {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }

  .cases-item-element .element-price img {
    width: 21px;
    margin-right: 10px;
  }

  .cases-item-element .price-amount {
    font-size: 12px;
    font-weight: 700;
    color: #bbbbbb;
  }

  .cases-item-element .price-amount span {
    font-size: 15px;
    font-weight: 700;
    color: #ffffff;
  }

  @media only screen and (max-width: 1000px) {

    .cases-item-element {
      width: calc(20% - 8px);
    }

  }

  @media only screen and (max-width: 800px) {

    .cases-item-element {
      width: calc(25% - 7.5px);
    }

  }

  @media only screen and (max-width: 650px) {

    .cases-item-element {
      width: calc(33.33% - 6.66px);
    }

  }

  @media only screen and (max-width: 500px) {

    .cases-item-element {
      width: calc(50% - 5px);
    }

  }
</style>