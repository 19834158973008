<template>
  <div class="home-banner-rewards">
    <div class="rewards-coins">
      <img src="@/assets/img/rakeback.webp" alt="rakeback-image" />
    </div>
    <div class="rewards-title">REWARDS</div>
    <div class="rewards-text">
      Increase your tier to earn more coins!<br>
      You currently earn <span>{{(homeGetPercentage).toFixed(2)}}%</span> rakeback on all bets.
    </div>
    <div class="rewards-action">
      <div class="action-amount">
        <img src="@/assets/img/icons/coin.png" alt="coins-image" />
        <div class="amount-value">
          <span>{{generalFormatAmount(homeGetAmount).split('.')[0]}}</span>.{{
          generalFormatAmount(homeGetAmount).split('.')[1]}}
        </div>
      </div>
      <button @click="homeClaimButton()" class="button-claim">CLAIM RAKEBACK</button>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import mixins from '@/mixins';

  export default {
    mixins: [
      mixins
    ],
    methods: {
      ...mapActions([
        'notificationShow', 
        'modalsSetShow',
        'modalsSetData'
      ]),
      homeClaimButton() {
        if(!this.authUser.user) {
          this.notificationShow({ 
            type: 'error', 
            message: 'Please sign in to perform this action.' 
          });
          return;
        }

        this.modalsSetData({ type: 'rakebackClaim', data: {} });
        this.modalsSetShow('Captcha');
      }
    },
    computed: {
      ...mapGetters([
          'authUser'
      ]),
      homeGetAmount() {
        let amount = 0;

        if(this.authUser.user) { amount = this.authUser.user.rakeback.available; }

        return amount;
      },
      homeGetPercentage() {
        let percentage = 0;

        if(this.authUser.user) {
          const level = Math.floor(Math.cbrt(this.authUser.user.stats.total.bet / 1000));

          if(level < 50) { percentage = 0.15; }
          else if(level < 100) { percentage = 0.2; }
          else if(level < 150) { percentage = 0.25; }
          else { percentage = 0.3; }
        }

        return percentage;
      }
    }
  }
</script>

<style scoped>
  .home-banner-rewards {
    width: calc(50% - 10px);
    height: 190px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 25px;
    border-radius: 8px;
    background: #212732;
  }

  .home-banner-rewards .rewards-coins {
    position: absolute;
    top: 50%;
    right: 50px;
    transform: translate(0, -50%);
  }

  .home-banner-rewards .rewards-coins img {
    height: 150px;
  }

  .home-banner-rewards .rewards-title {
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
  }

  .home-banner-rewards .rewards-text {
    font-size: 15px;
    font-weight: 600;
    color: #767c8b;
  }

  .home-banner-rewards .rewards-text span {
    font-size: 15px;
    font-weight: 600;
    color: #ffffff;
  }

  .home-banner-rewards .rewards-action {
    display: flex;
    align-items: center;
  }

  .home-banner-rewards .action-amount {
    height: 48px;
    display: flex;
    align-items: center;
    margin-right: 12px;
    padding: 0 18px;
    border-radius: 7px;
    background: #191e27;
  }

  .home-banner-rewards .action-amount img {
    width: 24px;
    margin-right: 10px;
  }

  .home-banner-rewards .amount-value {
    font-size: 13px;
    font-weight: 700;
    color: #bbbbbb;
  }

  .home-banner-rewards .amount-value span {
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
  }

  .home-banner-rewards button.button-claim {
    width: 150px;
    height: 48px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    background: #fd3b31;
    border-bottom: 2px solid #97302b;
    transition: all 0.3s ease;
  }

  .home-banner-rewards button.button-claim:hover {
    background: #fd3b31;
  }

  @media only screen and (max-width: 1250px) {

    .home-banner-rewards .rewards-coins {
      display: none;
    }

  }

  @media only screen and (max-width: 850px) {

    .home-banner-rewards {
      width: 100%;
      margin-top: 10px;
    }

  }
</style>